import type { CSSProperties, FC } from "react";
import React from "react";
import QrCreator from "qr-creator";
import styled from "@emotion/styled";

function generateQrCode(options: {
  value: string;
  canvas: HTMLCanvasElement;
  size: number;
}) {
  const { value, canvas, size } = options;
  QrCreator.render(
    {
      text: value,
      radius: 0.5,
      ecLevel: "H",
      fill: "black",
      background: "white",
      size: size * 4
    },
    canvas
  );
}

const QRCodeWrapper = styled.div`
  background-color: #fff;
  padding: calc(var(--space-sm) * 0.75);
  border-radius: calc(var(--space-sm) * 0.5);
  position: relative;
  aspect-ratio: 1;
  max-width: min(100%, var(--qr-code-size));

  canvas {
    width: 100%;
    aspect-ratio: 1;
    display: block;
  }

  &::after {
    content: "9am";
    font-family: "STK Bureau Serif", Serif;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 50%;
    width: 2rem;
    aspect-ratio: 1;
    font-size: 0.8rem;
    letter-spacing: -0.02em;
  }

  &[data-hide-logo="true"] {
    &::after {
      display: none;
    }
  }

  &[data-frame="true"] {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid var(--color-gray-light);
      width: 100%;
      height: 100%;
      border-radius: calc(var(--space-sm) * 0.5);
    }
  }
`;

const Qrcode: FC<{
  label?: string;
  text: string;
  size?: number;
  hideLogo?: boolean;
  frame?: boolean;
}> = ({ label, text: value, size, hideLogo = false, frame = false }) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    if (!canvasRef.current) return;
    const setSize = size ?? canvasRef.current.clientWidth;
    generateQrCode({
      value,
      canvas: canvasRef.current,
      size: setSize
    });
  }, [canvasRef]);

  return (
    <QRCodeWrapper
      className="qr-code"
      data-hide-logo={hideLogo}
      data-frame={frame}
      style={
        {
          "--qr-code-size": size ? `${size}px` : "100%"
        } as CSSProperties
      }
    >
      <canvas ref={canvasRef} role="img" aria-label={label ?? value}></canvas>
    </QRCodeWrapper>
  );
};

export default Qrcode;
