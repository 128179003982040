import styled from "@emotion/styled";
import clsx from "clsx";
import type { ReactElement } from "react";
import React from "react";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";

const Wrap = styled.div`
  &.center {
    width: min(100%, ${APP_CONTENT_WIDTH_WITHOUT_PADDING}px);
    margin: 0 auto;
  }
`;

interface Props {
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  direction?: "column" | "row";
  center?: boolean;
  children: ReactElement | ReactElement[];
}

export default function EnrollmentFrame(props: Props): ReactElement {
  const { children, center } = props;

  return (
    <Wrap
      className={clsx({
        center
      })}
    >
      {children}
    </Wrap>
  );
}
