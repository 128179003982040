import type {
  FindPharmaciesQuery,
  PharmacyDetailsResponse
} from "@9amhealth/openapi";
import {
  DefinePreferredPharmacyRequest,
  PharmaciesControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import reportErrorSentry from "src/lib/reportErrorSentry";

interface PatientPharmacyState {
  preferredPharmacy?: PharmacyDetailsResponse;
  pharmacySearchResults?: PharmacyDetailsResponse[];
  loading?: boolean;
}

export enum PharmacySearchFormKey {
  zip = "zip"
}

export type PharmacySearchFormValues = Record<PharmacySearchFormKey, string>;

export default class PatientPharmacyBloc extends Cubit<PatientPharmacyState> {
  temporaryPharmacySearchFormValues: {
    current: PharmacySearchFormValues | null;
  } = {
    current: null
  };

  constructor() {
    super({
      loading: false
    });
  }

  /**
   * Load the user's preferred pharmacy from the API
   */
  public loadPreferredPharmacy = async () => {
    this.emit({
      ...this.state,
      loading: true
    });

    try {
      const response =
        await PharmaciesControllerService.getCurrentUserPreferredPharmacies();
      const pharmacies = response.data;
      const [preferredPharmacy] = pharmacies;

      this.emit({
        ...this.state,
        preferredPharmacy
      });
    } catch (error) {
      reportErrorSentry(error);
    } finally {
      this.emit({
        ...this.state,
        loading: false
      });
    }
  };

  /**
   * Search pharmacies, updates the state with the pharmacies search results
   * @param fields
   */
  public searchPharmacies = async (fields: PharmacySearchFormValues) => {
    const zip = fields[PharmacySearchFormKey.zip];

    if (!zip.length) {
      throw new Error("Missing field: zip");
    }

    this.emit({
      ...this.state,
      loading: true
    });

    try {
      // set all query params except zip code as undefined
      const response = await PharmaciesControllerService.userSearchPharmacies(
        undefined as unknown as FindPharmaciesQuery,
        undefined,
        undefined,
        undefined,
        zip,
        undefined,
        undefined,
        undefined,
        undefined
      );

      // filter only results that contain zip search value, sort alphabetically
      const sortedResults = response.data
        .filter((result) => result.zipCode?.includes(zip))
        .sort((a, b) => a.pharmacyName.localeCompare(b.pharmacyName));

      this.emit({
        ...this.state,
        pharmacySearchResults: sortedResults
      });
    } catch (error) {
      reportErrorSentry(error);
      throw error;
    } finally {
      this.emit({
        ...this.state,
        loading: false
      });
    }
  };

  /**
   * Add a new preferred pharmacy to the user's account, updates the state with the new preferred pharmacy
   * @param pharmacy
   */
  public savePreferredPharmacy = async (pharmacy: PharmacyDetailsResponse) => {
    if (
      !pharmacy.providerPharmacyId ||
      !(
        pharmacy.pharmacyInformationProviderType in
        DefinePreferredPharmacyRequest.providerType
      )
    ) {
      throw new Error("Missing pharmacy details");
    }

    const { providerPharmacyId } = pharmacy;
    const providerType =
      pharmacy.pharmacyInformationProviderType as unknown as DefinePreferredPharmacyRequest.providerType;
    const preferredPharmacyRequest: DefinePreferredPharmacyRequest = {
      providerPharmacyId,
      providerType
    };

    try {
      await PharmaciesControllerService.defineCurrentUserPreferredPharmacy(
        preferredPharmacyRequest
      );
    } catch (error) {
      reportErrorSentry(error);
      throw error;
    } finally {
      this.emit({
        ...this.state,
        pharmacySearchResults: undefined
      });
    }
  };

  /**
   * Clear pharmacy search results - set them to undefined
   */
  public clearPharmacySearchResults = () => {
    this.emit({
      ...this.state,
      pharmacySearchResults: undefined
    });
  };
}
