import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useMemo } from "react";
import { getPrice } from "src/lib/getPrice";
import type { SubscriptionInterval } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import Translate from "src/ui/components/Translate/Translate";

const Highlight = styled.strong`
  color: var(--color-error);
  font-weight: 400;
`;

const InlineItem = styled.span<{ strike?: boolean }>`
  text-decoration: ${({ strike }): string =>
    strike ? "line-through" : "none"};
  opacity: ${({ strike }): number => (strike ? 0.6 : 1)};
  color: inherit;
  font-size: var(--title-font-size);
  font-weight: 400;
`;

const Interval = styled.span`
  font-size: 0.9em;
`;

const Price: FC<{
  amount: number;
  duration?: SubscriptionInterval;
  multiply?: boolean;
  noFormat?: boolean;
  className?: string;
  formatLocale?: boolean;
  hideDuration?: boolean;
  keepNumber?: boolean;
  zeroPricePlaceholder?: string;
  highlight?: boolean;
  text?: string;
  style?: React.CSSProperties;
  strike?: boolean;
  prefix?: string;
}> = (props) => {
  const {
    duration,
    amount,
    multiply = false,
    hideDuration = false,
    keepNumber = false,
    zeroPricePlaceholder = "FREE",
    text = "",
    style,
    strike,
    prefix
  } = props;
  const { highlight } = props;

  const priceInfo = useMemo(
    () => getPrice(amount, multiply, duration),
    [amount, multiply, duration]
  );
  const { totalAmount, intervalInt } = priceInfo;

  if (props.noFormat) {
    return <>${totalAmount}</>;
  }

  const priceFormatted = props.formatLocale
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(totalAmount)
    : `$${totalAmount}`;

  const Wrap = highlight ? Highlight : styled.span``;

  if (text) {
    return (
      <InlineItem strike={strike} className={props.className} style={style}>
        {prefix}
        <Wrap>{text}</Wrap>
      </InlineItem>
    );
  }

  return (
    <InlineItem strike={strike} className={props.className} style={style}>
      <Wrap>
        {prefix}
        {totalAmount === 0 && !keepNumber
          ? zeroPricePlaceholder
          : priceFormatted}
        {intervalInt && !hideDuration && (
          <Interval>
            /{intervalInt > 1 ? intervalInt : ""}
            <Translate msg="monthlyAbbreviation" />
          </Interval>
        )}
      </Wrap>
    </InlineItem>
  );
};

export default Price;
