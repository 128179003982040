import type { FC } from "react";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import { useBloc } from "src/state/state";
import BlockingLoadingOverlayController from "../BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { PopulateUserRequest } from "@9amhealth/openapi";
import Language from "src/constants/language";

const TYPE_MAP: Record<number, PopulateUserRequest.type | undefined> = {
  0: PopulateUserRequest.type.DIABETES,
  1: PopulateUserRequest.type.OBESITY
};

const TARGET_URLS: Record<
  PopulateUserRequest.type,
  Record<Language, string>
> = {
  DIABETES: {
    [Language.en]: `https://join9am.com/lilly-direct`,
    [Language.es]: `https://join9am.com/es/lilly-direct`
  },
  OBESITY: {
    [Language.en]: `https://join9am.com/lilly-direct-weight-health`,
    [Language.es]: `https://join9am.com/es/lilly-direct-weight-health`
  }
};

const HandoverTokenHandler: FC = () => {
  const [authState, { sendHandoverToken }] = useBloc(AuthenticationBloc);
  const [query] = useSearchParams();
  const running = useRef(false);

  const queryToken = query.get("token") ?? "";
  const typeParam = query.get("type") ?? "0";
  const redirectParam = query.get("redirect") ?? "";
  let languageParam = (query.get("language") ?? "en") as Language;
  if (!Object.values(Language).includes(languageParam)) {
    languageParam = Language.en;
  }
  const redirect: boolean = redirectParam !== "0";

  const typeNumber = Number(typeParam);
  const type = TYPE_MAP[typeNumber] ?? PopulateUserRequest.type.DIABETES;
  const targetUrl = TARGET_URLS[type];

  const auth = authState.credentials.accessToken;

  const url = new URL(targetUrl[languageParam]);
  const paramsIncoming = {
    utm_source: query.get("utm_source") ?? "handover",
    utm_medium: query.get("utm_medium") ?? "",
    utm_campaign: query.get("utm_campaign") ?? "",
    utm_content: query.get("utm_content") ?? ""
  };

  url.searchParams.set("utm_source", paramsIncoming.utm_source);
  url.searchParams.set("utm_medium", paramsIncoming.utm_medium);
  url.searchParams.set("utm_campaign", paramsIncoming.utm_campaign);
  url.searchParams.set("utm_content", paramsIncoming.utm_content);

  const target = url.toString();

  useEffect(() => {
    const [end] = BlockingLoadingOverlayController.startLoading({
      bg: "branded"
    });

    if (running.current) return;
    if (auth && typeof queryToken === "string") {
      running.current = true;
      void sendHandoverToken({
        token: queryToken,
        redirect: redirect ? target : undefined,
        type: type
      }).finally(() => end());
    }
  }, [queryToken, auth, target, running.current]);

  return null;
};

export default HandoverTokenHandler;
