import type { PurchasableItem } from "@9amhealth/openapi";
import {
  GetSuggestedTreatmentPlanResponse,
  QuestionnaireRef
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import { ABBREVIATED_QUESTIONNAIRE_ID } from "src/constants/misc";
import type Path from "src/constants/path";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import type {
  HeaderStep,
  SignupState
} from "src/state/SignupCubit/SignupState";
import {
  KnownQuestionnaireRefId,
  SignupStateInitial,
  SignupStatePurchaseFlow,
  SignupStateSuggestionIneligible,
  SignupStateSuggestionQuestionnaire,
  SignupStateSuggestionRegister
} from "src/state/SignupCubit/SignupState";
import { apiMiddleware, loadingState } from "src/state/state";
import eligibility = GetSuggestedTreatmentPlanResponse.eligibility;

export default class SignupCubit extends Cubit<SignupState> {
  headerSteps: HeaderStep[] = [];
  constructor(options: { headerSteps?: HeaderStep[] } = {}) {
    super(new SignupStateInitial());
    this.headerSteps = options.headerSteps ?? [];
  }

  static readonly loadSupportChat = (): void => {
    const launcher = document.getElementById("launcher");

    if (launcher) {
      launcher.style.display = "block";
    } else {
      const ZDS = document.createElement("script");
      ZDS.src =
        "https://static.zdassets.com/ekr/snippet.js?key=9266466b-d14d-43a5-918e-65f26761ec48";
      ZDS.id = "ze-snippet";
      ZDS.async = true;

      document.head.appendChild(ZDS);
    }
  };

  static readonly removeSupportChat = (): void => {
    document.querySelector("[title*=messaging]")?.remove();
    [...document.querySelectorAll(`[style*="z-index: 999999;"]`)].forEach(
      (el) => {
        (el as HTMLDivElement).remove();
      }
    );
    document.querySelector(`[data-product="web_widget"]`)?.remove();
  };

  public readonly setStep = (step: number, hideProgressBar = false): void => {
    if (this.state.currentStep !== step) {
      this.emit(
        new SignupStatePurchaseFlow({
          ...this.state,
          currentStep: step,
          hideProgressBar
        })
      );
    } else {
      const newState = this.state;
      newState.hideProgressBar = hideProgressBar;

      this.emit(newState);
    }
  };

  public readonly setStepQuestionnaire = (
    step: number,
    hideContactInfoOnMobile?: boolean
  ): void => {
    if (this.state instanceof SignupStateSuggestionQuestionnaire) {
      this.emit(
        new SignupStateSuggestionQuestionnaire({
          ...this.state,
          currentStep: step,
          hideContactInfoOnMobile
        })
      );
    }
  };

  public readonly setBackButton = (show: boolean): void => {
    if (this.state instanceof SignupStatePurchaseFlow) {
      this.emit(
        new SignupStatePurchaseFlow({
          ...this.state,
          showBackButton: show
        })
      );
    }
  };

  readonly setInitialStep = (): void => {
    const initialStep = {
      type: "TYPEFORM",
      id: ABBREVIATED_QUESTIONNAIRE_ID
    } as QuestionnaireRef;
    const state = this.state as SignupStateSuggestionIneligible;
    const { suggestion } = state;
    suggestion.questionnaireRef = initialStep;

    this.emit(
      new SignupStateSuggestionQuestionnaire({
        typeFormId: initialStep.id,
        hiddenFields: undefined,
        suggestion,

        currentStep: 5
      })
    );
  };
  readonly setAlternativeStep = (): void => {
    const altStep = this.state.suggestion?.alternativeStep;
    const state = this.state as SignupStateSuggestionIneligible;

    if (altStep?.type === QuestionnaireRef.type.TYPEFORM) {
      this.emit(
        new SignupStateSuggestionQuestionnaire({
          typeFormId: altStep.id,
          hiddenFields: this.state.suggestion?.answers,
          suggestion: state.suggestion,
          currentStep: 5
        })
      );
    }
  };

  private key = Date.now();
  readonly getNextStep = async (): Promise<void> => {
    loadingState.start(LoadingKey.suggestion);
    try {
      const data = await apiMiddleware.cachedSuggestTreatmentPlan();
      const suggestion = { suggestion: data };

      if (data.eligibility === eligibility.ELIGIBLE) {
        this.emit(
          new SignupStatePurchaseFlow({
            purchaseItems: data.purchaseItems,
            currentStep: 3
          })
        );
      }

      if (data.eligibility === eligibility.INELIGIBLE) {
        this.emit(
          new SignupStateSuggestionIneligible({ ...suggestion, currentStep: 2 })
        );
      }

      if (suggestion.suggestion.questionnaireRef) {
        const ref = suggestion.suggestion.questionnaireRef;
        if (
          data.eligibility === eligibility.DATA_NEEDED &&
          ref.type === QuestionnaireRef.type.NINEAM_HEALTH &&
          (ref.id as KnownQuestionnaireRefId) ===
            KnownQuestionnaireRefId.register
        ) {
          this.emit(
            new SignupStateSuggestionRegister({
              ...suggestion,
              currentStep: 1
            })
          );
        }

        if (
          data.eligibility === eligibility.DATA_NEEDED &&
          ref.type === QuestionnaireRef.type.TYPEFORM
        ) {
          const typeFormId = suggestion.suggestion.questionnaireRef.id;
          this.emit(
            new SignupStateSuggestionQuestionnaire({
              ...suggestion,
              typeFormId,
              hiddenFields: data.answers,
              hideContactInfoOnMobile:
                typeFormId !== ABBREVIATED_QUESTIONNAIRE_ID,
              // abbreviated questionnaire is step 0, other (medical) is step 2
              currentStep: typeFormId === ABBREVIATED_QUESTIONNAIRE_ID ? 0 : 2,
              key: this.key++
            })
          );
        }
      }
    } catch (e: unknown) {
      const newState = new SignupStateInitial();
      newState.error = translate("error_generic");
      this.emit(newState);
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.suggestion);
  };

  readonly hideSignupHeaderForPath = (pathname: string): boolean => {
    return pathname.includes("success") || pathname.includes("whats-next");
  };

  /**
   * Checks the suggestion
   * if a set of items matches the purchaseItems in the suggestion,
   * returns the funnel path for that subscription
   * @returns funnel path for matching items, or undefined if no match
   */
  readonly getFunnelPathForSuggestion = (
    suggestionItems?: PurchasableItem[]
  ): Path | undefined => {
    let funnelPath: string | undefined = undefined;
    const itemFunnelPathMap = new Map<string[], string>([
      [["care:weightloss"], "/signup/weight-loss"]
    ]);

    if (!suggestionItems) return funnelPath;

    for (const [items, path] of itemFunnelPathMap) {
      const draftItems = suggestionItems.map((item) => item.sku);
      if (items.every((item) => draftItems.includes(item as string))) {
        funnelPath = path;
        break;
      }
    }

    return funnelPath as Path;
  };
}
