import i18next from "i18next";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import translationEnglish from "src/translations/en.json";
import type { TranslationKey } from "src/types/translationKey";

export default function translate(
  msg: TranslationKey,
  variables: Record<string, unknown> = {},
  fallback?: TranslationKey
): string {
  if (
    featureFlags.getFlag(FeatureFlagName.loggingTranslation) &&
    !(msg in translationEnglish)
  ) {
    // eslint-disable-next-line no-console
    console.warn("Translation key ", msg, " not defined in translation file");
  }

  let text: string;
  const formattedMsg = msg.replace(/-/g, "_");

  text = i18next.t(formattedMsg, variables);

  if (typeof fallback !== "undefined" && text === formattedMsg) {
    text = i18next.t(fallback, variables);
  } else if (text === formattedMsg) {
    text = msg;
  }
  return text;
}
