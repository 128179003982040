import { Button } from "@9amhealth/shared";
import { useBloc } from "@blac/react";
import React from "react";
import UserJourneyBloc from "src/ui/components/UserJourneyOverview/UserJourneyBloc";

const DebugUserJourney = () => {
  const [{ enableAll, name, modules }, bloc] = useBloc(UserJourneyBloc);
  return (
    <div>
      <h4>{name}</h4>
      <Button onPress={() => bloc.patch({ enableAll: !enableAll })}>
        Enable Action for All: {enableAll ? "true" : "false"}
      </Button>
      <hr />
      <h5>Modules</h5>
      <pre>{JSON.stringify(modules, null, 2)}</pre>
      <hr />
      <h5>Tasks</h5>
      <pre>{JSON.stringify(bloc.tasks, null, 2)}</pre>
    </div>
  );
};

export default DebugUserJourney;
