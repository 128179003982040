import { Language, Locale } from "src/constants/language";
import { getSupportedUserLanguage } from "src/lib/i18next";

// Modify user locale because stripe accepts "es-419" for Latin American Spanish, but only "en" for US English
const getStripeUserLocale = () => {
  const userLanguage = getSupportedUserLanguage();
  return userLanguage === Language.es ? Locale.esLatinAmerica : Language.en;
};

export default getStripeUserLocale;
