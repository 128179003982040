import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import Translate from "../Translate/Translate";

export const ErrorPageLayout = styled.div`
  label: ErrorPageLayout;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 500;
  position: relative;
  background: #fffcf3;
  height: 100vh;
  height: 100svh;
`;

export const CenterEl = styled.div`
  border-radius: 0.6em;
  width: 80vw;
  max-width: 400px;
  text-align: center;

  p {
    margin: 0.2em 0 1em;
    line-height: 1.6;
    font-size: 1.1em;
    font-weight: 500;
  }

  button {
    margin-top: 1em;
    font-size: 1em;
    padding: 0.6em 1.2em;
  }

  a {
    color: var(--color-charcoal);
  }
`;

export const ErrorContent: FC = () => {
  return (
    <div>
      <CenterEl>
        <nine-heading>
          <nine-spacer s="xs"></nine-spacer>
          <p className="m0 color-c-80">
            <Translate msg="errorPage.loading" />{" "}
            <Translate msg="errorPage.refresh" />
          </p>
          <nine-spacer s="md"></nine-spacer>
          <p className="m0 color-c-80">
            <Translate msg="help.contactUs" />{" "}
            <a href="tel:+12029329958">(202) 932-9958</a>.
            <br />
            <Translate msg="help.thankYouMessage" />
          </p>
        </nine-heading>
        <nine-spacer s="lg"></nine-spacer>
        <nine-button
          onClick={(): void => {
            window.location.reload();
          }}
        >
          <Translate msg="refreshPage" />
        </nine-button>
      </CenterEl>
    </div>
  );
};

const ErrorPage: FC = () => {
  return (
    <ErrorPageLayout>
      <ErrorContent />
    </ErrorPageLayout>
  );
};

export default ErrorPage;
