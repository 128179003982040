import type { Dict } from "mixpanel-browser";
import type { FC } from "react";
import { useEffect } from "react";
import { tracker } from "src/state/state";
import type { TrackEvent, TrackType } from "src/state/Track/TrackCubit";

const Track: FC<{
  event: TrackEvent;
  type?: TrackType;
  data?: Dict;
  backend?: boolean;
}> = (props) => {
  useEffect(() => {
    tracker.track(props.event, {
      type: props.type,
      data: props.data,
      backend: props.backend
    });
  }, []);

  return null;
};

export default Track;
