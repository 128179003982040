import { DismissButton, Overlay, usePopover } from "react-aria";
import type { AriaPopoverProps } from "react-aria";
import type { OverlayTriggerState } from "react-stately";
import { useOverlayTrigger } from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import React from "react";
import Button from "atom/button/Button";
import type { OverlayTriggerProps } from "@react-types/overlays";

import styled from "@emotion/styled";
import { Dialog } from "react-aria-components";

/*
 * Underlay is used to prevent scrolling and interacting with elements outside
 * the popover
 */
const Underlay = styled.div`
  position: fixed;
  inset: 0;
`;

/*
 * PopoverWrapper is the actual popover element
 */
const PopoverWrapper = styled.div`
  position: absolute;
`;

const Arrow = styled.svg`
  position: absolute;
  fill: white;
  stroke-width: 1px;
  width: 12px;
  height: 12px;

  &[data-placement="top"] {
    top: 100%;
    transform: translateX(-50%);
  }

  &[data-placement="bottom"] {
    bottom: 100%;
    transform: translateX(-50%) rotate(180deg);
  }

  &[data-placement="left"] {
    left: 100%;
    transform: translateY(-50%) rotate(-90deg);
  }

  &[data-placement="right"] {
    right: 100%;
    transform: translateY(-50%) rotate(90deg);
  }
`;

interface PopoverProps extends Omit<AriaPopoverProps, "popoverRef"> {
  children: React.ReactNode;
  state: OverlayTriggerState;
  hideArrow?: boolean;
  title: string;
  ref?: React.Ref<HTMLDivElement | null>;
}

// Popover component
function PopoverNoRef(
  { children, state, offset = 8, ...props }: PopoverProps,
  ref: React.Ref<HTMLDivElement | null>
) {
  const popoverRef = React.useRef<HTMLDivElement>(null);

  React.useImperativeHandle(ref, () => popoverRef.current, [popoverRef]);

  // usePopover handles positioning the popover relative to the trigger element,
  // and closing it when the user interacts outside or presses the Escape key.
  const { popoverProps, underlayProps, arrowProps, placement } = usePopover(
    {
      ...props,
      offset,
      popoverRef
    },
    state
  );

  return (
    // Overlay is used to render the popover contents in a React Portal at the end of the document body
    <Overlay>
      <Underlay {...underlayProps} />
      <PopoverWrapper {...popoverProps} ref={popoverRef}>
        {!props.hideArrow && (
          <Arrow {...arrowProps} data-placement={placement} viewBox="0 0 12 12">
            <path d="M0 0 L6 6 L12 0" />
          </Arrow>
        )}
        {/* DismissButton is a visually hidden button that allows screen reader
        users to dismiss the popover */}
        <DismissButton onDismiss={state.close} />
        <Dialog aria-label={props.title}>{children}</Dialog>
        <DismissButton onDismiss={state.close} />
      </PopoverWrapper>
    </Overlay>
  );
}

export const Popover = React.forwardRef(PopoverNoRef) as typeof PopoverNoRef;

export function PopoverTrigger({
  label,
  children,
  overlayTriggerProps = {},
  ...props
}: Omit<AriaPopoverProps, "triggerRef" | "popoverRef"> & {
  label: string;
  children: React.ReactElement;
  overlayTriggerProps?: OverlayTriggerProps;
  buttonProps?: React.ComponentProps<typeof Button>;
}) {
  const ref = React.useRef<HTMLButtonElement>(null);

  // useOverlayTriggerState is used to manage the state of the overlay trigger
  const state = useOverlayTriggerState(overlayTriggerProps);

  // useOverlayTrigger is used to handle the interactions with the trigger element
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    ref
  );

  return (
    <>
      <Button {...triggerProps} {...props.buttonProps} ref={ref}>
        {label}
      </Button>
      {state.isOpen && (
        <Popover title={label} {...props} triggerRef={ref} state={state}>
          {/* The child is cloned to add the overlayProps */}
          {React.cloneElement(children, overlayProps)}
        </Popover>
      )}
    </>
  );
}
