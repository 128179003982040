/* eslint-disable no-console */
import { historyState } from "src/state/state";
import type Path from "src/constants/path";
import { useNavigate } from "react-router-dom";

interface Options {
  /**
   * Replace the current entry in the history stack instead of adding a new one.
   */
  replace?: boolean;
  /**
   * If true, go back multiple times if the url is in the history stack.
   */
  multiBack?: boolean;
  /**
   * If false, don't navigate, just go back or forward.
   */
  useBack?: boolean;
}

const DEBUG = false;

export default function useGoToOrBack(): (
  to: Path | string,
  options?: Options,
  debug?: boolean
) => void {
  const navigate = useNavigate();

  return (to: Path | string, options: Options = {}, debug: boolean = DEBUG) => {
    const delta = historyState.findDelta(to);
    const useBack = options.useBack && historyState.state.history.length > 1;
    if (debug) console.log("useGoToOrBack", { to, delta, useBack, options });

    if (useBack) {
      if (options.multiBack && delta < 0) {
        if (debug) console.log("useGoToOrBack multiBack", { delta });
        // go back as many times as it takes to get to the url
        return historyState.go(delta);
      } else if (historyState.backUrl === to) {
        if (debug) console.log("useGoToOrBack backUrl", { delta });
        // go back, if url is the same as the back url
        return historyState.back();
      }
    }

    if (debug) console.log("useGoToOrBack navigate", { to, options });
    // go forward to url
    navigate(to, { replace: options.replace });
  };
}
