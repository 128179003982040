import styled from "@emotion/styled";
import { Input as InputAria, Label, Text } from "react-aria-components";
import React from "react";

export const AutocompleteInputWrap = styled.div`
  border-radius: 0.5rem;
  background: var(--greys-white, #fff);
  height: 4rem;
  display: flex;
  position: relative;
  background: var(--greys-charcoal-60, rgba(33, 33, 33, 0.6));
  transition: all 0.2s ease-in-out;

  &[data-invalid="true"] {
    background: var(--primary-warning-red, #bf1902);
  }

  &::before {
    content: "";
    position: absolute;
    --inset-size: 1px;
    inset: var(--inset-size);
    transition: all 0.2s ease-in-out;
    display: block;
    background: white;
    z-index: 2;
    border-radius: calc(0.5rem - var(--inset-size));
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    transition: all 0.2s ease-in-out;
    display: block;
    opacity: 0;
    background: linear-gradient(
      46deg,
      #a6c6ff 13.49%,
      #fad0e8 50.42%,
      #ffd199 85.11%
    );
    pointer-events: none;
    z-index: 1;
    border-radius: inherit;
  }

  &:focus-within {
    background: transparent;
    box-shadow:
      0px 0px 0px 0px rgba(69, 49, 22, 0.08),
      -5px 5px 16px 0px rgba(69, 49, 22, 0.08),
      -20px 20px 29px 0px rgba(69, 49, 22, 0.07),
      -46px 45px 39px 0px rgba(69, 49, 22, 0.03),
      -82px 81px 46px 0px rgba(69, 49, 22, 0.01);

    &::before {
      --inset-size: 2px;
    }

    &::after {
      opacity: 1;
    }
  }

  > * {
    position: relative;
    z-index: 3;
  }
`;

export const AutocompleteInputEl = styled(InputAria)`
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  padding: 0.625rem 1rem;
  height: 100%;
  outline: none;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.02rem;

  transition: all 0.2s ease-in-out;

  &[data-invalid="true"]:not(:focus) {
    border: 1px solid var(--primary-warning-red, #bf1902);
    border-radius: 0.5rem;
  }

  &:not(:focus)::placeholder {
    color: transparent;
  }

  &[aria-labelledby] {
    &:focus,
    &:not(input[value=""]),
    &:not(input[placeholder=""]) {
      padding: 1rem 1rem 0;
    }
  }
`;

export const AutocompleteLabelEl = styled(Label)`
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  transform-origin: top left;
  left: 1rem;

  &[data-expanded="true"],
  &[data-hasvalue="true"] {
    top: 0.625rem;
    transform: translateY(0%) scale(0.75);
  }
`;

const AutocompleteIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-right: 1rem;
  width: 1.5rem;
  pointer-events: none;
  transition: all 0.2s ease-in-out;

  svg {
    width: 100%;
    height: 100%;
    fill: var(--greys-charcoal-60, rgba(33, 33, 33, 0.6));
  }
`;

export const AutocompleteIcon = (): React.ReactElement => (
  <AutocompleteIconWrapper>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4931 13.7184L7.21123 9.02399L6.54883 9.76991L12.4931 15.0547L18.4375 9.76991L17.7741 9.02399L12.4931 13.7184Z"
        fill="#212121"
      />
    </svg>
  </AutocompleteIconWrapper>
);

export const AutocompleteTextEl = styled(Text)`
  display: block;
  padding: 0.5rem 0.125rem 0rem 0.125rem;
  opacity: 0.8;
`;

export const AutocompleteErrorEl = styled.div`
  --error-red: #bf1902;
  padding: 0.5em 1em 1em;
  display: block;
  color: var(--error-red, #bf1902);

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: show 0.2s linear forwards;

  &:empty {
    padding: 0;
  }
`;
