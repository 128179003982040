import { Cubit } from "blac";

export enum LoadingKey {
  payment = "payment",
  none = "_",
  login = "login",
  register = "register",
  verifyEmail = "verifyEmail",
  requestPassReset = "requestPassReset",
  updatePass = "updatePass",
  saveFormData = "saveFormData",
  messages = "messages",
  treatmentPlan = "treatmentPlan",
  treatmentPlanFrequency = "treatmentPlanFrequency",
  treatmentPlanLabTest = "treatmentPlanLabTest",
  shippingAddress = "shippingAddress",
  avatar = "avatar",
  shipment = "shipment",
  paymentRedirect = "paymentRedirect",
  changePassword = "changePassword",
  number = "number",
  upload = "upload",
  suggestion = "suggestion",
  labResults = "labResults",
  userUploads = "labUploads",
  labOrders = "labOrders",
  subscriptionState = "subscriptionState",
  saveQuestionnaireResult = "saveQuestionnaireResult",
  questionnaire = "questionnaire",
  initiateVerification = "initiateVerification",
  answeredQuestionnaires = "answeredQuestionnaires",
  updateSuggestion = "updateSuggestion",
  coupon = "coupon",
  referral = "referral",
  userPreferences = "userPreferences",
  userConditions = "userConditions",
  sanityData = "sanityData",
  exampleSubscription = "exampleSubscription",
  requestSubscriptionChange = "requestSubscriptionChange",
  PAYMENT_METHOD_SETUP = "PAYMENT_METHOD_SETUP",
  medications = "medications",
  submitLabResults = "submitLabResults",
  profileData = "profileData",
  checkingZipCode = "checkingZipCode",
  authCheck = "authCheck",
  loadingLabResults = "loadingLabResults",
  loadingObservationBundles = "loadingObservationBundles",
  loadingObservationBundle = "loadingObservationBundle",
  checkCustomSignupStepDataComplete = "checkCustomSignupStepDataComplete",
  logout = "logout",
  devices = "devices",
  requiredAppUserData = "requiredAppUserData"
}

export default class LoadingCubit extends Cubit<string[]> {
  instantState: string[] = [];
  updateDebuff: NodeJS.Timeout | number = 0;
  documentLoaded = false;
  windowLoaded = false;

  constructor() {
    super([]);

    document.addEventListener("DOMContentLoaded", () => {
      this.documentLoaded = true;
      this.updateState();
    });

    window.addEventListener("load", () => {
      this.windowLoaded = true;
      this.updateState();
    });
  }

  reset = (): void => {
    this.instantState = [];
    this.updateState();
    this.emit([]);
  };

  updateState = (): void => {
    if (this.updateDebuff) {
      clearTimeout(this.updateDebuff);
    }
    this.updateDebuff = setTimeout(() => {
      const changed = this.state.join(",") !== this.instantState.join(",");
      if (changed) this.emit([...this.instantState]);
    });
  };

  public readonly start = (key: LoadingKey): void => {
    this.instantState.push(key);
    this.updateState();
  };

  public readonly finish = (key: LoadingKey): void => {
    this.instantState = this.instantState.filter(
      (k) => (k as LoadingKey) !== key
    );
    this.updateState();
  };

  public readonly isLoading = (key?: LoadingKey): boolean => {
    if (key) return this.state.indexOf(key) !== -1;
    return this.state.length > 0;
  };

  get appHasLoaded(): boolean {
    return this.documentLoaded && this.windowLoaded;
  }
}
