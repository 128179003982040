import type { TranslationKey } from "src/types/translationKey";

const loincNameMap: Record<string, TranslationKey> = {
  "4548-4": "hbA1c",
  "55284-4": "bloodPressure",
  "85354-9": "bloodPressure",
  "8480-6": "systolic",
  "8462-4": "diastolic",
  "41982-0": "bodyFatPercentage",
  "56086-2": "waistCircumference",
  "29463-7": "weight",
  "8302-2": "height",
  "39156-5": "bmi",
  "38483-4": "creatinine",
  "96588-9": "totalCholesterol",
  "96596-2": "cholesterolHdl",
  "96597-0": "cholesterolLdl",
  "96598-8": "triglyceride",
  "2885-2": "protein",
  "1751-7": "albumin",
  "2336-6": "globulin",
  "10834-0": "globulinCalculated",
  "1759-0": "albuminOrGlobulin",
  "2951-2": "sodium",
  "2823-3": "potasium",
  "2075-0": "chloride",
  "2028-9": "carbonDioxide",
  "2160-0": "creatinine",
  "98979-8": "egfr",
  "33914-3": "egfr",
  "48643-1": "egfrBlack",
  "3094-0": "ureaNitrogen",
  "17861-6": "calcium",
  "1975-2": "bilirubin",
  "6768-6": "alkalinePhosphatase",
  "1920-8": "ast",
  "1742-6": "alt",
  "2345-7": "glucose",
  "87422-2": "bloodGlucosePostMeal",
  "1556-0": "fastingGlucose",
  "32016-8": "bloodGlucose",
  "2093-3": "cholesterol",
  "2085-9": "cholesterolInHdl",
  "2571-8": "triglyceride",
  "2095-8": "cholesterolInHdlOrCholesterolTotal",
  "9830-1": "cholesterolTotalOrCholesterolInHdl",
  "11054-4": "cholesterolInLdlOrCholesterolInHdl",
  "13457-7": "cholesterolInLdl",
  "13458-5": "cholesterolInVdld",
  "43396-1": "cholesterolNonHdl",
  "14959-1": "microalbuminOrCreatinineRatio",
  "11580-8": "thyroidStimulatingHormone",
  "56540-8": "gAd-65Ab",
  "1986-9": "c-peptide",
  "1558-6": "glucosePostFast",
  "9318-7": "albOrCreatRatio",
  "2161-8": "creatinineUrine",
  "35674-1": "creatinineUrine",
  "14957-5": "microalbuminUrine",
  "3024-7": "freeT4",
  "3097-3": "ureaNitrogenOrCreatinine",
  "3016-3": "tsh",
  "1968-7": "bilirubinDirectBlood",
  "1971-1": "bilirubinIndirectBlood",
  "41950-7": "observations.stepsInDay"
};

const loincCodeToName = (code: string = "NONE"): TranslationKey => {
  const def = loincNameMap[code] as TranslationKey | undefined;
  if (!def) {
    return "unknown";
  }
  return def;
};

export default loincCodeToName;
