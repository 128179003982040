import {
  AutocompleteErrorEl,
  AutocompleteIcon,
  AutocompleteInputEl,
  AutocompleteInputWrap,
  AutocompleteLabelEl,
  AutocompleteTextEl
} from "atom/autocomplete/autocompleteComponents";
import {
  DropdownState,
  FormFieldState
} from "atom/autoform/AutoFormBloc";
import styled from "@emotion/styled";
import { useElementDimensions } from "lib/useElementDimensions";
import { ListBox } from "molecule/dropdown/Dropdown";
import React, { useEffect } from "react";
import {
  AriaComboBoxProps,
  AriaPopoverProps,
  DismissButton,
  Key,
  Overlay,
  useComboBox,
  useFilter,
  usePopover
} from "react-aria";
import { Button } from "react-aria-components";
import { OverlayTriggerState, useComboBoxState } from "react-stately";
import { z } from "zod";

const AutocompleteCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 30vh;
  width: 100%;

  &[data-loading="true"] {
    filter: blur(2px);
    pointer-events: none;
  }
`;

interface AutoCompletePopoverProps extends AriaPopoverProps {
  children: React.ReactNode;
  state: OverlayTriggerState;
}

function AutoCompletePopover({
  children,
  state,
  ...props
}: AutoCompletePopoverProps) {
  const { popoverProps } = usePopover(props, state);

  return (
    <Overlay>
      <div
        {...popoverProps}
        style={{
          ...popoverProps.style,
          overflowY: "scroll",
          backgroundColor: "white",
          boxShadow:
            "-82px 81px 46px rgba(69, 49, 22, 0.01), -46px 45px 39px rgba(69, 49, 22, 0.03), -20px 20px 29px rgba(69, 49, 22, 0.07), -5px 5px 16px rgba(69, 49, 22, 0.08), 0px 0px 0px rgba(69, 49, 22, 0.08)",
          borderRadius: "8px"
        }}
        ref={props.popoverRef as React.RefObject<HTMLDivElement>}
      >
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}

export type AutocompleteItem = object & {
  id: string;
  label?: string;
  name?: string;
};

export interface AutocompleteProps<T extends AutocompleteItem>
  extends AriaComboBoxProps<T> {
  errorParser?: (props: AutocompleteProps<T>) => string;
  defaultValue?: Key;
  defaultItems?: T[];
  error?: string | z.ZodIssue;
  ref?: React.Ref<FormFieldState | null>;
  isLoading?: boolean;
  name?: string;
}

function AutocompleteNoRef<T extends AutocompleteItem>(
  p: AutocompleteProps<T>,
  ref?: React.Ref<FormFieldState | null>
) {
  const {
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    },
    isLoading,
    ...props
  } = p;
  const { contains } = useFilter({ sensitivity: "base" });
  const state = useComboBoxState({
    ...props,
    defaultFilter: contains
  });
  useEffect(() => {
    const closePopoverOnScrollStart = () => {
      if (state.isOpen) {
        state.close();
      }
    };

    const reopenPopoverOnScrollEnd = () => {
      if (state.isFocused && !state.isOpen) {
        state.open();
      }
    };

    document.addEventListener("ionScrollStart", closePopoverOnScrollStart);
    document.addEventListener("ionScrollEnd", reopenPopoverOnScrollEnd);
    document.addEventListener("nine-scroll", closePopoverOnScrollStart);

    return () => {
      document.removeEventListener("ionScrollStart", closePopoverOnScrollStart);
      document.removeEventListener("ionScrollEnd", reopenPopoverOnScrollEnd);
      document.removeEventListener("nine-scroll", closePopoverOnScrollStart);
    };
  }, [state.isOpen]);

  const buttonRef = React.useRef(null);
  const inputRef = React.useRef(null);

  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const { buttonProps, inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef
    },
    state
  );

  const [{ width }] = useElementDimensions<HTMLButtonElement>(inputRef);

  React.useImperativeHandle(ref, () => {
    return {
      setValue: (value: string) => {
        state.setSelectedKey(value);
      }
    } as DropdownState;
  }, [state]);

  const errorString = errorParser(props);

  useEffect(() => {
    if (props.defaultSelectedKey || props.defaultValue) {
      for (const item of props.defaultItems || []) {
        if (
          item.name === props.defaultValue ||
          item.label === props.defaultValue ||
          item.id === props.defaultSelectedKey
        ) {
          state.setSelectedKey(String(item.id));
        }
      }
    }
  }, [props.defaultValue, props.defaultSelectedKey, props.defaultItems]);

  return (
    <>
      <div
        style={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <AutocompleteInputWrap data-invalid={!!props.isInvalid}>
          <AutocompleteInputEl
            {...inputProps}
            ref={inputRef}
            data-invalid={!!props.isInvalid}
          />
          {props.label && (
            <AutocompleteLabelEl
              {...labelProps}
              data-hasvalue={!!state.inputValue}
              data-expanded={state.isOpen || Boolean(state.selectedItem)}
              className="body1"
            >
              {props.label}
            </AutocompleteLabelEl>
          )}
          <AutocompleteIcon />
          <Button
            {...buttonProps}
            ref={buttonRef}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer"
            }}
          ></Button>
        </AutocompleteInputWrap>
        {state.isOpen && (
          <AutoCompletePopover
            state={state}
            triggerRef={inputRef}
            popoverRef={popoverRef}
            isNonModal
            shouldFlip={false}
            placement="bottom start"
          >
            <AutocompleteCard data-loading={isLoading} style={{ width }}>
              <ListBox
                {...listBoxProps}
                listBoxRef={listBoxRef}
                state={state}
              />
            </AutocompleteCard>
          </AutoCompletePopover>
        )}
        {props.description && (
          <AutocompleteTextEl slot="description" className="little1">
            {props.description}
          </AutocompleteTextEl>
        )}
        {errorString && (
          <AutocompleteErrorEl className={"little1 data-field-error"}>
            {errorString}
          </AutocompleteErrorEl>
        )}
      </div>
    </>
  );
}

const Autocomplete = React.forwardRef(
  AutocompleteNoRef
) as typeof AutocompleteNoRef;

export default Autocomplete;
