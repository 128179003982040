import styled from "@emotion/styled";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import clsx from "clsx";
import type { FC } from "react";
import React, { useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { IconCrossXCloseMissed } from "src/constants/icons";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import { isPlatform } from "src/lib/platform";
import { BlocProvider, appStatusBarState } from "src/state/state";
import InDialogBloc from "src/ui/components/InDialog/InDialogBloc";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { AppQueryPopupsController } from "../AppQueryPopups/AppQueryPopupsBloc";

const CustomStylesModal = styled(IonModal)`
  label: CustomStyles;

  @media only screen and (min-width: 768px) and (min-height: 768px) {
    --height: max(60vh, 600px);

    &[data-fullscreen="true"] {
      --height: 100vh;
      --width: 100vw;
    }
  }

  &[data-dialog-type="feedback"] {
    nine-funnel-step {
      width: 100%;
      --funnel-step-width: min(100%, 55rem);
    }
  }

  ion-toolbar {
    --background: var(--color-cream);
  }

  ion-title {
    font-size: 1rem;
    font-weight: 500;
  }

  .close {
    svg {
      width: 2rem;
      height: auto;
      color: var(--color-charcoal);
    }
  }

  ion-content > *:last-child {
    padding-bottom: calc(var(--keyboard-height, 50vh) + 2rem);
  }

  nine-funnel-step {
    --margin-top: 1rem;
    width: min(100%, 100vw, ${APP_CONTENT_WIDTH_WITHOUT_PADDING}px);
    --funnel-step-padding: 0;
  }

  &[data-custom-z-index="true"] {
    z-index: var(--z-index) !important;
  }
`;

const InDialog: FC<{
  isOpen?: boolean;
  hideHeader?: boolean;
  returnUrl?: string;
  isFullScreen?: boolean;
  alwaysUseReturnUrl?: boolean;
  onClose?: () => void;
  onWillDismiss?: () => void;
  children?: React.ReactNode;
  title?: string;
  setRef?: (ref: React.RefObject<HTMLIonModalElement>) => void;
  popup?: boolean;
  className?: string;
  simple?: boolean;
  backdropDismiss?: boolean;
  height?: string;
  width?: string;
  alwaysModal?: boolean;
  showClose?: boolean;
  dialogType?: string;
}> = (props) => {
  const bloc = useMemo(() => new InDialogBloc(), []);
  const modal = useRef<HTMLIonModalElement>(null);
  const modalCache = useRef<HTMLIonModalElement>();
  const presentationEl = document.getElementById("ion-page-router");
  const windowWidth = window.innerWidth;
  const { isOpen = true, showClose = true } = props;
  const [query] = useSearchParams();

  let zIndex: string | null | undefined = query.get("z");
  if (zIndex === "0") {
    zIndex = undefined;
  }

  const navigate = useGoToOrBack();
  const handleClose = () => {
    if (props.returnUrl) {
      navigate(props.returnUrl, {
        multiBack: true
      });
    }

    props.onWillDismiss?.();
    props.onClose?.();
    AppQueryPopupsController.closePopup();
  };

  useEffect(() => {
    return () => {
      if (props.popup) {
        appStatusBarState.resetThemeDefault();
      }
      void modalCache.current?.dismiss();
    };
  }, []);

  useEffect(() => {
    props.setRef?.(modal);
    if (modal.current) {
      modalCache.current = modal.current;
    }
  }, [modal]);

  if (!presentationEl) {
    return null;
  }

  const style: Record<string, unknown> = {
    "--z-index": zIndex
  };

  if (windowWidth >= 600 || props.alwaysModal) {
    if (props.height) {
      style["--height"] = props.height;
    }
    if (props.width) {
      style["--width"] = props.width;
    }
  }

  const usePresentingElement = props.alwaysModal
    ? undefined
    : windowWidth < 600
      ? presentationEl
      : undefined;

  if (!props.children || !props.title) {
    return null;
  }

  return (
    <BlocProvider bloc={bloc}>
      <CustomStylesModal
        ref={modal}
        isOpen={isOpen}
        presentingElement={usePresentingElement}
        mode={isPlatform("android") ? "md" : "ios"}
        className={clsx(
          props.className,
          props.simple && "modal-simple",
          props.alwaysModal && "modal-always-modal"
        )}
        style={style}
        data-custom-z-index={Boolean(zIndex)}
        backdropDismiss={props.backdropDismiss}
        onWillDismiss={handleClose}
        data-fullscreen={props.isFullScreen}
        data-dialog-type={props.dialogType}
      >
        {!props.hideHeader && (
          <IonHeader>
            <IonToolbar>
              {showClose && (
                <IonButtons slot="end">
                  <IonButton
                    aria-label="Close"
                    onClick={() => void modal.current?.dismiss()}
                    className="close"
                  >
                    <IconCrossXCloseMissed />
                  </IonButton>
                </IonButtons>
              )}
              {props.title && <IonTitle>{props.title}</IonTitle>}
              <IonButtons slot="end"></IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent scrollEvents className="ion-padding">
          {props.children}
        </IonContent>
      </CustomStylesModal>
    </BlocProvider>
  );
};

export default InDialog;
