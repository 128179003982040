import { IonPage } from "@ionic/react";
import React from "react";
import AppQueryPopups from "src/ui/components/AppQueryPopups/AppQueryPopups";

type AppPageProviderProps = {
  children: React.ReactNode;
};

const AppPageProvider: React.FC<AppPageProviderProps> = ({ children }) => {
  return (
    <IonPage id="ion-page-router">
      {children}
      <AppQueryPopups />
    </IonPage>
  );
};

export default AppPageProvider;
