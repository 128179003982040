import type { FC } from "react";
import React from "react";
import ListUserSelectedConditions from "src/ui/components/ListUserSelectedConditions/ListUserSelectedConditions";

const TextWithKeywords: FC<{ text?: string }> = ({ text = "" }) => {
  return (
    <>
      {text.includes("__user_conditions__") ? (
        <ListUserSelectedConditions />
      ) : (
        <p className="m0 as-subhead">{text}</p>
      )}
    </>
  );
};

export default TextWithKeywords;
