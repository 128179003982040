import styled from "@emotion/styled";

const Item = styled.div<{
  px?: number;
  py?: number;
}>`
  padding-left: ${({ px = 0 }): string => `calc(${px} * 1em)`};
  padding-right: ${({ px = 0 }): string => `calc(${px} * 1em)`};
  padding-top: ${({ py = 0 }): string => `calc(${py} * 1em)`};
  padding-bottom: ${({ py = 0 }): string => `calc(${py} * 1em)`};
`;

export default Item;
