import envVariables from "./lib/envVariables";

// export default createClient({
//   token:
//     envVariables.PLATFORM === "develop"
//       ? (envVariables.SANITY_API_TOKEN_READONLY_ALL_CONTENT as
//         | string
//         | undefined)
//       : undefined,
//   projectId: "g38rxyoc",
//   dataset: "production",
//   apiVersion: "2023-05-03",
//   proxy: envVariables.CMS_API_BASE_URL
// });
class MockClient {
  fetch = async <T>(query: string): Promise<T> => {
    const url = new URL(`${envVariables.CMS_API_BASE_URL}/api/query`);
    url.searchParams.set("query", query);
    const res = await fetch(url);
    const json = await res.json();
    return json as T;
  };
}
export default new MockClient();
