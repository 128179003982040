import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import type { ParsedJwt } from "src/lib/parseJwt";
import parseJwt from "src/lib/parseJwt";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import type { ProviderCredentials } from "src/state/UserCubit/CredentialsProvider";
import CredentialsProvider from "src/state/UserCubit/CredentialsProvider";

export default class LocalStorageTokenCredentialsProvider extends CredentialsProvider {
  private readonly log = (message: string, etc?: unknown): void => {
    if (featureFlags.getFlag(FeatureFlagName.loggingAuthenticationManager)) {
      // eslint-disable-next-line no-console
      console.warn(`[CRED-P-TOKEN]: ${message}`, etc ?? "");
    }
  };

  currentCredentials: {
    refreshParsed?: ParsedJwt;
    accessParsed?: ParsedJwt;
    refreshToken?: string;
    accessToken?: string;
  } = {};

  async isAvailable(): Promise<boolean> {
    return true;
  }

  isVerified(): boolean {
      return true;
  }

  async isEnabled(): Promise<boolean> {
    return true;
  }

  async getCredentials(): Promise<ProviderCredentials | undefined> {
    StorageController.selectUserIdFromStorage();
    const userId = StorageController.activeUserId;
    let refreshToken = "";
    let accessToken = "";

    if (userId) {
      refreshToken = StorageController.getItem("refreshToken") ?? "";
      accessToken = StorageController.getItem("accessToken") ?? "";
    }

    const parsedRefreshToken = parseJwt(refreshToken);
    const parsedAccessToken = parseJwt(accessToken);

    if (parsedRefreshToken.expired) {
      refreshToken = "";
      this.log("Refresh token expired, clearing from local storage");
      if (userId) {
        StorageController.removeItem("refreshToken");
      }
    }

    if (parsedAccessToken.expired) {
      accessToken = "";
      this.log("Access token expired, clearing from local storage");
      if (userId) {
        StorageController.removeItem("accessToken");
      }
    }

    // check types
    if (refreshToken && parsedRefreshToken.type !== "REFRESH") {
      const error = new Error("Invalid refresh token type");
      reportErrorSentry(error);
    }

    if (accessToken && parsedAccessToken.type !== "ACCESS") {
      const error = new Error("Invalid access token type");
      reportErrorSentry(error);
    }

    this.log("Returning credentials", {
      parsedAccessToken,
      parsedRefreshToken
    });

    this.currentCredentials = {
      refreshParsed: parsedRefreshToken,
      accessParsed: parsedAccessToken,
      refreshToken,
      accessToken
    };

    return {
      refreshToken,
      accessToken
    };
  }

  async setCredentials(credentials: ProviderCredentials): Promise<void> {
    //verify credentials
    const parsedRefreshToken = parseJwt(credentials.refreshToken);
    const parsedAccessToken = parseJwt(credentials.accessToken);

    if (parsedRefreshToken.sub) {
      StorageController.setNewUserId(parsedRefreshToken.sub);
    }

    this.log("Setting credentials", {
      parsedAccessToken,
      parsedRefreshToken,
      current: this.currentCredentials
    });

    StorageController.setItem(
      "refreshToken",
      credentials.refreshToken ?? this.currentCredentials.refreshToken ?? ""
    );
    StorageController.setItem(
      "accessToken",
      credentials.accessToken ?? this.currentCredentials.accessToken ?? ""
    );

    this.currentCredentials = {
      refreshParsed: parsedRefreshToken,
      accessParsed: parsedAccessToken,
      refreshToken: credentials.refreshToken,
      accessToken: credentials.accessToken
    };
  }

  async deleteCredentials(): Promise<void> {
    StorageController.removeItem("refreshToken");
    StorageController.removeItem("accessToken");
  }
}
