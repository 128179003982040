import type { FC, ReactNode } from "react";
import React from "react";
import { getPrice } from "src/lib/getPrice";
import { getSupportedUserLocale } from "src/lib/i18next";
import translate from "src/lib/translate";
import { SubscriptionInterval } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import Translate from "src/ui/components/Translate/Translate";
import { CheckoutCartItemKey, CheckoutCartItemViewData } from "../SignupCustomStepCheckoutDynamicContent/SignupCustomStepCheckoutDynamicContent";
import { TranslationKey } from "src/types/translationKey";

interface Props {
  buttonText?: string;
  duration: SubscriptionInterval.monthly;
  additionalText?: ReactNode;
  cartItems: Record<CheckoutCartItemKey, CheckoutCartItemViewData>;
}

export const Legal: FC<Props> = ({
  duration,
  buttonText = translate("payNow"),
  additionalText,
  cartItems
}) => {
  const priceDiscountedInfo = getPrice(cartItems.onboarding.fee || cartItems.ongoingCare.fee, false, duration);
  const fullPriceInfo = getPrice(cartItems.ongoingCare.fee, true, duration);

  let msg: TranslationKey = "signup.legalText";
  if (cartItems.ongoingCare.fee === 0) {
    msg = "signup.legalText_noMonthlyBasePrice";
  }

  const displayPriceTotal = Intl.NumberFormat(getSupportedUserLocale(), {
    style: "currency",
    currency: "USD"
  }).format(fullPriceInfo.totalAmount);

  const displayPriceDiscount = Intl.NumberFormat(getSupportedUserLocale(), {
    style: "currency",
    currency: "USD"
  }).format(priceDiscountedInfo.totalAmount);

  return (
    <div slot="extra">
      <nine-spacer s="sm"></nine-spacer>
      <small className="color-c-80 as-tiny">
        <Translate
          msg={msg}
          variables={{
            buttonText: buttonText,
            priceDiscounted: displayPriceDiscount,
            price: displayPriceTotal,
            duration: translate("duration", { context: duration })
          }}
        />
        {additionalText}
      </small>
    </div>
  );
};
