import styled from "@emotion/styled";

export const Card = styled.div`
  --color-white: #ffffff;
  --light-shadow: 0px 0px 0px 0px rgba(69, 49, 22, 0.08),
    -5px 5px 16px 0px rgba(69, 49, 22, 0.08),
    -20px 20px 29px 0px rgba(69, 49, 22, 0.07),
    -46px 45px 39px 0px rgba(69, 49, 22, 0.03),
    -82px 81px 46px 0px rgba(69, 49, 22, 0.01);
  --border-radius: 0.3rem;

  background: var(--color-white);
  box-shadow: var(--light-shadow);
  border-radius: var(--border-radius);
  position: relative;
  padding: 1em 1.6em;
`;
