import type {
  AnswerItemDto,
  GetSuggestedTreatmentPlanResponse,
  TreatmentPlanPurchaseItem
} from "@9amhealth/openapi";

export interface Answer {
  id: string;
  fieldType: string | "choice" | "multiple-choice" | "text";
  fieldValue: string;
  value: number | string;
  key: string;
}

export enum KnownQuestionnaireRefId {
  register = "REGISTRATION_FORM"
}

export class SignupState {
  name = "initial";
  currentStep = -1;
  showBackButton = false;
  suggestion?: GetSuggestedTreatmentPlanResponse;
  error = "";
  hideProgressBar = false;
  hideContactInfoOnMobile = true;
  key: number | undefined = 1;
}

export class SignupStateInitial extends SignupState {
  suggestion = undefined;
}

interface SuggestionParams {
  currentStep: number;
  suggestion: GetSuggestedTreatmentPlanResponse;
}

export class SignupStatePurchaseFlow extends SignupState {
  name = "plan";
  suggestion = undefined;
  purchaseItems: TreatmentPlanPurchaseItem[];

  constructor(options: {
    purchaseItems?: TreatmentPlanPurchaseItem[];
    currentStep: number;
    showBackButton?: boolean;
    hideProgressBar?: boolean;
  }) {
    super();
    this.purchaseItems = options.purchaseItems ?? [];
    this.currentStep = options.currentStep;
    this.showBackButton = Boolean(options.showBackButton);
    this.hideProgressBar = Boolean(options.hideProgressBar);
  }
}

export class SignupStateSuggestion extends SignupState {
  suggestion: GetSuggestedTreatmentPlanResponse;

  constructor(options: SuggestionParams) {
    super();
    this.suggestion = options.suggestion;
    this.currentStep = options.currentStep;
  }
}

export class SignupStateSuggestionIneligible extends SignupStateSuggestion {
  name = "ineligible";
}

export class SignupStateSuggestionRegister extends SignupStateSuggestion {
  name = "account";
  hideContactInfoOnMobile = false;
}

export interface SuggestionQuestionnaireParams extends SuggestionParams {
  typeFormId: string;
  hiddenFields: AnswerItemDto[] | undefined;
  key?: number;
  hideContactInfoOnMobile?: boolean;
}

export class SignupStateSuggestionQuestionnaire extends SignupStateSuggestion {
  name = "questionnaire";
  typeFormId: string;
  hiddenFields: AnswerItemDto[] | undefined;

  constructor(options: SuggestionQuestionnaireParams) {
    super(options);
    this.typeFormId = options.typeFormId;
    this.hiddenFields = options.hiddenFields;
    this.key = options.key;
    this.hideContactInfoOnMobile = Boolean(
      options.hideContactInfoOnMobile ?? true
    );
  }
}

export interface HeaderStep {
  title: string;
  /* example: (s: number): boolean => s < 1 */
  disabled: (activeStep: number) => boolean;
  /* example: (s: number): boolean => s === 1 */
  active: (activeStep: number) => boolean;
  visible: boolean;
}

export const HeaderStepsTreatmentPlanFull: HeaderStep[] = [
  {
    title: "Basic information",
    active: (s: number): boolean => s === 0,
    disabled: (s: number): boolean => s < 0,
    visible: false
  },
  {
    title: "Create your account",
    active: (s: number): boolean => s === 1,
    disabled: (s: number): boolean => s < 1,
    visible: false
  },
  {
    title: "Your health",
    active: (s: number): boolean => s === 2,
    disabled: (s: number): boolean => s < 2,
    visible: true
  },
  {
    title: "Your Care plan",
    active: (s: number): boolean => s === 3,
    disabled: (s: number): boolean => s < 3,
    visible: true
  },
  {
    title: "Subscribe",
    active: (s: number): boolean => s === 4,
    disabled: (s: number): boolean => s < 4,
    visible: true
  }
];

export const HeaderStepsOrderFlow: HeaderStep[] = [
  {
    title: "Your cart",
    visible: true,
    active: (s: number): boolean => s === 0,
    disabled: (s: number): boolean => s < 0
  },
  {
    title: "Set up for your test",
    visible: true,
    active: (s: number): boolean => s === 1,
    disabled: (s: number): boolean => s < 1
  },
  {
    title: "Checkout",
    visible: true,
    active: (s: number): boolean => s === 2,
    disabled: (s: number): boolean => s < 2
  }
];
