import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC, ReactElement, ReactNode } from "react";
import { default as React, useEffect, useMemo } from "react";
import type { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import LoadingCubit from "src/state/LoadingCubit/LoadingCubit";
import { useBloc } from "src/state/state";
import { LoadingSpinner } from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlay";
import BlockingLoadingOverlayController, {
  BlockingLoadingOverlayState
} from "../BlockingLoadingOverlay/BlockingLoadingOverlayController";

const OuterWrap = styled.div<{
  absolute: 0 | 1;
  grow?: 0 | 1;
  isLoading: 0 | 1;
  background?: 0 | 1;
  overContent?: 0 | 1;
}>`
  label: LoadingOuterWrap;
  width: 100%;
  position: ${({ absolute, background }): string =>
    absolute ? "absolute" : background ? "fixed" : "relative"};
  top: ${({ absolute, background }): string =>
    absolute || background ? "0" : "auto"};
  bottom: ${({ absolute, background }): string =>
    absolute || background ? "0" : "auto"};
  height: ${({ grow }): string => (grow ? "100%" : "auto")};

  ${({ isLoading, background }): string =>
    isLoading && background
      ? `background: var(--color-cream); z-index: 9999;`
      : ""}

  ${({ isLoading, overContent }): string =>
    isLoading ? "" : overContent ? "pointer-events:none;" : ""}
`;

export const IndicatorWrap = styled.div`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
  position: relative;
  width: 4rem;
  height: 4rem;

  .loading-progress {
    opacity: 1;
  }

  .has-text & {
    transform: translate(-50%, -80%) scale(0.7);
  }
`;

interface SuspendProps {
  grow?: 0 | 1;
}

export const Suspend = styled.div<SuspendProps>`
  height: ${({ grow }): string => (grow ? "100%" : "auto")};
  opacity: 1;
  position: relative;
  transition: opacity 0.1s;

  &:after {
    pointer-events: none;
    content: "";
    cursor: progress;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.is-suspended {
    opacity: 0.5;
    &:after {
      pointer-events: all;
    }

    .has-text & {
      opacity: 0.05;
    }
  }
`;

export const LoadingIndicator: FC<{
  inline?: boolean;
  fixed?: boolean;
  setInlinePosition?: boolean;
}> = ({ inline, fixed, setInlinePosition = true }) => {
  const inlinePosition = inline ? "relative" : "absolute";
  const position = fixed ? "fixed" : inlinePosition;
  return (
    <IndicatorWrap
      className="indicator"
      style={{
        position: setInlinePosition ? position : undefined
      }}
    >
      <LoadingSpinner />
    </IndicatorWrap>
  );
};

const niceBool = (val: boolean | undefined): 0 | 1 => (val ? 1 : 0);

const LoadingText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0.3em);
  z-index: 2;
  font-weight: 500;
  font-size: 0.85em;
  pointer-events: none;
`;

const Loader: FC<{
  fullPage?: boolean;
  children?: ReactNode | undefined;
  loadingKey?: LoadingKey;
  absolute?: boolean;
  hideProgress?: boolean;
  grow?: boolean;
  active?: boolean;
  fixed?: boolean;
  background?: boolean;
  overContent?: boolean;
  className?: string;
  text?: ReactElement | string;
  gradient?: boolean;
  bg?: BlockingLoadingOverlayState["bg"];
  id?: string;
}> = (props): ReactElement => {
  const {
    fullPage,
    loadingKey,
    active,
    absolute = false,
    hideProgress = false,
    fixed = false,
    overContent = false,
    bg = "branded",
    className = ""
  } = props;
  const [allLoading, loader] = useBloc(LoadingCubit);
  const loaderId = useMemo(() => {
    return props.id ?? Math.random().toString(36).split(".")[1];
  }, []);
  const isLoading = useMemo(() => {
    if (typeof active === "undefined") {
      return loadingKey ? loader.isLoading(loadingKey) : allLoading.length > 0;
    }

    return active;
  }, [allLoading, loader, active]);

  const hasText = Boolean(props.text);
  const showFullPage = Boolean(props.gradient);

  useEffect(() => {
    const show = (showFullPage || fullPage) && isLoading;
    let showing = false;

    if (show) {
      showing = true;
      BlockingLoadingOverlayController.startLoading({ bg, fadeIn: false });
    } else {
      BlockingLoadingOverlayController.endLoading();
    }

    return () => {
      if (showing) BlockingLoadingOverlayController.endLoading();
    };
  }, [showFullPage, isLoading, fullPage]);

  if (showFullPage) {
    return (
      <div id={loaderId}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <OuterWrap
      id={loaderId}
      background={niceBool(props.background)}
      isLoading={niceBool(isLoading)}
      grow={niceBool(props.grow)}
      absolute={niceBool(absolute)}
      overContent={niceBool(overContent)}
      className={clsx(className, {
        "has-text": hasText
      })}
    >
      {isLoading && !hideProgress && <LoadingIndicator fixed={fixed} />}
      {isLoading && hasText && <LoadingText>{props.text}</LoadingText>}
      <Suspend
        grow={niceBool(props.grow)}
        className={clsx({
          "is-suspended": isLoading
        })}
      >
        {props.children}
      </Suspend>
    </OuterWrap>
  );
};

export default Loader;
