import { QuestionnaireDiagnosisOption } from "../constants/QuestionnaireDiagnosisOption";

export const amazonEligibleConditions = new Set([
  QuestionnaireDiagnosisOption.PREDIABETES,
  QuestionnaireDiagnosisOption.HIGH_BLOOD_PRESSURE,
  QuestionnaireDiagnosisOption.HIGH_CHOLESTEROL,
  QuestionnaireDiagnosisOption.OBSTRUCTIVE_SLEEP_APNEA,
  QuestionnaireDiagnosisOption.FATTY_LIVER_DISEASE
]);
export const amazonIneligibleConditions = new Set([
  QuestionnaireDiagnosisOption.DIABETES,
  QuestionnaireDiagnosisOption.POSSIBLE_DIABETES
]);

export const matchesAmazonEligibilityCriteria = (
  activeDiagnosis: Set<QuestionnaireDiagnosisOption>
): boolean => {
  if (
    Array.from(activeDiagnosis).some((d) => amazonIneligibleConditions.has(d))
  ) {
    return false;
  }
  return (
    Array.from(activeDiagnosis).filter((d) => amazonEligibleConditions.has(d))
      .length >= 2
  );
};
