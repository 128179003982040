import styled from "@emotion/styled";

const Highlights = styled.div`
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray-light);
  padding: var(--space-sm) calc(var(--space-sm) * 1.2);
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    li {
      width: 50%;
      list-style: none;
      padding: 0;
      font-weight: 400;
      font-size: calc(var(--sm-scale) * 0.88);
      line-height: 140%;
      letter-spacing: -0.02em;
      margin: 0.5em 0;
      color: var(--color-charcoal-80);

      display: flex;
      align-items: center;
      gap: 0.8em;

      @media (max-width: 768px) {
        width: 100%;
      }

      &:before {
        content: none;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export default Highlights;
