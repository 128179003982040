import { App } from "@capacitor/app";
import React, { useEffect } from "react";

const DebugCapInfo = () => {
  const [appinfo, setAppInfo] = React.useState<string | null>(null);
  useEffect(() => {
    void App.getInfo().then((info) => {
      setAppInfo(JSON.stringify(info, null, 2));
    });
  }, []);
  return (
    <div>
      <pre>{appinfo}</pre>
    </div>
  );
};

export default DebugCapInfo;
