import type {
  Provider,
  ProviderResponse,
  StoreUserPcpRequest,
  SupplyProviderInformationQuery
} from "@9amhealth/openapi";
import { ApiError, ProviderControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import { ErrorCode } from "src/constants/errorCodes";
import { extractErrorCode } from "src/lib/errors";
import formatPhoneNumber from "src/lib/formatPhoneNumber";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";

interface PatientPCPState {
  pcp?: ProviderResponse;
  pcpSearchResults?: ProviderResponse[];
  loading?: boolean;
  pcpSearchError?: string;
}

export enum PCPSearchFormKey {
  lastName = "lastName"
}

export type PCPSearchFormValues = Record<PCPSearchFormKey, string>;

export default class PatientPCPBloc extends Cubit<PatientPCPState> {
  temporaryPCPSearchFormValues: {
    current: PCPSearchFormValues | null;
  } = {
    current: null
  };

  constructor() {
    super({
      loading: false,
      pcpSearchError: undefined
    });
  }

  /**
   * Load the user's PCP from the API
   */
  public loadPCP = async () => {
    this.emit({
      ...this.state,
      loading: true
    });

    try {
      const response = await ProviderControllerService.lookupPcpForUserV2();
      const pcp = response.data;

      this.emit({
        ...this.state,
        pcp
      });
    } finally {
      this.emit({
        ...this.state,
        loading: false
      });
    }
  };

  /**
   * Search PCPs, updates the state with the PCP search results
   * @param fields
   */
  public searchPCPs = async (fields: PCPSearchFormValues) => {
    const lastName = fields[PCPSearchFormKey.lastName];

    if (!lastName.length) {
      throw new Error("Missing field: lastName");
    }

    this.emit({
      ...this.state,
      loading: true,
      pcpSearchError: undefined
    });

    try {
      // set all query params except lastName as undefined
      const response =
        await ProviderControllerService.searchPrimaryCarePhysiciansV2(
          undefined as unknown as SupplyProviderInformationQuery,
          undefined,
          undefined,
          undefined,
          undefined,
          lastName
        );

      // sort alphabetically by first name
      const sortedResults = response.data.content?.sort((a, b) =>
        a.provider.firstName.localeCompare(b.provider.firstName)
      );

      this.emit({
        ...this.state,
        pcpSearchResults: sortedResults
      });
    } catch (error: ApiError | unknown) {
      if (
        error instanceof ApiError &&
        extractErrorCode(error.body) === ErrorCode.pcpSearchInvalidData
      ) {
        this.emit({
          ...this.state,
          pcpSearchError: translate("invalid_last_name")
        });
      }
      reportErrorSentry(error);
      throw error;
    } finally {
      this.emit({
        ...this.state,
        loading: false
      });
    }
  };

  /**
   * Add a new pcp to the user's account, updates the state with the new pcp
   * @param pcp
   */
  public savePCP = async (pcp: ProviderResponse) => {
    if (!pcp.provider.firstName || !pcp.provider.lastName) {
      throw new Error("Missing provider details");
    }

    const primaryCareProvider: Provider = {
      ...pcp.provider,
      phone: formatPhoneNumber(pcp.provider.phone ?? ""),
      fax: formatPhoneNumber(pcp.provider.fax ?? "")
    };
    const pcpRequest: StoreUserPcpRequest = {
      primaryCareProvider
    };

    try {
      await ProviderControllerService.storePcpForUserV2(pcpRequest);
    } catch (error) {
      reportErrorSentry(error);
      throw error;
    } finally {
      this.emit({
        ...this.state,
        pcpSearchResults: undefined
      });
    }
  };

  /**
   * Clear pcp search results - set them to undefined
   */
  public clearPCPSearchResults = () => {
    this.emit({
      ...this.state,
      pcpSearchResults: undefined
    });
  };
}
