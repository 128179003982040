import { Blac, Cubit } from "blac-next";
import { TaskResponse } from "@9amhealth/openapi";
import {
  AppRemoteConfigCubit,
  JourneyContentResponse
} from "src/state/AppRemoteConfigCubit/AppRemoteConfigCubit";
import { taskManagementState } from "src/state/state";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import {
  TaskObserverEvent,
  TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";

export interface UserJourneyLessonItem {
  type: "typeform" | "article" | "coaching";
  typeformId?: string;
  url?: string;
  task?: TaskResponse;
}

export interface UserJourneyLesson {
  name: string;
  taskGroup?: string;
  items: UserJourneyLessonItem[];
}

export interface UserJourneyModule {
  name: string;
  subtitle: string;
  group: string;
  lessons: UserJourneyLesson[];
  index: number;
  totalModules: number;
}

export interface UserJourneyState {
  enableAll: boolean;
  modules?: UserJourneyModule[];
  name?: string;
  loading?: boolean;
}

interface UserJourneyProps {
  journey: string;
  taskProgram: string;
}

export default class UserJourneyBloc extends Cubit<
  UserJourneyState,
  UserJourneyProps
> {
  tasks: (TaskResponseKnown | undefined)[] = [];
  journeyContent: JourneyContentResponse | null = null;

  constructor(props: UserJourneyProps) {
    super({
      enableAll: false
    });
    this.props = props;
    void this.loadContent();
  }

  loadContent = async () => {
    if (!this.props) return;
    const { journey, taskProgram } = this.props;
    if (!journey || !taskProgram) return;

    this.patch({ loading: true });
    await Promise.all([this.loadJourney(journey), this.loadTasks()]);
    this.patch({ loading: false });
    this.prepareState();
    this.addObserverForTaskChanges();
  };

  onTasksChanged = (programs: Map<KnownProgram, TaskResponseKnown[]>): void => {
    if (!this.props?.taskProgram) return;
    const tasks = programs.get(this.props.taskProgram as KnownProgram);
    if (!tasks) return;
    this.tasks = tasks;
    this.prepareState();
  };

  addObserverForTaskChanges = (): void => {
    taskManagementState.addObserver(
      TaskObserverEvent.TASK_LIST_CHANGED,
      this.onTasksChanged
    );
  };

  loadJourney = async (journey: string) => {
    const response =
      await Blac.getBloc(AppRemoteConfigCubit).loadJourneyContent(journey);

    this.journeyContent = response.journey;

    this.patch({
      name: response.journey.name
    });
  };

  loadTasks = async () => {
    if (!this.props?.taskProgram) return;
    const tasks = await taskManagementState.loadProgramTasks(
      this.props.taskProgram as KnownProgram
    );
    this.tasks = tasks as TaskResponseKnown[];
  };

  prepareState = () => {
    const { journeyContent, tasks } = this;
    const taskProgram = this.props?.taskProgram ?? "";

    if (!journeyContent || tasks.length === 0) return;
    const classes = journeyContent.listOfClassesContentAvailable;
    const modules: UserJourneyModule[] = classes.map((c, index) => ({
      name: c.name,
      index,
      totalModules: classes.length,
      subtitle: c.subtitle,
      group: c.taskGroupReference,
      lessons: c.listOfLessons.map((l) => ({
        name: l.name,
        taskGroup: l.taskGroup,
        items: l.items.map((i) => ({
          type: i.type,
          typeformId: i.typeformId,
          taskSlug: i.taskSlug,
          url: i.url,
          task: tasks.find((t) => {
            return (
              t?.program === taskProgram &&
              t.slug === i.taskSlug &&
              t.group === l.taskGroup
            );
          })
        }))
      }))
    }));

    this.patch({
      modules
    });
  };

  onTaskStatusUpdated = (task: TaskResponse | undefined, status: string) => {
    if (!task) return;
    this.tasks = this.tasks.map((t) => {
      if (
        t?.program === task.program &&
        t.group === task.group &&
        t.slug === task.slug
      ) {
        return {
          ...t,
          status
        } as TaskResponse;
      }

      return t;
    });
    this.prepareState();
  };

  get questionnaireTasks(): TaskResponseKnown[] {
    return this.tasks.filter((t) =>
      t?.slug.includes("questionnaire")
    ) as TaskResponseKnown[];
  }
}
