import type { FC } from "react";
import React, { useCallback } from "react";
import SanityCubit from "src/state/SanityCubit/SanityCubit";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc, userPreferences } from "src/state/state";
import { EstablishCareStepSettings } from "src/types/sanitySchema";
import Loader from "src/ui/components/Loader/Loader";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import Translate from "../Translate/Translate";
import { useBloc as useBloc2 } from "@blac/react";

const EstablishCareStep: FC = () => {
  const [, { nextStep }] = useBloc2(SignupCustomBloc);
  const [, { fetchEstablishCareStepSettings }] = useBloc(SanityCubit, {
    create: () => new SanityCubit<EstablishCareStepSettings>()
  });

  const [loading, setLoading] = React.useState(false);

  // Fetch list of users from establish care step document
  const careTeamMembers = fetchEstablishCareStepSettings()?.careTeamMembers;

  const handleContinue = useCallback(() => {
    setLoading(true);
    void userPreferences
      .updateUserPreferences({
        [UserPreferenceKeys.establishCareCompleted]: true
      })
      .then(() => {
        void nextStep();
      });
  }, []);

  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        height: "100%"
      }}
    >
      <div>
        <Loader active={loading} fullPage>
          <nine-team
            hide-buttons-mobile
            text-align-mobile="left"
            style={{
              "--button-background": "var(--color-cream)",
              "--page-max-width": "1100px",
              maxWidth: "100vw",
              margin: 0
            }}
          >
            <div slot="text">
              <h3 className="as-h2">
                <Translate msg="establishCare.title" />
              </h3>
              <p className="as-body">
                <Translate msg="establishCare.description.firstParagraph" />
              </p>
              <p className="as-body">
                <Translate msg="establishCare.description.secondParagraph" />
              </p>
            </div>

            <div slot="items">
              {careTeamMembers?.map((user) => (
                <nine-card
                  variant="team"
                  gradient="true"
                  responsive
                  key={user.name}
                >
                  <img
                    slot="image"
                    src={user.image?.url}
                    alt={`${user.name}, ${user.description}`}
                  />
                  <h5 className="as-subhead strong" slot="title">
                    {user.name}
                  </h5>
                  <p className="as-little" slot="content">
                    {user.description}
                  </p>
                </nine-card>
              ))}
            </div>
          </nine-team>

          <nine-spacer s="xxl" class="hide-below-switch"></nine-spacer>
          <nine-center
            style={{
              minHeight: "unset",
              position: "relative",
              marginTop: "-2.5rem",
              zIndex: 2
            }}
          >
            <nine-button onClick={handleContinue}>
              <Translate msg="continue" />
            </nine-button>
          </nine-center>
          <nine-spacer s="xxl"></nine-spacer>
        </Loader>
      </div>
    </div>
  );
};
export default EstablishCareStep;
