import styled from "@emotion/styled";
import {
  ChartGridProps,
  ChartTooltipContentProps
} from "atom/chart/ChartBaseConfig";
import ChartTypeLine, { ChartLine } from "atom/chart/ChartTypeLine";
import React, { ReactNode } from "react";
import { ReferenceLineProps, XAxisProps, YAxisProps } from "recharts";

export type ChartType = "line";

export type ChartTypeCommonProps<T> = {
  type: ChartType;
  tooltip?: ChartTooltipContentProps<T>;
  grid?: ChartGridProps;
  referenceLines?: Omit<ReferenceLineProps, "ref">[];
};

export type ChartTypeLineProps<T> = {
  type: "line";
  chartData: T[];
  lines: ChartLine[];
  yAxis?: Omit<YAxisProps, "ref">;
  xAxis?: Omit<XAxisProps, "ref">;
} & ChartTypeCommonProps<T>;

const ChartStyles = styled.div`
  width: 100%;
  height: 100%;

  .recharts-cartesian-grid-horizontal,
  .recharts-cartesian-grid-vertical {
    line {
      stroke: var(--grid-line-stroke, #00000011);
      stroke-width: var(--grid-line-stroke-width, 1px);
      stroke-dasharray: 4px;
    }
  }
  .recharts-cartesian-grid-horizontal {
    line {
      &:first-of-type,
      &:last-of-type {
        opacity: 0;
      }
    }
  }
`;

function Chart<T>(
  props: { chartData: T[] } & Omit<ChartTypeLineProps<T>, "data">
): ReactNode | null {
  if (props.chartData.length === 0) return null;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (props.type === "line") {
    return (
      <ChartStyles>
        <ChartTypeLine<T> {...props} />
      </ChartStyles>
    );
  }
  return null;
}

export default Chart;
