import type { ReactNode } from "react";
import React from "react";
import reportErrorSentry from "src/lib/reportErrorSentry";
import ErrorPage from "src/ui/components/SentryBoundary/ErrorPage";

interface ErrorProps {
  children: ReactNode;
  fallback?: ReactNode;
}
interface ErrorState {
  hasError: boolean;
}

export default class LocalErrorBoundary extends React.Component<
  ErrorProps,
  ErrorState
> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    reportErrorSentry(error);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return this.props.fallback ?? <ErrorPage />;
    }
    return this.props.children;
  }
}
