import React, { FC, ReactNode } from "react";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import { useBloc } from "src/state/state";
import { Skeleton } from "src/ui/components/AsyncContent/AsyncContent";
import { CheckoutContentDiabetesAndPrevention } from "src/ui/components/SignupCustomContent/campaigns/generic/CheckoutContentDiabetesAndPrevention";
import { CheckoutContentGenericAllInOne } from "src/ui/components/SignupCustomContent/campaigns/generic/CheckoutContentGenericAllInOne";
import { CheckoutContentWeightManagement } from "src/ui/components/SignupCustomContent/campaigns/generic/CheckoutContentWeightManagement";
import type {
  CustomizedCheckoutScreenContentProps,
  ProgramVariableContent
} from "src/ui/components/SignupCustomContent/state/CustomizedCheckoutScreenBloc";
import CustomizedCheckoutScreenBloc from "src/ui/components/SignupCustomContent/state/CustomizedCheckoutScreenBloc";

export const variableProgramContent: ProgramVariableContent = {
  [ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION]:
    CheckoutContentDiabetesAndPrevention,
  [ProfileProgramMembership.HEALTHY_WEIGHT_JOURNEY]:
    CheckoutContentWeightManagement,
  [ProfileProgramMembership.ALLINONE_HEALTHCARE_CONCIERGE]:
    CheckoutContentGenericAllInOne,
  [ProfileProgramMembership.CDCDPP]: undefined,
  [ProfileProgramMembership.MEDICARE]: undefined
};

export interface CustomizedCheckoutScreenContentPropsWithPlaceholder
  extends CustomizedCheckoutScreenContentProps {
  placeholder: boolean;
}

export interface DynamicContentItemProps {
  eligibleFoeMedicalBilling: boolean;
}

export function makeFC<T extends DynamicContentItemProps>(comp: ReactNode): FC<T> {
  return () => comp;
}

export default function useContentForProgram(): CustomizedCheckoutScreenContentPropsWithPlaceholder {
  const [{ matchingContent }] = useBloc(CustomizedCheckoutScreenBloc, {
    create: () =>
      new CustomizedCheckoutScreenBloc({
        variableContent: variableProgramContent,
        findMatchingContent: true,
        fallbackContent: CheckoutContentGenericAllInOne,
        fallbackContentSlug: "general-all"
      })
  });

  return {
    imageSrc: matchingContent?.imageSrc ?? "",
    image:
      matchingContent?.image ??
      makeFC(
        <Skeleton
          width={"100%"}
          className="no-transform"
          style={{ aspectRatio: "458/256" }}
        />
      ),
    title:
      matchingContent?.title ??
      makeFC(<Skeleton width={"80%"} height={32} className="no-transform" />),
    subtitle:
      matchingContent?.subtitle ??
      makeFC(<Skeleton width={"100%"} height={56} className="no-transform" />),
    highlights:
      matchingContent?.highlights ??
      makeFC(<Skeleton width={"100%"} height={90} className="no-transform" />),
    note:
      matchingContent?.note ??
      makeFC(<Skeleton width={"100%"} height={50} className="no-transform" />),
    list:
      matchingContent?.list ??
      makeFC(<Skeleton className="no-transform" width={"100%"} height={350} />),
    slug: matchingContent?.slug ?? KnownProgram.GENERAL_ALL,
    placeholder: !matchingContent
  } satisfies CustomizedCheckoutScreenContentPropsWithPlaceholder & {
    placeholder: boolean;
  };
}
