import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import type { FC } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import reportErrorSentry from "src/lib/reportErrorSentry";
import useContentForProgram, {
  DynamicContentItemProps
} from "src/lib/useContentForProgram";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { SubscriptionInterval } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import { toast, useBloc } from "src/state/state";
import Loader from "src/ui/components/Loader/Loader";
import Price from "src/ui/components/Price/Price";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import Translate from "src/ui/components/Translate/Translate";
import { useBloc as useBloc2 } from "@blac/react";

const EnrollButton: FC<{
  checkMetadataPartnerFunnel?: string;
  onEnroll: () => void;
  loading: boolean;
}> = ({ checkMetadataPartnerFunnel, onEnroll, loading }) => {
  const [
    { allSubscriptions },
    { loadAllSubscriptions, filterAllSubscriptions, buySubscription }
  ] = useBloc(SubscriptionCubit);
  const [, { nextStep }] = useBloc2(SignupCustomBloc);

  useEffect(() => {
    void loadAllSubscriptions();
  }, []);

  const funnelSpecificSubscription = useMemo(() => {
    return filterAllSubscriptions({
      status: [
        SubscriptionDetailsResponse.status.DRAFT,
        SubscriptionDetailsResponse.status.IN_REVIEW,
        SubscriptionDetailsResponse.status.ACTIVE
      ],
      metadataPartnerFunnel: checkMetadataPartnerFunnel
        ? [checkMetadataPartnerFunnel]
        : undefined,
      duration: [SubscriptionInterval.monthly, SubscriptionInterval.quarterly],
      looseCheck: true
    })[0] as SubscriptionDetailsResponse | undefined;
  }, [allSubscriptions]);

  if (!funnelSpecificSubscription) {
    reportErrorSentry(new Error("User does not have funnel specific metadata"));

    return (
      <ErrorBox data-severity="error">
        <Translate msg="error.generic" />
      </ErrorBox>
    );
  }

  return (
    <nine-button
      variant="fill"
      color="black"
      onClick={() => {
        if (loading) return;

        onEnroll();
        void buySubscription(funnelSpecificSubscription.id, {
          onSuccess: () => void nextStep(),
          onError: () => {
            reportErrorSentry(new Error("Error buying subscription"));
            toast.error("error_generic");
          }
        });
      }}
    >
      <Translate msg="enroll_now" />
    </nine-button>
  );
};

const GenericCheckoutCustomizedCheckoutScreen: FC<{
  checkMetadataPartnerFunnel: string;
  totalPrice: number;
  eligibleFoeMedicalBilling: boolean;
}> = ({
  checkMetadataPartnerFunnel,
  totalPrice,
  eligibleFoeMedicalBilling
}) => {
  const content = useContentForProgram();
  const [loading, setLoading] = useState(false);

  const dynamicContentProps: DynamicContentItemProps = {
    eligibleFoeMedicalBilling
  };

  return (
    <>
      <Loader active={loading} fullPage>
        <nine-info-section
          style={{
            margin: "0.8em 0",
            width: `min(${APP_CONTENT_WIDTH}px, 100vw)`
          }}
        >
          <nine-info-container slot="content">
            <nine-spacer s="md"></nine-spacer>
            {content.image && <content.image {...dynamicContentProps} />}
            <nine-spacer s="md"></nine-spacer>
            <h5 className="strong m0">
              {content.title && <content.title {...dynamicContentProps} />}
            </h5>
            <p className="color-c-80">
              {content.subtitle && (
                <content.subtitle {...dynamicContentProps} />
              )}
            </p>
            <nine-spacer s="xs"></nine-spacer>
            {content.highlights && (
              <content.highlights {...dynamicContentProps} />
            )}

            {content.list && <content.list {...dynamicContentProps} />}
            <nine-spacer s="lg"></nine-spacer>
            <div className="as-little">
              {content.note && <content.note {...dynamicContentProps} />}
            </div>

            <nine-spacer s="md"></nine-spacer>
          </nine-info-container>
        </nine-info-section>

        {totalPrice === 0 && (
          <>
            <nine-info-section>
              <p slot="title" className="m0 strong as-tiny color-c-60">
                <Translate msg="summary" uppercase={true} />
              </p>
              <nine-info-container slot="content">
                <nine-info-row variant="medication" imageSrc={content.imageSrc}>
                  {content.image && <content.image {...dynamicContentProps} />}
                  <h5 slot="title" className="m0 strong">
                    {content.title && (
                      <content.title {...dynamicContentProps} />
                    )}
                  </h5>

                  <p slot="description" className="m0 color-c-80">
                    <Translate msg="membership.checkout.subtitle" />
                  </p>
                </nine-info-row>
                <nine-row px0 pyLarge borderGrayLight>
                  <p slot="title" className="m0 as-little color-c">
                    <Translate msg="signup_funnel_checkout_item_title_monthly_membership" />{" "}
                    (
                    <Translate msg="covered" />)
                  </p>
                  <p slot="label" className="m0 as-little color-c text-right">
                    <Price
                      amount={0}
                      duration={SubscriptionInterval.monthly}
                      formatLocale
                      keepNumber
                    />
                  </p>
                </nine-row>
                <nine-row px0 pyLarge borderGrayLight>
                  <h5 slot="title" className="m0 strong color-c">
                    <Translate msg="total" />
                  </h5>
                  <h5 slot="label" className="m0 strong color-c text-right">
                    <Price
                      amount={0}
                      formatLocale
                      keepNumber
                      duration={SubscriptionInterval.monthly}
                    />
                  </h5>
                </nine-row>
              </nine-info-container>
            </nine-info-section>

            <nine-center>
              <nine-spacer s="md"></nine-spacer>
              <EnrollButton
                loading={loading}
                onEnroll={() => setLoading(true)}
                checkMetadataPartnerFunnel={checkMetadataPartnerFunnel}
              />
            </nine-center>
          </>
        )}
      </Loader>
    </>
  );
};

export default GenericCheckoutCustomizedCheckoutScreen;
