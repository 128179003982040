import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React from "react";

const Title = styled.h3`
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 16px;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

interface Props {
  title?: string;
  children?: ReactNode | undefined;
  style?: React.CSSProperties;
}

export const TileWrapper: FC<Props> = ({ title, children, style }) => {
  return (
    <Wrapper style={style}>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
};
