import styled from "@emotion/styled";

export const Wrap = styled.div`
  position: relative;
  display: flex;
  gap: 0.5em;
  box-sizing: border-box;
  width: 100%;
  height: 240px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.6rem;
  justify-content: center;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    content: "";
    display: block;
    height: calc(50% - 32px / 2);
    z-index: 1;
    pointer-events: none;
  }
  &:before {
    top: -0.5px;
    border-bottom: 0.5px solid #000;
    background: linear-gradient(to bottom, #ffffff, #ffffff99);
  }
  &:after {
    bottom: -0.5px;
    border-top: 0.5px solid #000;
    background: linear-gradient(to top, #ffffff, #ffffff99);
  }
  .embla-picker {
    height: 100%;
    display: flex;
    align-items: center;
    width: calc(var(--item-width, 100px));
    justify-content: center;
    line-height: 1;
    font-size: 1.8rem;
  }
  .embla-picker__scene {
    min-width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    touch-action: pan-x;
  }
  .embla-picker__viewport {
    height: 32px;
    width: 100%;
    position: relative;
    perspective: 1000px;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  .embla-picker__viewport--perspective-left {
    /* perspective-origin: calc(50% + 130px) 50%; */
    /* transform: translateX(27px); */
  }
  .embla-picker__viewport--perspective-right {
    /* perspective-origin: calc(50% - 130px) 50%; */
    /* transform: translateX(-27px); */
  }
  .embla-picker__viewport--align-right {
    perspective-origin: 100% 50%;
    --item-align: right;
  }
  .embla-picker__viewport--align-left {
    perspective-origin: 0% 50%;
    --item-align: left;
  }
  .embla-picker__container {
    height: 100%;
    width: 100%;
    position: absolute;
    transform-style: preserve-3d;
    will-change: transform;
  }
  .embla-picker__slide {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    font-size: 19px;
    text-align: var(--item-align, center);
    display: flex;
    align-items: center;
    justify-content: var(--item-align, center);
    backface-visibility: hidden;
    opacity: 0;
    transition: color;
    --text-normal: #000;
    --text-disabled: #00000044;

    &[data-valid="false"] {
      span {
        opacity: 0.2;
      }
    }
  }
  .embla-picker__label {
    font-weight: 700;
    transform: translateX(-55px);
    pointer-events: none;
  }
`;
