export const headerHeight = 80;
export const tabBarHeight = 55;
export const toolBarHeight = 74;
export const contentWidth = 520;
export const signupHeaderHeightMobile = 76;
export const signupHeaderHeightDesktop = 76;
export const signupHeaderBreakpoint = 1070;

export const toolBarPadding = 12;

export const APP_BREAKPOINT = 730;
export const APP_BREAKPOINT_SM = 420;
export const TOP_NAV_HEIGHT = 64;
export const APP_CONTENT_WIDTH = 490;
export const APP_CONTENT_WIDTH_WITHOUT_PADDING = 442;
export const APP_CONTEND_WIDTH_LIFESTYLE = 474;
export const APP_CONTENT_WIDTH_APPOINTMENT = 410;
