import type { UpdateTaskRequest } from "@9amhealth/openapi";
import { TaskResponse } from "@9amhealth/openapi";
import type { FC } from "react";
import React from "react";
import type { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import type {
  TaskKey,
  TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import TaskManagementBloc from "src/state/TaskManagementBloc/TaskManagementBloc";
import { useBloc } from "src/state/state";

const DebugTaskMocker: FC = () => {
  const [{ tasks }, { updateTaskStatus }] = useBloc(TaskManagementBloc);
  const availableStatus = Object.values(TaskResponse.status);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const programs = new Set<KnownProgram>();
  const groups = new Set<string>();
  const slugs = new Set<string>();

  tasks.forEach((task) => {
    programs.add(task.program as KnownProgram);
    groups.add(task.group);
    slugs.add(task.slug);
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const values = new FormData(e.currentTarget);

    const t = {
      program: values.get("program") as KnownProgram,
      group: values.get("group") as string,
      slug: values.get("slug") as TaskKey
    };

    const status = values.get("status") as UpdateTaskRequest.status;
    setError("");
    setLoading(true);
    void updateTaskStatus(t as TaskResponseKnown, status)
      .catch((tmError: unknown) => {
        setError(String((tmError as { message?: string }).message));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Program:
        <input type="text" name="program" list="programs" />
        <datalist id="programs">
          {Array.from(programs).map((program) => {
            return (
              <option key={program} value={program}>
                {program}
              </option>
            );
          })}
        </datalist>
      </label>
      <br />

      <label>
        Group:
        <input type="text" name="group" list="groups" />
        <datalist id="groups">
          {Array.from(groups).map((group) => {
            return (
              <option key={group} value={group}>
                {group}
              </option>
            );
          })}
        </datalist>
      </label>
      <br />

      <label>
        Slug:
        <input type="text" name="slug" list="slugs" />
        <datalist id="slugs">
          {Array.from(slugs).map((slug) => {
            return (
              <option key={slug} value={slug}>
                {slug}
              </option>
            );
          })}
        </datalist>
      </label>
      <br />

      <label>
        Status:
        <select name="status">
          {availableStatus.map((status) => {
            return (
              <option key={status} value={status}>
                {status}
              </option>
            );
          })}
        </select>
      </label>
      <br />
      <button type="submit">Submit</button>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
    </form>
  );
};

export default DebugTaskMocker;
