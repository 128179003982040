import styled from "@emotion/styled";

export const Box = styled.div<{ width?: string }>`
  padding: 0;
  height: ${({ width }): string => width ?? "auto"};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--color-gray);
  margin: 10px 0;
`;

export const Accordion = styled.details``;

export const AccordionSummary = styled.summary``;

export const AccordionDetails = styled.div``;

export const LegacyCard = styled.div`
  box-shadow: var(--light-shadow);
  padding: 1rem;
  border-radius: 0.5rem;
  background: white;

  h4 {
    font-size: calc(var(--font-size) * 1.4);
    text-align: center;
    margin: 0;
    margin: 1rem 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h5 {
    font-size: calc(var(--font-size) * 1.1);
    margin: 0;
  }

  h6 {
    font-size: calc(var(--font-size) * 1);
    margin: 0;
  }

  em {
    font-size: calc(var(--font-size) * 0.9);
  }
`;
