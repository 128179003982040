import {
  RegisterTokenRequest,
  TokenControllerService
} from "@9amhealth/openapi";
import { App } from "@capacitor/app";
import type {
  ActionPerformed,
  PermissionStatus,
  PushNotificationSchema,
  RegistrationError,
  Token
} from "@capacitor/push-notifications";
import { PushNotifications } from "@capacitor/push-notifications";
import { Cubit } from "blac";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings
} from "capacitor-native-settings";
import { globalEvents } from "src/constants/globalEvents";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import createTrackEvent from "src/lib/createTrackEvent";
import envVariables from "src/lib/envVariables";
import { isHybridApp, isIOS } from "src/lib/platform";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { tracker, userPreferences } from "src/state/state";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";

interface PushNotificationsState {
  token?: string;
  permissions?: PermissionStatus;
  pushHistory: PushNotificationSchema[];
}

class PushNotificationsBloc extends Cubit<PushNotificationsState> {
  isAvailable = isHybridApp();
  nativeSettings = NativeSettings;

  navigate = (path: string): void => {
    // eslint-disable-next-line no-console
    console.warn("NAVIGATE METHOD NOT IMPLEMENTED");
    window.location.href = path;
  };

  constructor() {
    super({
      pushHistory: []
    });

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      void this.unregister();
    });
  }

  async init(): Promise<void> {
    try {
      await this.addListeners();

      // check permissions
      const permissions = await this.checkPermissions();

      // if permissions are granted, load delivered notifications
      if (permissions.receive === "granted") {
        void this.updateToken();
        await this.getDeliveredNotifications();
      }
    } catch (error) {
      reportErrorSentry(error);
    }
  }

  addListeners = async (): Promise<void> => {
    if (!this.isAvailable) return;

    await PushNotifications.addListener(
      "registration",
      this.registrationHandler
    );
    await PushNotifications.addListener(
      "registrationError",
      this.registrationErrorHandler
    );

    await PushNotifications.addListener(
      "pushNotificationReceived",
      this.pushNotificationReceivedHandler
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      this.pushNotificationActionPerformedHandler
    );
  };

  requestPermissions = async (
    options: { goToSettings?: boolean } = {}
  ): Promise<{
    permissions: string;
    token: string;
  }> => {
    if (!this.isAvailable) {
      return {
        permissions: "",
        token: ""
      };
    }

    const { goToSettings = false } = options;

    const result: { permissions: string; token: string } = {
      permissions: "unknown",
      token: ""
    };

    // check permissions
    const permissions = await this.checkPermissions();
    tracker.track(createTrackEvent("Push Notification Access - Check"), {
      data: {
        "Current Permission Status": permissions.receive
      }
    });

    result.permissions = permissions.receive;

    switch (permissions.receive) {
      case "granted":
        // if permissions are "granted", register
        await PushNotifications.register();
        break;
      case "prompt":
        // if permissions are "prompt", request them
        await PushNotifications.requestPermissions();
        await PushNotifications.register();
        result.token = this.state.token ?? "no token set in state";

        break;
      case "prompt-with-rationale":
      case "denied":
        if (goToSettings) {
          // if permissions are "prompt-with-rationale", go to settings
          await this.nativeSettings.open({
            optionAndroid: AndroidSettings.AppNotification,
            optionIOS: IOSSettings.App
          });
          result.token = this.state.token ?? "no token set in state";
        }
        break;
    }

    if (result.token) {
      void this.updateUserNotificationPreference(true);
    }

    return result;
  };

  updateUserNotificationPreference = async (setTo: boolean) => {
    return userPreferences.updateUserPreferences({
      [UserPreferenceKeys.pushNotificationsCareTeam]: setTo
    });
  };

  checkPermissions = async (): Promise<PermissionStatus> => {
    let permissions: PermissionStatus = { receive: "prompt" };

    if (!this.isAvailable) return permissions;

    try {
      permissions = await PushNotifications.checkPermissions();
    } catch (error) {
      reportErrorSentry(error);
    }

    const currentUserPreferences =
      userPreferences.state[
        UserPreferenceKeys.appUserPushNotificationAccessRequestAction
      ];

    // set user preferences
    if (
      currentUserPreferences !== permissions.receive &&
      (permissions.receive === "granted" || permissions.receive === "denied")
    ) {
      await userPreferences.updateUserPreferences({
        [UserPreferenceKeys.appUserPushNotificationAccessRequestAction]:
          permissions.receive
      });
    }

    this.emit({ ...this.state, permissions });
    return permissions;
  };

  getDeliveredNotifications = async (): Promise<void> => {
    const notificationList =
      await PushNotifications.getDeliveredNotifications();
    this.emit({ ...this.state, pushHistory: notificationList.notifications });
  };

  /*
    Event Handlers
   */
  registrationHandler = (token: Token): void => {
    tracker.track(createTrackEvent("Push Notification Access - Accepted"));
    void this.saveToken(token.value);
    void this.checkPermissions();
  };

  registrationErrorHandler = (err: RegistrationError): void => {
    addSentryBreadcrumb("push-notifications", "Registration Error", "error");
    reportErrorSentry(
      new Error("Push Notification Registration Error", { cause: err })
    );
  };

  pushNotificationReceivedHandler = (
    notification: PushNotificationSchema
  ): void => {
    // eslint-disable-next-line no-console
    console.log(notification);
    tracker.track(
      createTrackEvent("Push Notification Access - Notification Received")
    );
  };

  pushNotificationActionPerformedHandler = (action: ActionPerformed): void => {
    tracker.track(
      createTrackEvent(
        "Push Notification Access - Notification Action Performed"
      )
    );

    const { tapTargetUrl = "" } = action.notification.data as {
      tapTargetUrl?: string;
    };

    if (tapTargetUrl) {
      //  push history to target URL
      this.navigate(tapTargetUrl);
    }
  };

  updateToken = async (): Promise<void> => {
    try {
      const token = await this.getToken();
      return await this.saveToken(token);
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  /*
    React Methods
    */
  setNavigateMethod = (navigate: (url: string) => void): void => {
    if (this.navigate !== navigate) {
      this.navigate = navigate;
    }
  };

  getToken = async (): Promise<string> => {
    if (!this.isAvailable) return "";

    const { token } = this.state;
    if (token) {
      return token;
    }

    const permissions = await this.checkPermissions();

    if (permissions.receive === "granted") {
      await PushNotifications.register();
    }

    return this.state.token ?? "";
  };

  /*
    Api Methods
    */

  async saveToken(token: string): Promise<void> {
    if (!token) return;
    if (!this.isAvailable) return;

    this.emit({ ...this.state, token });

    const appPlatform = isIOS()
      ? RegisterTokenRequest.appPlatform.IOS
      : RegisterTokenRequest.appPlatform.ANDROID;
    const pushPlatform = isIOS()
      ? RegisterTokenRequest.pushPlatform.APNS
      : RegisterTokenRequest.pushPlatform.FCM;

    try {
      await TokenControllerService.registerToken({
        token,
        pushPlatform,
        appPlatform,
        applicationId: envVariables.APPLICATION_ID,
        name: `Push Token v2`
      });
    } catch (error) {
      reportErrorSentry(error);
    }
    //  save token to server
    await this.checkPermissions();
  }

  unregister = async (): Promise<void> => {
    const { token } = this.state;
    if (!token) return;
    this.emit({ ...this.state, token: "" });
    try {
      await this.updateUserNotificationPreference(false);
      const pushPlatform = isIOS()
        ? RegisterTokenRequest.pushPlatform.APNS
        : RegisterTokenRequest.pushPlatform.FCM;

      const appInfo = await App.getInfo();
      const applicationId = appInfo.id;

      await TokenControllerService.deregisterToken({
        pushPlatform: pushPlatform,
        token,
        applicationId
      });

      await PushNotifications.unregister();
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  removeAllDeliveredNotifications = async (): Promise<void> => {
    try {
      const permissions = await this.checkPermissions();
      if (permissions.receive !== "granted") return;

      await PushNotifications.removeAllDeliveredNotifications();
    } catch (error) {
      reportErrorSentry(error);
    }
  };
}

export default PushNotificationsBloc;
