import { Cubit } from "blac";
import type { RefObject } from "react";

export interface OverlayState {
  isVisible: boolean;
  title: string;
  backAction?: (() => void) | null;
}

export default class OverlayCubit extends Cubit<OverlayState> {
  contentContainerRef: RefObject<HTMLDivElement> | null = null;
  actionContainerRef: RefObject<HTMLDivElement> | null = null;
  onCloseCallback: (() => void) | undefined = undefined;

  constructor() {
    super({
      isVisible: false,
      title: ""
    });
  }

  readonly setVisible = (isVisible: boolean): void => {
    this.emit({
      ...this.state,
      isVisible
    });

    if (!isVisible) {
      this.onCloseCallback?.();
    }
  };

  readonly setTitle = (title: string): void => {
    this.emit({
      ...this.state,
      title
    });
  };

  readonly setOnCloseCallback = (
    onCloseCallback: typeof this.onCloseCallback
  ): void => {
    this.onCloseCallback = onCloseCallback;
  };

  readonly setContentContainerRef = (
    ref: RefObject<HTMLDivElement> | null
  ): void => {
    this.contentContainerRef = ref;
  };

  readonly setActionContainerRef = (
    ref: RefObject<HTMLDivElement> | null
  ): void => {
    this.actionContainerRef = ref;
  };

  readonly setBackAction = (action: (() => void) | null): void => {
    this.emit({
      ...this.state,
      backAction: action
    });
  };
}
