import { Cubit } from "blac";

export interface BlockingLoadingOverlayErrorProps {
  title?: string;
  message?: string;
  retry?: () => void;
  onCancel?: () => void;
}

export interface BlockingLoadingOverlayState {
  status: "error" | "loading" | "none" | "success";
  error?: BlockingLoadingOverlayErrorProps;
  bg: "solid" | "transparent" | "branded" | "partner";
  fadeIn: boolean;
}

export class BlockingLoadingOverlayBloc extends Cubit<BlockingLoadingOverlayState> {
  constructor() {
    super({
      status: "none",
      bg: "branded",
      fadeIn: true
    });
  }
  loadingCounter = 0;

  public readonly endLoading = () => {
    this.loadingCounter = Math.max(this.loadingCounter - 1, 0);
    if (this.loadingCounter === 0) {
      this.emit({
        ...this.state,
        status: "none"
      });
    }
  };

  public readonly loadingSuccess = () => {
    this.emit({
      ...this.state,
      status: "success"
    });

    setTimeout(() => {
      this.endLoading();
    }, 1400);
  };

  public readonly loadingError = (error?: BlockingLoadingOverlayErrorProps) => {
    this.loadingCounter = Math.max(this.loadingCounter - 1, 0);
    this.emit({
      ...this.state,
      status: "error",
      error
    });
  };

  public readonly startLoading = (
    options: {
      bg?: BlockingLoadingOverlayState["bg"];
      fadeIn?: boolean;
    } = {}
  ) => {
    this.loadingCounter++;

    this.emit({
      ...this.state,
      error: undefined,
      status: "loading",
      bg: options.bg ?? "branded",
      fadeIn: options.fadeIn ?? true
    });
    return [this.loadingSuccess, this.loadingError];
  };

  public readonly callErrorRetry = () => {
    if (this.state.error?.retry) {
      this.state.error.retry();
    }
  };

  public readonly callOnCancel = () => {
    if (this.state.error?.onCancel) {
      this.state.error.onCancel();
    }
  };
}

const BlockingLoadingOverlayController = new BlockingLoadingOverlayBloc();
export default BlockingLoadingOverlayController;
