import React, { ReactNode } from "react";
import {
  LineProps,
  ReferenceLineProps,
  TooltipProps,
  XAxisProps,
  YAxisProps
} from "recharts";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";
import {
  NameType,
  ValueType
} from "recharts/types/component/DefaultTooltipContent";
import { BaseAxisProps } from "recharts/types/util/types";
import ChartTooltipContent, { ChartTooltipData } from "./ChartTooltipContent";

export enum ChartColors {
  Orange = "#FFBD70",
  Green = "#6FC696",
  Pink = "#F7BDE6",
  Red = "#FF7663",
  Blue = "#80AEFF",
  BlueLight = "#ABD4FF",
  Violet = "#CAA1FF",
  VioletLight = "#DCCBFF"
}

export type ChartGridProps = {
  horizontal?: boolean;
  vertical?: boolean;
  horizontalRatio?: number;
  strokeDasharray?: string;
  styles?: GridLineStyle & {
    evenLine?: GridLineStyle;
    oddLine?: GridLineStyle;
  };
};

export type GridLineStyle = {
  stroke?: string;
  "stroke-width"?: number;
  "stroke-dasharray"?: string;
};

export const ChartBaseAxisCommon: BaseAxisProps = {
  tick: { fontSize: 12, fill: "#00000066" },
  axisLine: false,
  tickLine: false
};

export const ChartBaseXAxis: Omit<XAxisProps, "ref"> = {
  ...ChartBaseAxisCommon,
  dataKey: "name",
  allowDataOverflow: true,
  height: 12,
  padding: { left: 1, right: 10 },
  minTickGap: 15
};

export const ChartBaseYAxis: Omit<YAxisProps, "ref"> = {
  ...ChartBaseAxisCommon,
  orientation: "right",
  width: 30
};

export const ChartBaseReferenceLine: Omit<ReferenceLineProps, "ref"> = {
  strokeDasharray: "4 4",
  strokeWidth: 2,
  stroke: "#00000033"
};

export type ChartLineProps = Omit<LineProps, "ref">;
export const ChartBaseLine: (opts: { color?: string }) => ChartLineProps = (
  opts = {}
) => ({
  strokeWidth: 2.5,
  connectNulls: true,
  stroke: opts.color,
  isAnimationActive: false,
  activeDot: {
    r: 7,
    strokeWidth: 2.5,
    stroke: "white",
    fill: opts.color
  },
  dot: {
    r: 7,
    strokeWidth: 2.5,
    stroke: "white",
    fill: opts.color
  }
});

export const ChartBaseLineChart: CategoricalChartProps = {
  margin: {
    top: 10,
    right: 0,
    left: 12,
    bottom: 10
  }
};

export type ChartTooltipContentProps<T> = {
  formatLabel?: (tick: ChartTooltipData<T>) => ReactNode;
  formatValue?: (tick: ChartTooltipData<T>) => ReactNode;
};

export function ChartBaseTooltip<T>(
  opts: ChartTooltipContentProps<T>
): TooltipProps<ValueType, NameType> {
  return {
    isAnimationActive: false,
    position: {
      y: 4.5
    },
    cursor: {
      strokeWidth: 2,
      stroke: "#212121"
    },
    offset: -2,
    filterNull: true,
    content: (p) => <ChartTooltipContent {...p} {...opts} />
  };
}
