import type { ItemType } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";

/**
 * Checks if the item type matches the expected type
 * This is a workaround for unknown types from the "item" parameter, there are many duplicate types for "ItemType" in the OpenApi code that are actually the same
 */
export const checkItemType = (item: unknown, type: ItemType): boolean => {
  // TODO: remove type casting when openApi is updated
  return (item as ItemType) === type;
};
