import React, { useId } from "react";
import { CheckboxGroupState, useCheckboxGroupState } from "react-stately";
import { AriaCheckboxGroupProps, useCheckboxGroup } from "react-aria";
import { CheckboxFieldError } from "atom/checkbox/checkboxComponents";
import { z } from "zod";
import { CheckboxGroupContext } from "atom/checkbox/CheckboxGroupContext";

export interface CheckboxGroupProps extends AriaCheckboxGroupProps {
  children: React.ReactNode;
  errorParser?: (props: CheckboxGroupProps) => string;
  error?: string | z.ZodIssue;
}

function CheckboxGroupNoRef(
  props: CheckboxGroupProps,
  ref: React.Ref<CheckboxGroupState | null>
) {
  const rid = useId();
  const {
    children,
    label,
    description,
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    }
  } = props;
  const state = useCheckboxGroupState(props);
  const {
    groupProps,
    labelProps,
    descriptionProps,
    errorMessageProps,
    isInvalid
  } = useCheckboxGroup(
    { ...props, "aria-labelledby": label ? undefined : rid },
    state
  );

  const errorString = errorParser(props);

  React.useImperativeHandle(ref, () => state, [state]);

  return (
    <div {...groupProps}>
      <span {...labelProps}>
        {label}
        {label && props.isRequired ? "*" : ""}
      </span>
      <CheckboxGroupContext.Provider value={state}>
        <span id={rid}>{children}</span>
      </CheckboxGroupContext.Provider>
      {description && (
        <div {...descriptionProps} className="little1">
          {description}
        </div>
      )}
      {isInvalid && (
        <CheckboxFieldError {...errorMessageProps} className="little1">
          {errorString}
        </CheckboxFieldError>
      )}
    </div>
  );
}

export const CheckboxGroup = React.forwardRef(CheckboxGroupNoRef);
