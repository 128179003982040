import styled from "@emotion/styled";
import type { SanityImageAssetDocument } from "@sanity/client";
import type { CSSProperties, FC } from "react";
import React, { useEffect, useRef } from "react";
import { CmsImageRootObject } from "src/types/sanitySchema";

const ImageWrapper = styled.div`
  label: ImageWrapper;
  overflow: hidden;
  aspect-ratio: var(--img-aspect);
  border-radius: var(--border-radius);
  width: 100%;
  background-size: cover;
  position: relative;
  z-index: 1;
  max-height: 100%;
  min-width: 100%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    z-index: 2;
    background-size: cover;
    background-position: center var(--img-y);
    background-image: var(--img-blurred);
    transition: opacity calc(var(--ease-time) * 2) var(--ease-type);
  }

  &[data-loaded="true"]::before {
    opacity: 0;
  }

  &[data-fill="true"] {
    position: absolute;
    inset: 0;
    z-index: 1;
    aspect-ratio: unset;
  }
`;

const AspectBlurImage = styled.img`
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  object-position: center var(--img-y);
  inset: 0;
`;

interface MinimalSanityImageAssetDocument {
  url?: string;
  metadata?: {
    dimensions?: {
      aspectRatio?: number;
    };
    lqip?: string;
  };
}

export function imageFromSrc(src: string): SanityImageAssetDocument {
  return {
    url: src,
    metadata: {
      dimensions: {},
      lqip: ""
    }
  } satisfies MinimalSanityImageAssetDocument as SanityImageAssetDocument;
}

const ResponsiveImage: FC<{
  image?: SanityImageAssetDocument | CmsImageRootObject;
  alt?: string;
  q?: number;
  fit?: string;
  initialAspectRatio?: number;
  customAspectRatio?: number;
  startWidth?: number;
  fillContainer?: boolean;
  imgY?: number;
  className?: string;
}> = ({
  image,
  q = 80,
  fit = "max",
  initialAspectRatio = 1.78,
  customAspectRatio = undefined,
  startWidth = 320,
  fillContainer = false,
  imgY = 0.5,
  className = ""
}) => {
  const w = startWidth;
  const src = image?.url;
  const blur = image?.metadata.lqip;
  const [loaded, setLoaded] = React.useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  const aspectRatio =
    customAspectRatio ??
    image?.metadata.dimensions.aspectRatio ??
    initialAspectRatio;

  const scale = (index: number): number => {
    const ratio = Math.floor(index * 1.3);
    const width = w * ratio;
    return width;
  };

  const defaultSrc = src
    ? `${src}?w=${scale(3)}&q=${q}&auto=format&fit=${fit}`
    : "";

  // fully responsive SrcSet
  const srcSet = (
    loaded && src
      ? [
          `${src}?w=${scale(1)}&q=${q}&auto=format&fit=${fit} ${scale(1)}w`,
          `${src}?w=${scale(2)}&q=${q}&auto=format&fit=${fit} ${scale(2)}w`,
          `${src}?w=${scale(3)}&q=${q}&auto=format&fit=${fit} ${scale(3)}w`,
          `${src}?w=${scale(4)}&q=${q}&auto=format&fit=${fit} ${scale(4)}w`,
          `${src}?w=${scale(5)}&q=${q}&auto=format&fit=${fit} ${scale(5)}w`,
          `${src}?w=${scale(6)}&q=${q}&auto=format&fit=${fit} ${scale(6)}w`
        ]
      : []
  ).join(", ");

  const sizes = (
    loaded && src
      ? [
          `(max-width: ${scale(1)}px) ${scale(1) - 80}px`,
          `(max-width: ${scale(2)}px) ${scale(2) - 80}px`,
          `(max-width: ${scale(3)}px) ${scale(3) - 80}px`,
          `(max-width: ${scale(4)}px) ${scale(4) - 80}px`,
          `(max-width: ${scale(5)}px) ${scale(5) - 80}px`,
          `${scale(6)}px`
        ]
      : []
  ).join(", ");

  useEffect(() => {
    if (loaded) {
      return;
    }
    if (defaultSrc && imageRef.current) {
      const ld = (useSrc: string): void => {
        const img = new Image();
        img.src = useSrc;
        img.onload = () => {
          setLoaded(true);
        };
      };

      const check = (): boolean => {
        const current = imageRef.current?.currentSrc ?? "";
        const hasSrc = Boolean(
          current && !current.includes(window.location.host)
        );
        if (hasSrc) {
          ld(current);
        }
        return hasSrc;
      };

      let success = check();
      if (!success) {
        const i = setInterval(() => {
          success = check();
          if (success) {
            clearInterval(i);
          }
        }, 50);
      }
    }
  }, [defaultSrc, imageRef, loaded]);

  return (
    <ImageWrapper
      style={
        {
          "--img-aspect": aspectRatio,
          "--img-blurred": blur ? `url(${blur})` : undefined,
          "--img-y": `${100 * imgY}%`
        } as CSSProperties
      }
      className={`responsive-image ${className}`}
      data-loaded={loaded}
      data-fill={fillContainer}
    >
      {defaultSrc && (
        <AspectBlurImage
          ref={imageRef}
          srcSet={srcSet}
          sizes={sizes}
          src={defaultSrc}
        />
      )}
    </ImageWrapper>
  );
};

export default ResponsiveImage;
