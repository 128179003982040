import type { NineInput } from "@9amhealth/wcl";
import type { CountryCode } from "libphonenumber-js";
import { parsePhoneNumber } from "libphonenumber-js";
import { addSentryBreadcrumb } from "./addSentryBreadcrumb";

export function validatePhoneNumber(
  number: string,
  countryCode?: CountryCode
): boolean {
  try {
    const parsed = parsePhoneNumber(number, countryCode);
    return parsed.isValid();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return false;
  }
}

export default function formatPhoneNumber(number: string): string | undefined {
  try {
    const parsed = parsePhoneNumber(number, "US");
    const valid = parsed.isValid();
    if (!valid) return undefined;

    return parsed.formatInternational().replace(/ /g, "");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return undefined;
  }
}

export function formatPhoneNumberNational(
  number?: string,
  onlyNumbers = true,
  options?: { noValidation?: boolean }
): string | undefined {
  const { noValidation = false } = options ?? {};
  try {
    if (!number) return undefined;
    const parsed = parsePhoneNumber(number, "US");

    if (!noValidation) {
      const valid = parsed.isValid();
      if (!valid) {
        return undefined;
      }
    }

    const formatted = parsed.formatNational();
    return onlyNumbers ? formatted.replace(/[^0-9]/g, "") : formatted;
  } catch (error) {
    addSentryBreadcrumb(
      "formatPhoneNumberNational",
      `Error: ${String(error)}`,
      "error"
    );
    return undefined;
  }
}

export function nineInputValidatePhoneNumber(
  input: NineInput,
  value: string
): string | undefined {
  let error = input.defaultValidate(input, value);

  if (!error) {
    const numberValid = validatePhoneNumber(value, "US");
    error = numberValid ? undefined : "invalid";
  }

  return error;
}

export function numbersAreEqual(a?: string, b?: string): boolean {
  if (!a || !b) return false;
  const aFmt = formatPhoneNumber(a);
  const bFmt = formatPhoneNumber(b);
  return aFmt === bFmt;
}
