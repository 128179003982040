const base = "https://cdn.sanity.io/images/g38rxyoc/production/";

export const iconUrlStar =
  base + "86b78d6737677337cd521cab8a9af6931db06c3f-20x20.svg";

export const iconUrlVideoPlay =
  base + "7ab66cc57a29f547c71cd2413668dd8abdc48819-20x20.svg";

export const iconUrlSettingsDials =
  base + "d7d49e4d77811f9ce2852641c170a47cde926dbf-20x20.svg";

export const iconUrlMedicalAvatar =
  base + "e0f2240b106df7cf0b90806bb095236035476015-20x20.svg";

export const iconUrlCareHandsHeart =
  base + "95574aa0859545a39b1ff64112e3758d3abf0e33-20x20.svg";

export const iconUrlCalendarDateSimple =
  base + "0ea4d7130104ecf6b88994fa1bd6ff0efe205034-20x20.svg";
