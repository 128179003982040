import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import useUserAuthenticatedState from "src/state/hooks/useUserAuthenticatedState";
import { useBloc } from "src/state/state";
import LoginForm from "src/ui/components/LoginForm/LoginForm";
import { Pad } from "src/ui/styled/Pad";
import Loader from "../Loader/Loader";
import { keyframes } from "@emotion/css";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FillPage = styled.div`
  label: FillPage;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-cream);
  z-index: 2000;
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 2em 0;
  transition: padding 0.3s;
  padding-top: calc(var(--ion-safe-area-top, 0));
  padding-bottom: var(--ion-safe-area-bottom, 0);
  transition: padding-bottom 0.5s cubic-bezier(0.38, 0.7, 0.125, 1);
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out forwards;
  animation-delay: 0.5s;

  &:has(:focus-within) {
    padding-bottom: calc(
      var(--stored-keyboard-height, 0px) + var(--ion-safe-area-bottom, 0)
    );
  }
`;

const Content = styled.div`
  label: Content;
  width: min(100%, 620px);
  padding: 2em 0;
  margin: auto;
`;

const LoginOverlay: FC = () => {
  const authenticated = useUserAuthenticatedState();
  const [{ authenticationStatus }] = useBloc(AuthenticationBloc);
  if (authenticated) {
    return null;
  }

  if (authenticationStatus === "pending") {
    return <Loader gradient active fixed />;
  }

  return (
    <FillPage>
      <Content>
        <Pad className="wrap-center">
          <LoginForm
            resetPasswordLink={createAppPath(Path.authResetPassword, "auth")}
            registerLink={"/signup/universal" as Path}
            noFrame
          />
        </Pad>
      </Content>
    </FillPage>
  );
};

export default LoginOverlay;
