import SanityService from "src/api/SanityService";
import { Condition } from "src/constants/conditions";
import { diabetesConditions } from "src/constants/conditions";
import type { MedicalCondition } from "src/state/UserCubit/UserCubit";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import sanityQuery from "./sanityQuery";
import Language from "src/constants/language";
import { EducationalContent } from "src/ui/components/UserEducationalFeed/UserEducationalFeedBloc";

export interface Journey {
  journey: string;
  date: string;
}

interface ClassContent {
  language: string;
  name: string;
  listOfEducationalContent: { _ref: string; _key: string; _type: string }[];
  _createdAt: string;
  _id: string;
  _rev: string;
  _type: string;
  _updatedAt: string;
}

export interface JourneyContent {
  name: string;
  language: string;
  type: string;
  interval: number;
  listOfClasses: ClassContent[];
}

export enum JourneyName {
  default = "Default"
}

export enum MedicalCategory {
  diabetes = "diabetes",
  hypertension = "hypertension",
  weightLoss = "weight-loss"
}

export enum DefaultCategory {
  all = "all",
  nutrition = "nutrition",
  fitness = "fitness",
  mentalHealth = "mental-health",
  heartHealth = "heart-health"
}

export type Category = DefaultCategory | MedicalCategory;

export const categoryIds: Record<Category, string> = {
  [DefaultCategory.all]: "",
  [DefaultCategory.nutrition]: "51f65264-13eb-4b9e-bd5c-a4ded6b86125",
  [DefaultCategory.fitness]: "ca61da00-d9d5-4dbd-ab17-1d897cdcc87c",
  [DefaultCategory.heartHealth]: "9ca25205-008f-49af-9283-9579b39799bb",
  [DefaultCategory.mentalHealth]: "91acc25f-67db-45f7-8631-8bb952a35496",
  [MedicalCategory.diabetes]: "6c04b2a7-9a90-4a95-81e9-ebb82e6e6b02",
  [MedicalCategory.hypertension]: "fc7a9524-f7f4-47e7-960f-1da802f0da91",
  [MedicalCategory.weightLoss]: "f19031c8-58ff-4375-bbe5-45ba1b2e56ce"
};

export const getClassNumber = (
  enrollmentDate: string,
  journeyInterval: number
) => {
  const today = new Date();
  const enrollmentDateFormatted = new Date(enrollmentDate);
  const difference = today.getTime() - enrollmentDateFormatted.getTime();
  const daysPassed = Math.floor(difference / (1000 * 60 * 60 * 24));
  return parseInt((daysPassed / journeyInterval).toString());
};

export const hasCondition = (
  conditions: MedicalCondition[],
  checkedCondition: Condition
): boolean => {
  return conditions.some(
    (condition) => condition.condition.name === checkedCondition
  );
};

export const getCategories = (
  programs: ProfileProgramMembership[] | undefined,
  conditions: MedicalCondition[]
) => {
  const categories: Category[] = Object.values(DefaultCategory);

  const hasDiabetes = conditions.some((medCondition) =>
    diabetesConditions.has(medCondition.condition.name as string)
  );

  const hasHyperTension = hasCondition(conditions, Condition.highBloodPressure);
  const isInWeightLossProgram = programs?.includes(
    ProfileProgramMembership.HEALTHY_WEIGHT_JOURNEY
  );

  if (hasDiabetes) {
    categories.push(MedicalCategory.diabetes);
  }
  if (hasHyperTension) {
    categories.push(MedicalCategory.hypertension);
  }
  if (isInWeightLossProgram) {
    categories.push(MedicalCategory.weightLoss);
  }

  return categories;
};

export const getCategoriesIds = (categories: Category[]): string[] => {
  const ids: string[] = [];
  categories.forEach((category) => ids.push(categoryIds[category]));
  return ids;
};

export const getJourneyContent = async (
  userJourney = JourneyName.default,
  language = Language.en
) => {
  const journeyContent: JourneyContent[] = await SanityService.fetchSanityData(
    sanityQuery.educationalUserJourney(userJourney, language)
  );

  return journeyContent;
};

export const getEducationalContentByJourneyAndCategories = async (options: {
  categories: Category[];
  language: Language;
  userClassNumber?: number;
}): Promise<{
  cmsItems: EducationalContent[];
}> => {
  const journeyContent = await getJourneyContent(
    JourneyName.default,
    options.language
  );

  const classEducationalItems = journeyContent[0].listOfClasses.find(
    (c) => c.name === `Batch ${options.userClassNumber}`
  )?.listOfEducationalContent;

  const educationalItemsRefs =
    classEducationalItems?.map((item) => item._ref) ?? [];

  const categoriesIds = getCategoriesIds(options.categories);

  const cmsItems: EducationalContent[] = await SanityService.fetchSanityData(
    sanityQuery.EducationalContentClassItemsByCategory(
      options.language,
      categoriesIds,
      educationalItemsRefs
    )
  );

  // order cms items by order in educationalItems
  const orderedCmsItems = cmsItems.toSorted((a, b) => {
    const aIndex = educationalItemsRefs.indexOf(a._id);
    const bIndex = educationalItemsRefs.indexOf(b._id);
    return aIndex - bIndex;
  });

  return { cmsItems: orderedCmsItems };
};

export const getEducationalContent = async (
  categories: Category[],
  language = Language.en
) => {
  const categoriesIds = getCategoriesIds(categories);

  const cmsItems = await SanityService.fetchSanityData(
    sanityQuery.educationalContentByCategories(categoriesIds, language)
  );

  return { cmsItems };
};

export default getEducationalContent;
