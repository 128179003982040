import { Cubit } from "blac";

export default class InDialogBloc extends Cubit<{ isOpen: boolean }> {
  moduleRef: HTMLIonModalElement | null = null;

  constructor() {
    super({ isOpen: false });
  }

  open = () => {
    this.emit({ isOpen: true });
  };

  close = () => {
    this.emit({ isOpen: false });
  };

  setModuleRef = (moduleRef: HTMLIonModalElement | null) => {
    this.moduleRef = moduleRef;
  };
}
