import { Cubit } from "blac";
import type { FC } from "react";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { DynamicContentItemProps } from "src/lib/useContentForProgram";
import type { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import {
  ProfileAttributesKeys,
  type ProfileProgramMembership
} from "src/state/UserCubit/UserCubit";
import { userState } from "src/state/state";

export type ProgramVariableContent = Record<
  ProfileProgramMembership,
  CustomizedCheckoutScreenContentProps | undefined
>;

export interface CustomizedCheckoutScreenContentProps {
  imageSrc?: string;
  image?: FC<DynamicContentItemProps>;
  title?: FC<DynamicContentItemProps>;
  subtitle?: FC<DynamicContentItemProps>;
  highlights?: FC<DynamicContentItemProps>;
  note?: FC<DynamicContentItemProps>;
  list?: FC<DynamicContentItemProps>;
  listGettingStarted?: FC<DynamicContentItemProps>;
  listOngoingCare?: FC<DynamicContentItemProps>;
  slug?: KnownProgram;
}

export default class CustomizedCheckoutScreenBloc extends Cubit<{
  matchingContent?: CustomizedCheckoutScreenContentProps;
}> {
  // create proxy questionnaire cubit for requests and questionnaire methods
  questionnaireCubitProxy = new QuestionnaireCubit("", {
    disableTracking: true
  });

  variableContent?: ProgramVariableContent;

  constructor(options: {
    findMatchingContent?: boolean;
    fallbackContent?: CustomizedCheckoutScreenContentProps;
    fallbackContentSlug?: string;
    variableContent?: ProgramVariableContent;
  }) {
    super({});

    this.variableContent = options.variableContent;

    if (options.findMatchingContent) {
      void this.findMatchingContent({
        fallback: options.fallbackContent,
        fallbackSlug: options.fallbackContentSlug
      });
    }
  }

  findMatchingContent = async (options: {
    fallback?: CustomizedCheckoutScreenContentProps;
    fallbackSlug?: string;
  }): Promise<void> => {
    try {
      const profileAttributes = await userState.getProfileAttributes();
      const programs =
        profileAttributes?.[ProfileAttributesKeys.programMemberships] ?? [];

      for (const p of programs) {
        const programKey = p.program;

        for (const [key, value] of Object.entries(this.variableContent ?? {})) {
          if (programKey === key) {
            this.emit({
              matchingContent: {
                ...value
              }
            });
            return;
          }
        }
      }
    } catch (error) {
      reportErrorSentry(error);
    }

    this.emit({
      matchingContent: options.fallback
    });
  };
}
