import type {
  GetSuggestedTreatmentPlanResponse,
  TreatmentPlanPurchaseItem,
  UserAddressApiDto
} from "@9amhealth/openapi";
import {
  AddSubscriptionItemRequest,
  CareControllerService,
  GetSuggestedSubscriptionStepResponse,
  RemoveSubscriptionItemRequest,
  SubscriptionControllerService,
  SuggestedTreatmentPlan
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import { PHLEBOTOMY_SKU } from "src/constants/misc";
import Path from "src/constants/path";
import { checkItemType } from "src/lib/checkItemType";
import { createAppPath } from "src/lib/createAppPath";
import { extractErrorCode } from "src/lib/errors";
import { KnownPurchaseItemMetaDataKeys } from "src/lib/parsePurchaseItem";
import reportErrorSentry from "src/lib/reportErrorSentry";
import type { FileItem } from "src/state/FilesCubit/FilesCubit";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import type SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import UserCubit from "../UserCubit/UserCubit";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import {
  apiMiddleware,
  fileState,
  loadingState,
  subscriptionState,
  tracker,
  userPreferences,
  userState
} from "src/state/state";
import type { TreatmentPlanState } from "./TreatmentPlanState";
import { SmsNotificationsPermissionStatus } from "./TreatmentPlanState";
import nextStep = GetSuggestedSubscriptionStepResponse.nextStep;
import eligibility = SuggestedTreatmentPlan.eligibility;

export enum ItemType {
  SUBSCRIPTION_PLAN_SUGGESTION = "SubscriptionPlanSuggestion",
  LAB_TEST_SUGGESTION = "LabTestSuggestion",
  MEDICATION_SUGGESTION = "MedicationSuggestion",
  PRESCRIBED_MEDICATION = "PrescribedMedication",
  PRELIMINARY_MEDICATION_SUGGESTION = "PreliminaryMedicationSuggestion",
  OVER_THE_COUNTER_GLUCOMETER = "OverTheCounterGlucometer",
  OVER_THE_COUNTER_LAB_TEST = "OverTheCounterLabTest",
  OVER_THE_COUNTER_MICROLET_COLOR_LANCET_ITEM = "OverTheCounterMicroletColorLancetItem",
  OVER_THE_COUNTER_STRIP_ITEM = "OverTheCounterStripItem",
  prescription = "PrescriptionData",
  PRESCRIBED_SUPPLY = "PrescribedSupply"
}

export const MedicationItemTypes: ItemType[] = [
  ItemType.MEDICATION_SUGGESTION,
  ItemType.PRELIMINARY_MEDICATION_SUGGESTION
];

export enum SubscriptionInterval {
  oneTime = "P0D",
  monthly = "P1M",
  quarterly = "P3M"
}

/*
  Define the next step for any current step.
  This runs before we load the next step from the backend
  {  [from]: [to]  }
  when the there are no hits the backend will decide the next step.
  Custom steps should follow this format: "customStep:<customStepName>"
  when asking the backend for the next step only the "customStep" is used.
*/
interface CustomNextStep {
  name: string;
  apply: (state: TreatmentPlanState) => boolean;
}

const CUSTOM_NEXT_STEPS: Record<string, CustomNextStep | undefined> = {
  LAB_TEST_CUSTOMIZE: {
    name: "LAB_TEST_CUSTOMIZE:STRIPS",
    apply: (): boolean => {
      return userState.userIsOnInsulin;
    }
  }
};

export default class TreatmentPlanCubit extends Cubit<TreatmentPlanState> {
  public shippingFormData?: UserAddressApiDto;
  subscriptionCubit?: SubscriptionCubit;
  suggestedItems: TreatmentPlanPurchaseItem[];

  constructor(
    suggestedItems?: TreatmentPlanPurchaseItem[],
    dataNeededReasons = [],
    ineligibilityReasons = []
  ) {
    super({
      dataNeededReasons,
      ineligibilityReasons
    });
    this.suggestedItems = suggestedItems ?? [];

    this.initSubscription();

    // Tracker start
    tracker.startTimer(TrackEvent.subscription);
    this.subscriptionCubit = subscriptionState;
  }

  readonly initSubscription = (): void => {
    this.getTreatmentPlanSuggestion()
      .then((data) => {
        if (data?.eligibility === eligibility.ELIGIBLE) {
          void subscriptionState.initSubscription({
            renewalInterval: SubscriptionInterval.quarterly,
            useTreatmentPlan: true
          });
        }
      })
      .catch((e: unknown) => reportErrorSentry(e));
  };

  readonly getTreatmentPlanSuggestion = async (): Promise<
    GetSuggestedTreatmentPlanResponse | undefined
  > => {
    loadingState.start(LoadingKey.updateSuggestion);
    try {
      const data = await apiMiddleware.cachedSuggestTreatmentPlan();

      this.suggestedItems = data.purchaseItems ?? [];

      this.emit({
        ...this.state,
        dataNeededReasons: data.dataNeededReasons ?? [],
        ineligibilityReasons: data.ineligibilityReasons
      });

      loadingState.finish(LoadingKey.updateSuggestion);

      return data;
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.updateSuggestion);
  };

  readonly nextStep = async (pathname: string): Promise<void> => {
    const currentStep = this.getStepNameFromPathname(pathname);
    await this.loadNextStep(currentStep);
    this.emit({ ...this.state, step: this.state.nextStep });
  };

  readonly setStepByPathname = async (pathname: string): Promise<void> => {
    const currentStep = this.getStepNameFromPathname(pathname);
    await this.loadNextStep(currentStep);
    this.emit({
      ...this.state,
      step: {
        nextStep: currentStep as nextStep,
        configurationCompleted: false,
        nextStepInputDataAvailable: true
      }
    });
    this.trackPageEnter(currentStep);
  };

  readonly getStepNameFromPathname = (
    pathname: string
  ): GetSuggestedSubscriptionStepResponse.nextStep | string =>
    pathname
      .replace(createAppPath(Path.treatmentPlan, "join"), "")
      .replace(/\//g, "");

  readonly loadNextStep = async (currentStep: string): Promise<void> => {
    const customNextStep = CUSTOM_NEXT_STEPS[currentStep];
    const [useStep] = currentStep.split(":");

    if (customNextStep) {
      const useCustomNextStep = customNextStep.apply(this.state);
      if (useCustomNextStep) {
        const ns: GetSuggestedSubscriptionStepResponse = {
          nextStep: customNextStep.name as nextStep,
          configurationCompleted: false,
          nextStepInputDataAvailable: true
        };
        this.emit({ ...this.state, nextStep: ns });
        return;
      }
    }

    loadingState.start(LoadingKey.treatmentPlan);
    try {
      const { data } =
        await SubscriptionControllerService.suggestSubscriptionNextStep(
          useStep as never
        );
      this.emit({ ...this.state, nextStep: data });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.treatmentPlan);
  };

  readonly getSuggestionItems = (
    itemType: ItemType
  ): TreatmentPlanPurchaseItem[] => {
    return this.suggestedItems.filter((item) =>
      checkItemType(item.type, itemType)
    );
  };

  readonly checkHasPlaceholderMedications = (): boolean => {
    return this.getSuggestionItems(ItemType.MEDICATION_SUGGESTION).some(
      (item) =>
        checkItemType(item.type, ItemType.PRELIMINARY_MEDICATION_SUGGESTION)
    );
  };

  togglePending = false;
  readonly toggleLabTestChoice = async (set?: boolean): Promise<void> => {
    if (this.togglePending) {
      return;
    }

    this.togglePending = true;
    loadingState.start(LoadingKey.treatmentPlanLabTest);
    const fullSubscription = this.subscriptionCubit?.getFullSubscription();

    if (this.subscriptionCubit && fullSubscription) {
      const { id } = fullSubscription;
      const isAdded = this.subscriptionCubit.isAtHomeLabTestChosen(id);
      if ((set === true && isAdded) || (set === false && !isAdded)) {
        return;
      }

      try {
        if (this.subscriptionCubit.isAtHomeLabTestChosen(id)) {
          const atHomeLabTestItemId =
            this.subscriptionCubit.getSubscriptionPurchaseItems(
              id,
              ItemType.LAB_TEST_SUGGESTION
            )[0].id;
          await SubscriptionControllerService.removeSubscriptionItem(id, {
            itemToExpireIds: [atHomeLabTestItemId],
            expirationReason:
              RemoveSubscriptionItemRequest.expirationReason
                .LAB_TESTS_NOT_SELECTED
          });
        } else {
          await SubscriptionControllerService.addSubscriptionItem(id, {
            provider: AddSubscriptionItemRequest.provider.TREATMENT_PLAN,
            sku: this.getSuggestionItems(ItemType.LAB_TEST_SUGGESTION)[0]?.sku
          });
        }
        await this.subscriptionCubit.getSubscriptionDetails(id);
      } catch (e: unknown) {
        reportErrorSentry(e);
      } finally {
        this.togglePending = false;
      }
    }
    loadingState.finish(LoadingKey.treatmentPlanLabTest);
  };

  readonly uploadPhoto = async (file: File): Promise<void> => {
    try {
      loadingState.start(LoadingKey.treatmentPlan);
      await fileState.uploadFile({
        file,
        fileAttributes: {
          "subscription.identity.face": true
        }
      });
      const uploadedFile = this.getSubscriptionIdentityFaceFile();
      await fileState.loadFile({ id: uploadedFile?.id });
    } finally {
      loadingState.finish(LoadingKey.treatmentPlan);
    }
  };

  readonly uploadId = async (file: File): Promise<void> => {
    try {
      loadingState.start(LoadingKey.treatmentPlan);
      await fileState.uploadFile({
        file,
        fileAttributes: {
          "subscription.identity.id": true
        }
      });
      const uploadedFile = this.getSubscriptionIdentityIdFile();
      await fileState.loadFile({ id: uploadedFile?.id });
    } finally {
      loadingState.finish(LoadingKey.treatmentPlan);
    }
  };

  readonly requestSubscriptionChange = async (
    suggestedChanges: string
  ): Promise<{ error: string }> => {
    loadingState.start(LoadingKey.requestSubscriptionChange);
    let error = "";

    try {
      tracker.track(TrackEvent.REQUEST_CHANGES, {
        type: TrackType.complete,
        data: { Changes: suggestedChanges }
      });
      await CareControllerService.reportPatientIssue(suggestedChanges);

      await userPreferences.updateUserPreferences({
        [UserPreferenceKeys.requestedPlanChanges]: "true"
      });
    } catch (e: unknown) {
      reportErrorSentry(e);
      error = extractErrorCode(e);
    }
    loadingState.finish(LoadingKey.requestSubscriptionChange);
    return { error };
  };

  readonly setNotifications = async (phoneNumber: string): Promise<void> => {
    loadingState.start(LoadingKey.treatmentPlan);

    const { error } = await UserCubit.setNotificationNumber(phoneNumber);

    this.emit({
      ...this.state,
      smsNotificationsPermissionStatus: error
        ? SmsNotificationsPermissionStatus.FAILURE
        : SmsNotificationsPermissionStatus.SUCCESS
    });

    loadingState.finish(LoadingKey.treatmentPlan);
  };

  public readonly getSubscriptionIdentityFaceFile = ():
    | FileItem
    | undefined => {
    return fileState
      .getFileByAttribute({ "subscription.identity.face": true })
      .pop();
  };

  public readonly getSubscriptionIdentityIdFile = (): FileItem | undefined => {
    return fileState
      .getFileByAttribute({ "subscription.identity.id": true })
      .pop();
  };

  readonly trackPageExit = (outEvent: TrackType, step: string): void => {
    const fullSubscription = this.subscriptionCubit?.getFullSubscription();
    switch (step) {
      case nextStep.LAB_TEST_CUSTOMIZE:
      case undefined:
        tracker.track(TrackEvent.subCustomization, {
          type: outEvent,
          data: {
            "At home lab tests": this.subscriptionCubit?.isAtHomeLabTestChosen(
              fullSubscription?.id
            )
          }
        });
        break;
      case nextStep.SUBSCRIPTION_PERIOD_CUSTOMIZE:
        tracker.track(TrackEvent.subFrequency, {
          type: outEvent
        });
        break;
      case nextStep.SHIPMENT_ADDRESS_DATA:
        tracker.track(TrackEvent.subShippingForm, {
          type: outEvent
        });
        break;
      case nextStep.IDENTITY_VERIFICATION_FACE_PHOTO:
        tracker.track(TrackEvent.subVerifyFace, {
          type: outEvent
        });
        break;
      case nextStep.IDENTITY_VERIFICATION_ID_PHOTO:
        tracker.track(TrackEvent.subVerifyDocument, {
          type: outEvent
        });
        break;
      case nextStep.ORDER_REVIEW_WITH_PAYMENT_DATA:
        tracker.track(TrackEvent.subReviewAndPayment, {
          type: outEvent
        });
        break;
    }
  };

  // istanbul ignore next
  private readonly trackPageEnter = (step: string): void => {
    const fullSubscription = this.subscriptionCubit?.getFullSubscription();
    const hasPreliminaryMedication = Boolean(
      fullSubscription &&
        this.subscriptionCubit?.checkHasPlaceholderMedications(
          fullSubscription.id
        )
    );

    switch (step) {
      case nextStep.LAB_TEST_CUSTOMIZE:
        tracker.track(TrackEvent.subCustomization, {
          type: TrackType.start,
          data: fullSubscription
            ? {
                "With Preliminary Medication": hasPreliminaryMedication
              }
            : undefined
        });
        break;
      case nextStep.SUBSCRIPTION_PERIOD_CUSTOMIZE:
        tracker.track(TrackEvent.subFrequency, {
          type: TrackType.start
        });
        break;
      case nextStep.SHIPMENT_ADDRESS_DATA:
        tracker.track(TrackEvent.subShippingForm, {
          type: TrackType.start
        });
        break;
      case nextStep.IDENTITY_VERIFICATION_FACE_PHOTO:
        tracker.track(TrackEvent.subVerifyFace, {
          type: TrackType.start
        });
        break;
      case nextStep.IDENTITY_VERIFICATION_ID_PHOTO:
        tracker.track(TrackEvent.subVerifyDocument, {
          type: TrackType.start
        });
        break;
    }
  };

  get hasHomePhlebotomy(): boolean {
    return this.getSuggestionItems(ItemType.LAB_TEST_SUGGESTION).some(
      (item) => item.sku === PHLEBOTOMY_SKU
    );
  }

  get hasMandatoryLabTests(): boolean {
    return this.getSuggestionItems(ItemType.LAB_TEST_SUGGESTION).some((item) =>
      Boolean(
        (
          item.metadata[KnownPurchaseItemMetaDataKeys.mandatory] as
            | string[]
            | undefined
        )?.length
      )
    );
  }
}
