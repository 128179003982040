import React, { useEffect, type FC } from "react";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { authenticationState } from "src/state/state";

const DebugAuthQuery: FC<{ redirect?: boolean }> = (props) => {
  const { redirect = false } = props;
  const [showDone, setShowDone] = React.useState(false);

  useEffect(() => {
    const urlQuery = new URLSearchParams(window.location.search);

    const accessToken = urlQuery.get("accessToken");
    const refreshToken = urlQuery.get("refreshToken");
    const done = urlQuery.get("done") === "true";
    setShowDone(done);

    if (accessToken && refreshToken && !done) {
      StorageController.setItem("9am.e2e-auth", "true");
      void authenticationState
        .setCredentials({
          accessToken,
          refreshToken
        })
        .then(() => {
          if (redirect) {
            window.location.href = "?done=true";
          }
        });
    }
  }, []);

  return showDone ? <div id="done">Done</div> : null;
};

export default DebugAuthQuery;
