import Language from "../constants/language";
import { getSupportedUserLanguage } from "./i18next";

export enum Document {
  privacyPolicy = "privacyPolicy",
  termsOfService = "termsOfService",
  telehealthConsent = "telehealth",
  healthInformationRelease = "healthInformationRelease",
  noticePrivacyPractice = "noticePrivacyPractice"
}

type DocumentLink = {
  [language in Language]: string;
};

export const legalDocumentsLinks: Record<Document, DocumentLink> = {
  [Document.privacyPolicy]: {
    [Language.en]: "https://join9am.com/privacy-policy",
    [Language.es]: "https://join9am.com/es/privacy-policy"
  },
  [Document.termsOfService]: {
    [Language.en]: "https://join9am.com/terms-of-service",
    [Language.es]: "https://join9am.com/es/terms-of-service"
  },
  [Document.telehealthConsent]: {
    [Language.en]: "https://join9am.com/telehealth-consent",
    [Language.es]: "https://join9am.com/telehealth-consent"
  },
  [Document.healthInformationRelease]: {
    [Language.en]: "https://join9am.com/health-information-release",
    [Language.es]: "https://join9am.com/health-information-release"
  },
  [Document.noticePrivacyPractice]: {
    [Language.en]: "https://join9am.com/notice-of-privacy-practices",
    [Language.es]: "https://join9am.com/notice-of-privacy-practices"
  }
};

export const getLegalDocumentLink = (
  document: Document,
  language: Language = getSupportedUserLanguage()
): string => {
  const originalLink = legalDocumentsLinks[document][language];

  const url = new URL(originalLink);
  // add ?co=1
  url.searchParams.set("co", "1");
  url.searchParams.set("dialog", "true");

  return url.toString();
};
