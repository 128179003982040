import { StatusBar, Style } from "@capacitor/status-bar";
import { Cubit } from "blac";

interface StatusBarBlocState {
  theme: Style;
  navBarColor: string;
  popupOpen: boolean;
  title?: string;
  renderPortal?: boolean;
}

export const APP_DEFAULT_THEME_STYLE = Style.Light;
export const APP_DEFAULT_THEME_COLOR = "#fffcf3";

export default class StatusBarBloc extends Cubit<StatusBarBlocState> {
  static temporaryThemeColor: string | null = null;
  constructor() {
    super({
      theme: APP_DEFAULT_THEME_STYLE,
      navBarColor: APP_DEFAULT_THEME_COLOR,
      popupOpen: false
    });

    this.resetThemeDefault();
  }

  setThemeDark = () => {
    this.emit({
      ...this.state,
      theme: Style.Dark,
      navBarColor: "#000000"
    });
    void this.updateNative();
  };

  resetThemeDefault = () => {
    this.emit({
      ...this.state,
      theme: APP_DEFAULT_THEME_STYLE,
      navBarColor: StatusBarBloc.temporaryThemeColor ?? APP_DEFAULT_THEME_COLOR
    });
    void this.updateNative();
  };

  nativeTheme: Style = Style.Default;
  updateNative = async () => {
    let setTo = Style.Light;
    setTo = this.state.theme === Style.Dark ? Style.Dark : Style.Light;
    if (this.nativeTheme !== setTo) {
      this.nativeTheme = setTo;

      try {
        await StatusBar.setStyle({ style: Style.Light });
        await StatusBar.setBackgroundColor({ color: this.state.navBarColor });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        /* empty */
      }
    }
  };

  setPopupOpen = (popupOpen: boolean) => {
    this.emit({
      ...this.state,
      popupOpen
    });
  };

  setTitle = (title?: string) => {
    this.emit({
      ...this.state,
      title
    });
  };

  setRenderPortal = (renderPortal: boolean) => {
    this.emit({
      ...this.state,
      renderPortal
    });
  };

  subPageClose = () => {
    this.emit({
      ...this.state,
      title: undefined
    });
  };

  subPageOpen = () => {
    this.emit({
      ...this.state,
      title: this.state.title ?? ""
    });
  };
}
