import type { CodeableConcept } from "fhir/r4";
import reportErrorSentry from "src/lib/reportErrorSentry";

export const SYSTEM_LOINC = "http://loinc.org";
export const SYSTEM_BIOREF = "https://reports-qa.bioreference.com/test";
export const SYSTEM_UNITSOFMEASURE = "http://unitsofmeasure.org";

export enum LoincCodingCode {
  bloodPressure = "55284-4",
  bloodPressureWithChildrenOptional = "85354-9",
  bloodPressureSystolic = "8480-6",
  bloodPressureDiastolic = "8462-4",
  weight = "29463-7",
  height = "8302-2",
  waistCircumference = "56086-2",
  bloodGlucoseCapillary = "32016-8",
  bloodGlucoseCapillaryFasting = "1556-0",
  bmi = "39156-5",
  hba1c = "4548-4",
  stepsInDay = "41950-7"
}

export const LoincCoding = (code: LoincCodingCode): CodeableConcept => {
  const found = LoincCodingMap[code];
  if (!found) {
    const e = new Error(`No coding found for ${code}`);
    reportErrorSentry(e);
    throw e;
  }
  return found;
};

export const LoincCodingMap: Partial<Record<LoincCodingCode, CodeableConcept>> =
  {
    [LoincCodingCode.stepsInDay]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.stepsInDay,
          display: "Number of steps in 24 Hours, Measured"
        }
      ],
      text: "Number of steps"
    },
    [LoincCodingCode.bloodPressure]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.bloodPressure,
          display: "Blood pressure systolic and diastolic"
        }
      ],
      text: "Blood pressure"
    },
    [LoincCodingCode.bloodPressureWithChildrenOptional]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.bloodPressureWithChildrenOptional,
          display: "Blood pressure panel with all children optional"
        }
      ],
      text: "Blood pressure"
    },
    [LoincCodingCode.bloodGlucoseCapillary]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.bloodGlucoseCapillary,
          display: "Glucose [Mass/volume] in Capillary blood"
        }
      ],
      text: "Glucose [Mass/volume] in Capillary blood"
    },
    [LoincCodingCode.bloodGlucoseCapillaryFasting]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.bloodGlucoseCapillaryFasting,
          display: "Fasting glucose [Mass/volume] in Capillary blood"
        }
      ],
      text: "Fasting glucose [Mass/volume] in Capillary blood"
    },
    [LoincCodingCode.bloodPressureSystolic]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.bloodPressureSystolic,
          display: "Systolic Blood pressure"
        }
      ],
      text: "Systolic"
    },
    [LoincCodingCode.bloodPressureDiastolic]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.bloodPressureDiastolic,
          display: "Diastolic Blood pressure"
        }
      ],
      text: "Diastolic"
    },
    [LoincCodingCode.weight]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.weight,
          display: "Body weight"
        }
      ],
      text: "Body weight"
    },
    [LoincCodingCode.height]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.height,
          display: "Body height"
        }
      ],
      text: "Body height"
    },
    [LoincCodingCode.waistCircumference]: {
      coding: [
        {
          system: SYSTEM_LOINC,
          code: LoincCodingCode.waistCircumference,
          display: "Waist circumference"
        }
      ],
      text: "Waist circumference"
    }
  };

export const ValueDataForCodingCode = (
  code: LoincCodingCode
): { unit: string; code: string; comparator?: "<" | "<=" | ">" | ">=" } => {
  const found = ValueDataForCodingCodeMap[code];
  if (!found) {
    const e = new Error(`No value data found for ${code}`);
    reportErrorSentry(e);
    throw e;
  }
  return found;
};

export const ValueDataForCodingCodeMap: Partial<
  Record<
    LoincCodingCode,
    { unit: string; code: string; comparator?: "<" | "<=" | ">" | ">=" }
  >
> = {
  // steps in day: https://build.fhir.org/ig/HL7/physical-activity/Observation-Scen4ObservationDailySteps.json.html
  [LoincCodingCode.stepsInDay]: { unit: "steps", code: "/d" },
  [LoincCodingCode.bloodPressure]: { unit: "mmHg", code: "mm[Hg]" },
  [LoincCodingCode.bloodPressureWithChildrenOptional]: {
    unit: "mmHg",
    code: "mm[Hg]"
  },
  [LoincCodingCode.bloodPressureSystolic]: { unit: "mmHg", code: "mm[Hg]" },
  [LoincCodingCode.bloodPressureDiastolic]: { unit: "mmHg", code: "mm[Hg]" },
  [LoincCodingCode.weight]: { unit: "kg", code: "kg" },
  [LoincCodingCode.height]: { unit: "cm", code: "cm" },
  [LoincCodingCode.waistCircumference]: { unit: "cm", code: "cm" },
  [LoincCodingCode.bloodGlucoseCapillary]: { unit: "mg/dL", code: "mg/dL" },
  [LoincCodingCode.bloodGlucoseCapillaryFasting]: {
    unit: "mg/dL",
    code: "mg/dL"
  }
};
