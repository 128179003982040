import styled from "@emotion/styled";
import { Cubit } from "blac";
import type { FC } from "react";
import React from "react";
import { IconCloseCircle } from "src/constants/icons";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { toast, useBloc } from "src/state/state";
import FullVideoPlayer from "./FullVideoPlayer";
import type { VideoPlayerOptions } from "./FullVideoPlayerBloc";

const VidLayer = styled.div`
  position: fixed;
  inset: 0;
  z-index: 99999999;
  padding: 1rem;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  transition: background 0.2s ease-in-out;

  &[data-open="false"] {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0);

    #videoFrame,
    > button {
      opacity: 0 !important;
    }
  }

  &[data-open="true"] {
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.9);

    @media screen and (min-width: 700px) {
      background-color: rgba(0, 0, 0, 0.75);
    }

    #videoFrame {
      opacity: 1;
    }
  }
`;

const VidFrame = styled.div<{ ratio: string }>`
  width: 100%;
  background: white;
  aspect-ratio: var(--vid-ratio, 16 / 9);
  transform-origin: top left;
  position: fixed;
  left: 50%;
  top: 50%;

  width: calc(min(1920px, 100vw) - 2rem);
  height: auto;

  @media screen and (min-aspect-ratio: ${({ ratio }) => ratio}) {
    height: calc(min(1080px, 87vh) - 2rem);
    width: auto;
  }

  [data-tall="true"] & {
    top: calc(50% + 1.8rem);

    @media screen and (min-aspect-ratio: ${({ ratio }) => ratio}) {
      height: calc(min(1920px, 87vh) - 6.2rem);
      width: auto;
    }
  }
`;

const VidCloseButton = styled.button`
  background: #00000033;
  border: none;
  cursor: pointer;
  z-index: 1;
  --icon-color: white;
  position: absolute;
  border-radius: 50%;
  right: 0.6rem;
  top: -3.4rem;

  svg {
    --size: 2.8rem;
    width: var(--size);
    height: var(--size);
    display: block;
  }
`;

interface VideoPopupState {
  open: boolean;
  videoOptions?: VideoPlayerOptions;
  ratio: number;
}

class VideoPopupBloc extends Cubit<VideoPopupState> {
  animationSourceElement: HTMLElement | null = null;
  frameElement: HTMLDivElement | null = null;
  layerElement: HTMLDivElement | null = null;

  constructor() {
    super({ open: false, ratio: 16 / 9 });
  }

  onClose?: () => void;
  openVideo = (options: VideoPlayerOptions, onClose?: () => void) => {
    const hasSource = options.sources?.some((source) => source.src);
    if (!hasSource) {
      reportErrorSentry(new Error("No video source provided"));
      toast.error("video.error_loading");
      return;
    }

    this.emit({
      open: true,
      videoOptions: options,
      ratio: options.ratio ?? 16 / 9
    });

    this.animateFrameOpen();
    this.layerElement?.focus();
    this.setVideoUrlQuery(true);
    this.listenForClose();
    this.onClose = onClose;
  };

  setVideoUrlQuery = (withVideo: boolean) => {
    const url = new URL(window.location.href);
    const addToHistory = withVideo;
    if (withVideo) {
      url.searchParams.set("video", "true");
    } else {
      url.searchParams.delete("video");
    }

    if (addToHistory) {
      window.history.pushState({}, "", url.toString());
    } else {
      window.history.replaceState({}, "", url.toString());
    }
  };

  closeVideo = () => {
    if (this.frameElement) {
      this.frameElement.style.opacity = "0";
    }
    if (this.animationSourceElement) {
      this.animationSourceElement.closest("button")?.focus();
    }
    this.emit({ open: false, ratio: 16 / 9 });
    this.setVideoUrlQuery(false);
    if (this.onClose) {
      this.onClose();
      this.onClose = undefined;
    }
  };

  listenForClose = () => {
    window.addEventListener("popstate", () => {
      const url = new URL(window.location.href);
      if (!url.searchParams.has("video")) {
        this.closeVideo();
      }
    });
  };

  animateFrameOpen = () => {
    if (!this.animationSourceElement && this.frameElement) {
      this.frameElement.style.transform =
        "translate3d(-50%, -50%, 1px) scale(1)";
      this.frameElement.style.opacity = "1";
      return;
    }
    if (!this.animationSourceElement || !this.frameElement) return;

    // remove any existing animation
    this.frameElement.style.transition = "none";
    this.frameElement.style.transform = "none";
    this.frameElement.style.opacity = "0";

    const fromPosition = this.animationSourceElement.getBoundingClientRect();
    const toPosition = this.frameElement.getBoundingClientRect();

    // transform the frame to be at the same position as the source
    const dx = fromPosition.left - toPosition.left;
    const dy = fromPosition.top - toPosition.top;
    const scaleX = fromPosition.width / toPosition.width;
    const scaleY = fromPosition.height / toPosition.height;

    this.frameElement.style.transform = `translate3d(${dx}px, ${dy}px, 1px) scale(${scaleX}, ${scaleY})`;

    // force a repaint
    this.frameElement.getBoundingClientRect();

    // scale to be at final size and position
    this.frameElement.style.transition =
      "transform .5s var(--ease-type), .4s var(--ease-type)";

    // reset
    this.frameElement.style.transform = "translate3d(-50%, -50%, 1px) scale(1)";
    this.frameElement.style.opacity = "1";
  };

  setAnimationSource = (source: HTMLElement | null | undefined) => {
    this.animationSourceElement = source ?? null;
  };

  setFrameElement = (frame: HTMLDivElement | null) => {
    this.frameElement = frame;
  };

  setLayerElement = (layer: HTMLDivElement | null) => {
    this.layerElement = layer;
  };
}

export const VideoPopupController = new VideoPopupBloc();

const VideoPopup: FC = () => {
  const frameRef = React.useRef<HTMLDivElement>(null);
  const layerRef = React.useRef<HTMLDivElement>(null);
  const [state, { setFrameElement, setLayerElement }] = useBloc(
    VideoPopupBloc,
    {
      create: () => VideoPopupController
    }
  );

  React.useEffect(() => {
    setFrameElement(frameRef.current);
  }, [frameRef.current]);

  React.useEffect(() => {
    setLayerElement(layerRef.current);
  }, [layerRef.current]);

  React.useEffect(() => {
    // ensure that no video is open when the component is unmounted, and that the url query is cleared
    VideoPopupController.closeVideo();
    return () => {
      VideoPopupController.closeVideo();
    };
  }, []);

  const ratio = state.videoOptions?.ratio ?? 16 / 9;

  return (
    <VidLayer
      data-open={state.open}
      aria-hidden={!state.open}
      data-tall={ratio < 1.2}
      aria-label="Video Popup"
      tabIndex={0}
      aria-modal="true"
      ref={layerRef}
      style={
        {
          "--vid-ratio": ratio.toFixed(3)
        } as React.CSSProperties
      }
    >
      <VidFrame ratio={ratio.toFixed(3)} ref={frameRef} id="videoFrame">
        <VidCloseButton onClick={VideoPopupController.closeVideo}>
          <IconCloseCircle />
        </VidCloseButton>
        {state.open && state.videoOptions && (
          <FullVideoPlayer options={state.videoOptions} />
        )}
      </VidFrame>
    </VidLayer>
  );
};

export default VideoPopup;
