interface DecodedJwt {
  exp: number;
  iat: number;
  sub?: string;
  sess: string;
  role: string | "USER";
  type: string | "ACCESS" | "REFRESH" | "SAML_EXCHANGE";
  samlreg?: string;
}

export interface ParsedJwt extends DecodedJwt {
  expired: boolean;
  expiresInMs: number;
  expiresAt: Date;
}

const fallbackJwtData = {
  exp: 0,
  iat: 0,
  sub: "",
  sess: "",
  role: "",
  type: "",
  expired: true,
  expiresInMs: 0,
  expiresAt: new Date(0)
} satisfies ParsedJwt;

function parseJwt(token?: string): ParsedJwt {
  if (!token) {
    return fallbackJwtData;
  }
  const base64Url = token.split(".")[1] ?? "";

  if (!base64Url) {
    return fallbackJwtData;
  }
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const decoded = JSON.parse(jsonPayload) as DecodedJwt;
  const expTime = decoded.exp * 1000;

  const expiresInMs = expTime - Date.now();
  const expiresAt = new Date(expTime);

  return {
    ...decoded,
    expired: expTime < Date.now(),
    expiresInMs: expiresInMs < 0 ? 0 : expiresInMs,
    expiresAt
  } satisfies ParsedJwt;
}
export default parseJwt;
