import type { BgVariants } from "@9amhealth/wcl";
import type { ReactNode } from "react";
import type FunnelKey from "src/constants/funnelKey";
import type {
  CustomQuestionnaireAnswer,
  CustomQuestionnaireFilterAnswerOptions
} from "src/state/QuestionnaireCubit/QuestionnaireState";
import type { SignupSuccessPageProps } from "src/ui/components/SignupCustomContent/SignupSuccessPage";
import type { SignupCheckoutPageContentProps } from "src/ui/components/SignupCustomContent/steps/SignupCustomStepCheckoutCustomRender";

export interface SignupCustomPageParameters
  extends Record<string, string | undefined> {
  campaign?: string;
  step?: string;
}

export enum SignupCustomCampaignName {
  Medicare = "medicare",
  weightLoss = "weight-loss",
  Default = "default",
  Prescryptive = "prescryptive",
  Universal = "universal",
  CDC_DPP = "cdcdpp",
  Lilly = "lilly",
  SmithRx = "smithrx",
  eternalHealth = "eternalhealth",
  kwikTrip = "kwiktrip",
  evernorth = "evernorth",
  wipfli = "wipfli",
  stateOfIndiana = "state-of-indiana",
  transcarent = "transcarent",
  amazon = "amazon",
  lillyTeam = "lilly-team"
}

export enum SignupCustomStepType {
  Questionnaire = "questionnaire",
  InfoPage = "info-page",
  Authentication = "Authentication",
  Checkout = "Checkout",
  ShippingAddress = "ShippingAddress",
  SuccessPage = "SuccessPage",
  Action = "Action",
  Consents = "Consents"
}

export interface SignupCustomCampaignStepBase {
  name: string;
  path: string;
  isRequired?: boolean;
  background?: BgVariants | undefined;
  /**
   * Check if the step is complete
   * @returns true if the step is complete, false otherwise
   */
  dataComplete?: () => Promise<boolean> | boolean;
  onNextStep?: () => void;
  beforeNextStep?: () => Promise<void>;
}

export interface SignupCustomStepQuestionnaire
  extends SignupCustomCampaignStepBase {
  type: SignupCustomStepType.Questionnaire;
  questionnaireId: string;
  questionnaireName: string;
  answerOverrides?: CustomQuestionnaireAnswer[];
  filterAnswerOptions?: CustomQuestionnaireFilterAnswerOptions[];
}

export interface SignupCustomStepInfoPage extends SignupCustomCampaignStepBase {
  type: SignupCustomStepType.InfoPage;
  render: () => React.ReactNode;
}

export interface SignupCustomStepSuccessPage
  extends SignupCustomCampaignStepBase {
  type: SignupCustomStepType.SuccessPage;
  successPageProps: SignupSuccessPageProps;
  action?: (
    navigate: (path: string, options?: { replace?: boolean }) => void
  ) => void;
}

export interface SignupCustomStepAuthentication
  extends SignupCustomCampaignStepBase {
  type: SignupCustomStepType.Authentication;
}

export interface SignupCustomStepAuthConsents
  extends SignupCustomCampaignStepBase {
  type: SignupCustomStepType.Consents;
}

export interface SignupCustomStepCheckout extends SignupCustomCampaignStepBase {
  type: SignupCustomStepType.Checkout;
  checkoutPageProps: SignupCheckoutPageContentProps;
  createSubscriptionFunction: () => Promise<{ error?: string | undefined }>;
}
export interface SignupCustomStepShippingAddress
  extends SignupCustomCampaignStepBase {
  type: SignupCustomStepType.ShippingAddress;
  shippingFormPageProps?: {
    title?: ReactNode | string;
    subtitle?: ReactNode | string;
    afterForm?: ReactNode | string;
  };
}

export interface SignupCustomStepAction extends SignupCustomCampaignStepBase {
  type: SignupCustomStepType.Action;
  action: () => Promise<void>;
}

export type SignupCustomStep =
  | SignupCustomStepAction
  | SignupCustomStepAuthConsents
  | SignupCustomStepAuthentication
  | SignupCustomStepCheckout
  | SignupCustomStepInfoPage
  | SignupCustomStepQuestionnaire
  | SignupCustomStepShippingAddress
  | SignupCustomStepSuccessPage;

export type SignupCustomCampaignStepsMap = Record<
  SignupCustomCampaignName,
  SignupCustomStep[]
>;

export type SignupCustomCampaignPrettyNameMap = Record<
  SignupCustomCampaignName,
  string
>;

export interface SignupCustomState {
  campaign: SignupCustomCampaignName | string;
  step: string;
  previewMode?: boolean;
  /**
   * True if the current step is valid, false otherwise
   * a step is considered valid if we have checked that all previous steps are complete
   */
  currentStepValid: boolean;
  key: number;
}

export interface SignupCustomCampaign {
  name: SignupCustomCampaignName;
  logo?: string;
  logoSize?: "large" | "small";
  customLogo?: string;
  prettyName: string;
  funnelKey: FunnelKey;
  steps: () => SignupCustomStep[];
}
