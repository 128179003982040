import styled from "@emotion/styled";

export const StyledCheckbox = styled.input`
  --space-sm: 0.8rem;
  --color-charcoal-60: #21212199;
  --gradient-dusk: linear-gradient(
    90deg,
    #0e3763 0%,
    #5b4855 52.6%,
    #8a6947 100%
  );

  border-radius: 4px;
  padding: calc(var(--space-sm) * 0.125);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0 0.5em 0 0;
  --width: calc(var(--space-sm) * 1.5);
  --height: calc(var(--space-sm) * 1.5);
  width: var(--width);
  height: var(--height);
  min-width: var(--width);
  min-height: var(--height);
  --boder-color: var(--color-charcoal-60);
  border: 1px solid var(--boder-color);
  transform: translateY(0);
  display: grid;
  /* aligning ::before horizontally and vertically */
  place-content: center;
  position: relative;

  &::before {
    content: "";
    transform: scale(0);
    transition: transform 0.12s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
  }

  &:checked {
    border: none;
    background: linear-gradient(
      89.59deg,
      #80aeff 0.36%,
      #f7bde6 52.67%,
      #ffbd70 99.65%
    );

    &::after {
      border-radius: 3px;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
      background: linear-gradient(
        270.11deg,
        #ffdfb4 16.73%,
        #fddee9 50.08%,
        #c1d7fb 81.42%
      );
    }

    &::before {
      transform: scale(0.8);
      content: url('data:image/svg+xml;utf8, <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.1883 1.76986L15.669 0.298096L6.22459 9.76751L1.78081 5.32373L0.287109 6.76924L6.22463 12.7068L17.1883 1.76986Z" fill="url(%23paint0_linear_959_607)"/><defs><linearGradient id="paint0_linear_959_607" x1="0.287109" y1="6.50243" x2="17.1883" y2="6.50243" gradientUnits="userSpaceOnUse"><stop stop-color="%230E3763"/><stop offset="0.526042" stop-color="%235B4855"/><stop offset="1" stop-color="%238A6947"/></linearGradient></defs></svg>');
      position: absolute;
      inset: 0;
      height: 100%;
      display: grid;
      place-content: center;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &[aria-invalid="true"] {
    --boder-color: var(--primary-warning-red, #bf1902);
  }
`;

export const CheckboxFieldError = styled.div`
  padding: 0.5em 1em 1em;
  display: block;
  color: var(--primary-warning-red, #bf1902);

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: show 0.2s linear forwards;
`;

export const StyledLabel = styled.label`
  display: flex;

  &[data-disabled="true"] {
    opacity: 0.6;
  }
`;
