export enum ObservationCategory {
  ACTIVITY = "ACTIVITY",
  EXAM = "EXAM",
  IMAGING = "IMAGING",
  LABORATORY = "LABORATORY",
  NULL = "NULL",
  PROCEDURE = "PROCEDURE",
  SOCIALHISTORY = "SOCIALHISTORY",
  SURVEY = "SURVEY",
  THERAPY = "THERAPY",
  VITALSIGNS = "VITALSIGNS"
}
