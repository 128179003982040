import type { VerifyEmailRequest } from "@9amhealth/openapi";
import { UserEmailVerificationControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { extractErrorCode } from "src/lib/errors";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import type { UserEmailVerificationStateInitial } from "src/state/UserEmailVerificationCubit/UserEmailVerificationState";
import { UserEmailVerificationStateName } from "src/state/UserEmailVerificationCubit/UserEmailVerificationState";
import { apiMiddleware, loadingState, userState } from "src/state/state";

export default class UserEmailVerificationCubit extends Cubit<UserEmailVerificationStateInitial> {
  private readonly loadingState = loadingState;

  constructor() {
    super({
      stateName: UserEmailVerificationStateName.unverified
    });
  }

  public readonly initiateVerification = async (
    email?: string
  ): Promise<void> => {
    apiMiddleware.clearAll();
    this.loadingState.start(LoadingKey.initiateVerification);
    let error;

    addSentryBreadcrumb("auth", `Initiating email verification`);

    if (email) {
      const data = { email };

      try {
        await UserEmailVerificationControllerService.initiateEmailVerification(
          data
        );
      } catch (e: unknown) {
        error = e as Error;
        reportErrorSentry(e);
      }
    } else {
      reportErrorSentry(new Error("No email provided"));
    }

    this.loadingState.finish(LoadingKey.initiateVerification);

    if (error) {
      throw error;
    }
  };

  public resendFailed = (): void => {
    this.emit({
      stateName: UserEmailVerificationStateName.resendFailed
    });
  };

  public readonly verifyEmailByLink = async (data: {
    linkVerificationToken: string;
  }): Promise<void> => {
    this.loadingState.start(LoadingKey.verifyEmail);
    let error;

    try {
      const requestBody = {
        linkVerificationToken: data.linkVerificationToken
      };
      await UserEmailVerificationControllerService.completeEmailVerification(
        requestBody
      );

      void userState.setUserInfo();

      this.emit({
        stateName: UserEmailVerificationStateName.verified
      });

      addSentryBreadcrumb("auth", `Successfully verified email`);
    } catch (e: unknown) {
      error = e as Error;
      this.emit({
        stateName: UserEmailVerificationStateName.verificationFailed
      });

      addSentryBreadcrumb("auth", `Failed to verify email`);
    }
    this.loadingState.finish(LoadingKey.verifyEmail);

    if (error) {
      throw error;
    }
  };

  public readonly verifyEmailByCode = async (data: {
    code: string;
    verificationToken: string;
  }): Promise<NetworkGenericResponse> => {
    let success = true;
    this.loadingState.start(LoadingKey.verifyEmail);

    if (userState.state.userData?.email)
      try {
        const requestBody = {
          verificationCode: data.code,
          verificationToken: data.verificationToken,
          email: userState.state.userData.email
        };

        await UserEmailVerificationControllerService.completeEmailVerification(
          requestBody
        );

        this.emit({
          stateName: UserEmailVerificationStateName.verified
        });

        addSentryBreadcrumb("auth", `Successfully verified email`);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e: unknown) {
        success = false;
        this.emit({
          stateName: UserEmailVerificationStateName.verificationFailed
        });
        addSentryBreadcrumb("auth", `Failed to verify email`);
      }

    this.loadingState.finish(LoadingKey.verifyEmail);

    return {
      success
    };
  };

  public readonly verifyEmailStateless = async (
    data: VerifyEmailRequest
  ): Promise<{ error: string }> => {
    loadingState.start(LoadingKey.verifyEmail);
    let error = "";

    try {
      await UserEmailVerificationControllerService.completeEmailVerification(
        data
      );

      addSentryBreadcrumb("auth", `Successfully verified email stateless`);
    } catch (e: unknown) {
      reportErrorSentry(e);
      error = extractErrorCode(e);
    }
    loadingState.finish(LoadingKey.verifyEmail);
    return { error };
  };
}
