import { Browser } from "@capacitor/browser";
import { useCallback, useEffect, useState } from "react";
import envVariables from "src/lib/envVariables";

export async function OpenBrowser(
  url: string,
  options?: {
    presentationStyle?: "fullscreen" | "popover";
    useBaseUrl?: boolean;
    waitForActionComplete?: string;
  }
): Promise<void> {
  const { useBaseUrl = true, presentationStyle = "fullscreen" } = options ?? {};
  const fullUrl = useBaseUrl ? envVariables.WEB_URL + url : url;

  return Browser.open({
    url: fullUrl,
    presentationStyle,
    toolbarColor: "#fffcf3"
  });
}

// React hook
type BrowserState = "finished" | "initial" | "loaded";

type BrowserHook = [
  browser: {
    open: (url: string) => Promise<void>;
  },
  status?: BrowserState
];

export function useBrowser(): BrowserHook {
  const [status, setStatus] = useState<BrowserState>("initial");
  const open = useCallback(async (url: string): Promise<void> => {
    return OpenBrowser(url);
  }, []);

  useEffect(() => {
    void Browser.addListener("browserFinished", () => {
      setStatus("finished");
    });

    void Browser.addListener("browserPageLoaded", () => {
      setStatus("loaded");
    });

    return () => {
      void Browser.removeAllListeners();
    };
  }, []);

  return [{ open }, status];
}
