import React from "react";

export const ErrorIcon: React.FC = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Zm0 .96a10.96 10.96 0 1 0 0-21.92 10.96 10.96 0 0 0 0 21.92Z"
        fill="#BF1902"
      />
      <path
        d="M11.59 14.07h.92l.42-4.2V6.69h-1.76v3.2l.42 4.19Zm-.52 3.21h1.96v-1.95h-1.96v1.95Z"
        fill="#BF1902"
      />
    </svg>
  );
};

export const CheckmarkIcon: React.FC = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.585 5.907 12 2 8.42l.64-.585 3.267 2.993L13.36 4l.64.585Z"
        fill="#212121"
      ></path>
    </svg>
  );
};
