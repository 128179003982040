import styled from "@emotion/styled";
import { Button, Label, Text } from "react-aria-components";
import React from "react";

export const DropdownButtonEl = styled(Button)`
  --error-red: #bf1902;
  label: DropdownButtonEl;
  font-weight: 400;
  flex-direction: column;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;

  &[data-disabled="true"] {
    opacity: 0.7;
    filter: grayscale(1);
    cursor: not-allowed;
  }

  border-radius: 0.5rem;
  background: var(--color-white, #fff);
  height: 4rem;
  display: flex;
  position: relative;
  background: var(--greys-charcoal-60, rgba(33, 33, 33, 0.6));
  transition: all 0.2s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    --inset-size: 1px;
    inset: var(--inset-size);
    transition: all 0.2s ease-in-out;
    display: block;
    background: white;
    z-index: 2;
    border-radius: calc(0.5rem - var(--inset-size));
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    transition: all 0.2s ease-in-out;
    display: block;
    opacity: 0;
    background: linear-gradient(
      46deg,
      #a6c6ff 13.49%,
      #fad0e8 50.42%,
      #ffd199 85.11%
    );
    pointer-events: none;
    z-index: 1;
    border-radius: inherit;
  }

  &:focus-within,
  &[aria-expanded="true"] {
    background: transparent;
    box-shadow:
      0px 0px 0px 0px rgba(69, 49, 22, 0.08),
      -5px 5px 16px 0px rgba(69, 49, 22, 0.08),
      -20px 20px 29px 0px rgba(69, 49, 22, 0.07),
      -46px 45px 39px 0px rgba(69, 49, 22, 0.03),
      -82px 81px 46px 0px rgba(69, 49, 22, 0.01);

    &::before {
      --inset-size: 2px;
    }

    &::after {
      opacity: 1;
    }
  }

  > * {
    position: relative;
    z-index: 3;
  }

  &[data-invalid="true"] {
    background: var(--error-red, #bf1902);
  }
`;

const DropdownIconWrapper = styled.div`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  margin-right: 1rem;
  width: 1.5rem;
  pointer-events: none;
  transition: all 0.2s ease-in-out;

  svg {
    width: 100%;
    height: 100%;
    fill: var(--greys-charcoal-60, rgba(33, 33, 33, 0.6));
  }
`;

export const DropdownIcon = (): React.ReactElement => (
  <DropdownIconWrapper>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4931 13.7184L7.21123 9.02399L6.54883 9.76991L12.4931 15.0547L18.4375 9.76991L17.7741 9.02399L12.4931 13.7184Z"
        fill="#212121"
      />
    </svg>
  </DropdownIconWrapper>
);

export const DropdownValue = styled.div`
  display: flex;
  border: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  height: 100%;
  outline: none;
  padding: 1.3rem 1rem 0;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.02rem;

  transition: all 0.2s ease-in-out;

  &:not(:focus)::placeholder {
    color: transparent;
  }

  &[data-label] {
    &:not(input[value=""]),
    &:not(input[placeholder=""]) {
      padding: 1.72rem 1rem 0;
    }
  }
`;

export const ListBoxWrapper = styled.div`
  width: 100%;
`;

export const DropdownLabelEl = styled(Label)`
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  transform-origin: top left;
  left: 1rem;

  &[data-expanded="true"] {
    top: 0.625rem;
    transform: translateY(0%) scale(0.75);
  }
`;

export const DropdownTextEl = styled(Text)`
  display: block;
  padding: 0.5rem 0.125rem 0rem 0.125rem;
  opacity: 0.8;
`;

export const DropdownErrorEl = styled.div`
  --error-red: #bf1902;
  padding: 0.5em 1em 1em;
  display: block;
  color: var(--error-red, #bf1902);

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: show 0.2s linear forwards;

  &:empty {
    padding: 0;
  }
`;

export const DropdownCard = styled.div`
  --color-white: #ffffff;
  --light-shadow: 0px 0px 0px 0px rgba(69, 49, 22, 0.08),
    -5px 5px 16px 0px rgba(69, 49, 22, 0.08),
    -20px 20px 29px 0px rgba(69, 49, 22, 0.07),
    -46px 45px 39px 0px rgba(69, 49, 22, 0.03),
    -82px 81px 46px 0px rgba(69, 49, 22, 0.01);
  --border-radius: 0.3rem;

  background: var(--color-white);
  box-shadow: var(--light-shadow);
  border-radius: var(--border-radius);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  max-height: 400px;
`;

export const StyledOption = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  outline: none;
  cursor: pointer;

  &:first-of-type {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  &[data-selected="true"] {
    background: var(--secondary-midmorning-blue, #abd4ff);
  }

  &[tabindex="0"]:not([data-selected="true"]),
  &:focus[data-selected="false"] {
    background: var(--secondary-dark-cream, #f2efe7);
  }
`;

export const StyledSection = styled.div`
  --color-charcoal-60: rgba(33, 33, 33, 0.6);
  --color-light-gray: #e6e3db;
  display: flex;
  margin: 0.375rem 0.75rem;
  padding: 0.375rem;
  align-items: flex-start;
  align-self: stretch;
  outline: none;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-charcoal-60);

  border-bottom: 1px solid var(--color-light-gray);
`;
