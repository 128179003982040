import type { FC, ReactNode } from "react";
import React, { Suspense } from "react";
import LocalErrorBoundary from "src/ui/components/SentryBoundary/LocalErrorBoundary";
import ErrorPage from "src/ui/components/SentryBoundary/ErrorPage";

const CustomSuspense: FC<{ children: ReactNode }> = (props) => {
  return (
    <LocalErrorBoundary fallback={<ErrorPage />}>
      <Suspense fallback={<div></div>}>{props.children}</Suspense>
    </LocalErrorBoundary>
  );
};

export default CustomSuspense;
