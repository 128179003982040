import { App, AppState } from "@capacitor/app";
import { globalEvents } from "src/constants/globalEvents";
import parseJwt from "src/lib/parseJwt";
import reportErrorSentry from "src/lib/reportErrorSentry";

type ParseResultUnknown =
  | string
  | number
  | boolean
  | Record<string | number | symbol, unknown>
  | Array<unknown>;

class MemStorage {
  storage: Map<string, string> = new Map();

  get length(): number {
    return this.storage.size;
  }

  getItem(key: string): string | null {
    return this.storage.get(key) ?? null;
  }

  setItem(key: string, value: string): void {
    this.storage.set(key, value);
  }

  clear(): void {
    this.storage.clear();
  }

  removeItem(key: string): void {
    this.storage.delete(key);
  }

  key(index: number): string | null {
    const keys = Array.from(this.storage.keys());
    return keys[index] ?? null;
  }
}

export const memStorage: Storage = new MemStorage();

export default class StorageBloc {
  useStorage: Storage = localStorage;
  sessionStorage = sessionStorage;
  activeUserId: string | null = null;
  pageIsActive = true;
  verboseLogging = false;

  constructor() {
    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.clearUser();
    });
    void App.addListener("appStateChange", this.handleAppStateChange);
  }

  static keys = {
    refreshToken: "refreshToken",
    lastActive: "lastActive",
    useStorageType: "useStorageType"
  };

  createPatternForKey = (key: string): RegExp => {
    return new RegExp(`9am-([a-z0-9]+)-${key}`);
  };

  switchStorageTo = (storage: Storage): void => {
    this.sessionStorage.setItem(
      StorageBloc.keys.useStorageType,
      storage === localStorage ? "local" : "session"
    );
    this.useStorage = storage;
  };

  selectStorageType = (): void => {
    const storageType = this.sessionStorage.getItem(
      StorageBloc.keys.useStorageType
    );
    const selectedStorage =
      storageType === "session" ? sessionStorage : localStorage;
    this.useStorage = selectedStorage;
  };

  getUserIdFromLastActiveTime(): string | null {
    // check for the latest active user by lastActive time
    const allStorageKeys = Object.keys(this.useStorage);
    const userIdRegexLastActive = this.createPatternForKey("lastActive");
    const lastActivekeys = allStorageKeys.filter((k) =>
      k.match(userIdRegexLastActive)
    );
    let latest = { userId: "", time: 0 };
    for (const lastActiveKey of lastActivekeys) {
      const activeTime = parseInt(
        this.useStorage.getItem(lastActiveKey) ?? "0"
      );
      const userId = lastActiveKey.match(userIdRegexLastActive)?.[1];
      if (!userId) {
        continue;
      }
      const hasRefreshToken = this.useStorage.getItem(
        `9am-${userId}-refreshToken`
      );
      if (!hasRefreshToken) {
        continue;
      }
      const parsedRefreshToken = parseJwt(hasRefreshToken);
      if (activeTime > latest.time && parsedRefreshToken.sub) {
        latest = { userId: parsedRefreshToken.sub, time: activeTime };
      }
    }
    if (latest.userId) {
      return latest.userId;
    }

    return null;
  }

  getUserIdFromStoredRefreshToken(): string | null {
    // find any user with a refreshToken
    const userIdRegexRefreshToken = this.createPatternForKey("refreshToken");
    const allStorageKeys = Object.keys(this.useStorage);
    const refreshKeys = allStorageKeys.filter((k) =>
      k.match(userIdRegexRefreshToken)
    );
    if (refreshKeys.length > 0) {
      const userId = refreshKeys[0].match(userIdRegexRefreshToken)?.[1];
      if (userId) {
        return userId;
      }
    }

    return null;
  }

  setNewUserId = (newUserId: string): void => {
    const currentUserId = this.activeUserId;
    this.log("selectUserId", newUserId);
    this.activeUserId = newUserId;

    if (this.activeUserId !== currentUserId) {
      this.logActive();
    }
  };

  selectUserIdFromStorage = (): void => {
    if (!this.activeUserId) {
      this.activeUserId = this.getUserIdFromLastActiveTime();
    }

    if (!this.activeUserId) {
      this.activeUserId = this.getUserIdFromStoredRefreshToken();
    }
  };

  handleAppStateChange = (state: AppState) => {
    const { isActive } = state;
    this.pageIsActive = isActive;
    if (isActive) {
      this.logActive();
    }
  };

  logActive = (): void => {
    if (this.activeUserId) {
      this.setItem(StorageBloc.keys.lastActive, Date.now().toString());
    }
  };

  log = (message: string, etc?: unknown): void => {
    if (this.verboseLogging) {
      // eslint-disable-next-line no-console
      console.warn(`[STORAGE]: ${message}`, etc ?? "");
    }
  };

  makeKey = (key: string): string => {
    if (!this.activeUserId) {
      throw new Error(`No active user - cannot make key, ${key}`);
    }
    return `9am-${this.activeUserId.replace(/-/g, "")}-${key}`;
  };

  setItem = (key: string, value: string, storage = this.useStorage): void => {
    if (!this.activeUserId) {
      reportErrorSentry(new Error(`No active user - cannot set item, ${key}`));
      return;
    }
    storage.setItem(this.makeKey(key), value);
  };

  getItem = (key: string, storage = this.useStorage): string | null => {
    if (!this.activeUserId) {
      reportErrorSentry(new Error(`No active user - cannot get item, ${key}`));
      return null;
    }
    return storage.getItem(this.makeKey(key));
  };

  getItemParsed = <T = ParseResultUnknown>(
    key: string,
    storage = this.useStorage
  ): T | null => {
    if (!this.activeUserId) {
      reportErrorSentry(new Error(`No active user - cannot get item, ${key}`));
      return null;
    }
    const value = storage.getItem(this.makeKey(key));
    if (!value) {
      return null;
    }
    try {
      return JSON.parse(value) as T;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      reportErrorSentry(new Error(`Failed to parse item, ${key}`));
      return null;
    }
  };

  removeItem = (key: string, storage = this.useStorage): void => {
    if (!this.activeUserId) {
      reportErrorSentry(
        new Error(`No active user - cannot remove item, ${key}`)
      );
      return;
    }
    storage.removeItem(this.makeKey(key));
  };

  clear = (storage = this.useStorage): void => {
    storage.clear();
  };

  clearUser = (): void => {
    const clearUserId = this.activeUserId;
    if (!clearUserId) {
      reportErrorSentry(new Error("No active user - cannot clear user"));
      return;
    }

    const keys = Object.keys(this.useStorage);
    const keysToRemove = keys.filter((k) =>
      k.includes(clearUserId.replace(/-/g, ""))
    );
    keysToRemove.forEach((k) => this.useStorage.removeItem(k));
    sessionStorage.removeItem(StorageBloc.keys.useStorageType);
  };

  migrateOldStorage01(): void {
    try {
      const oldRefreshTokenKey = "9am.refreshToken";
      // find refreshToken in old key
      const token =
        sessionStorage.getItem(oldRefreshTokenKey) ??
        localStorage.getItem(oldRefreshTokenKey);
      if (!token) return;
      const parsed = parseJwt(token);
      if (!parsed.sub) return;
      // clear all storage so that we can start fresh
      localStorage.clear();
      sessionStorage.clear();
      // set the user id and refresh token
      this.setNewUserId(parsed.sub);
      this.setItem(StorageBloc.keys.refreshToken, token);
    } catch (e) {
      localStorage.clear();
      sessionStorage.clear();
      reportErrorSentry(
        new Error("Failed to migrate old storage", { cause: e })
      );
    }
  }
}

export const StorageController = new StorageBloc();
StorageController.migrateOldStorage01();
StorageController.selectStorageType();
StorageController.selectUserIdFromStorage();
