import * as Sentry from "@sentry/react";
import envVariables from "./envVariables";

export const sentryConfig: Sentry.BrowserOptions = {
  dsn: envVariables.SENTRY_DSN,
  environment: envVariables.ENV_NAME,
  enabled: envVariables.PROD,
  release: `9am-${envVariables.APP_ENV}@${envVariables.BUILD_VERSION}`,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions, limits are set on DNS
  ignoreErrors: [
    "The client is not authorized to perform the action.",
    "User has no rights to perform this operation.",
    "The client is not authorized to perform the action. The `code`, `message`, and `data` fields may provide more information about the reason of the error.",
    "Failed to load Stripe.js",
    "Failed to fetch",
    "No new version available",
    "Biometric verification failed",
    "disable_auto_update_under_native",
    /Failed to download from: https:\/\/app-archive.dev.join9am.com(.*)/,
    /^Request to https:\/\/api.[a-zA-Z-.]*.9am.health(.*) failed with status code 401$/,
    /^Request to https:\/\/api.[a-zA-Z-.]*.join9am.com(.*) failed with status code 401$/
  ]
};
