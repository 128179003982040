import { ApiError } from "@9amhealth/openapi";
import { ErrorCode } from "src/constants/errorCodes";
import reportErrorSentry from "src/lib/reportErrorSentry";
export const extractErrorCode = (error: unknown): ErrorCode => {
  try {
    return `${(error as ApiError).body.code}` as ErrorCode;
  } catch (e: unknown) {
    reportErrorSentry(e);
  }

  return ErrorCode.unknown;
};
