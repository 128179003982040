import { SubscriptionInterval } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";

export const getPrice = (
  amount: number,
  multiply: boolean,
  duration?: SubscriptionInterval
): {
  totalAmount: number;
  intervalInt: number | undefined;
} => {
  let intervalInt;

  if (duration === SubscriptionInterval.monthly) {
    intervalInt = 1;
  }

  if (duration === SubscriptionInterval.quarterly) {
    intervalInt = 3;
  }

  const totalAmount = multiply && intervalInt ? intervalInt * amount : amount;

  return { totalAmount, intervalInt };
};
