import { FormFieldBaseState } from "atom/autoform/AutoFormBloc";
import styled from "@emotion/styled";
import { FactoryArg } from "imask";
import React from "react";
import {
  FieldError,
  Input as InputAria,
  Label,
  Text,
  TextField
} from "react-aria-components";
import { z } from "zod";

export const DataFieldEl = styled(TextField)`
  font-weight: 400;

  &:first-of-type {
    --data-field-border-radius: 0.5rem 0.5rem 0 0;
    --data-field-line-height: 0;
    margin-top: var(--data-field-first-margin, 0.5rem);
  }

  &[data-disabled="true"] {
    opacity: 0.7;
    filter: grayscale(1);
    cursor: not-allowed;
  }

  &:last-of-type {
    --data-field-border-radius: 0 0 0.5rem 0.5rem;
    margin-bottom: var(--data-field-last-margin, 0.5rem);
  }

  &:only-of-type {
    --data-field-border-radius: 0.5rem;
  }
`;

export const DataFieldRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: var(--data-field-border-radius, 0);
  background: var(--data-field-background, #fff);
  transition: background 0.2s ease;
  padding: 0 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;
    height: var(--data-field-line-height, 0.0625rem);
    background: var(--data-field-border-color, #e6e3db);
  }

  .data-field[data-invalid="true"] & {
    background: var(--data-field-background-invalid, #fdf4f2);
    box-shadow: inset 0 0 0 1px var(--primary-warning-red, #bf1902);

    &::before {
      opacity: 0;
    }
  }
`;

export const DataFieldInputEl = styled(InputAria)`
  flex: 1;
  text-align: right;
  outline: none;
  border: none;
  background: transparent;
  padding: 1rem 0;
  margin: 0;
  min-width: 0;
  width: auto;

  &[data-has-affix] {
    padding-right: 0.3rem;
  }
`;

export const DataFieldLabelEl = styled(Label)`
  opacity: 0.6;
`;

export const DataFieldAffixEl = styled(Text)`
  opacity: 0.8;
  white-space: nowrap;
`;

export const DataFieldErrorEl = styled(FieldError)`
  padding: 0.5em 1em 1em;
  display: block;
  color: var(--primary-warning-red, #bf1902);

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: show 0.2s linear forwards;
`;

export interface DataFieldProps
  extends Omit<React.ComponentProps<typeof TextField>, "ref"> {
  label?: string;
  affix?: React.ReactNode;
  focusOnSelect?: boolean;
  onChangeCapture?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorParser?: (props: DataFieldProps) => string;
  mask?: FactoryArg;
  error?: string | z.ZodIssue;
  // ref will be ignored in props, but it's needed for React.forwardRef
  ref?: React.Ref<FormFieldBaseState>;
}
