import type { AnsweredQuestionnaire, AnswerItemDto } from "@9amhealth/openapi";
import { GetSuggestedTreatmentPlanResponse } from "@9amhealth/openapi";
import {
  MEDICAL_ASSESSMENT_ID,
  MEDICAL_QUESTIONNAIRE_ETHNICITY_QUESTION_ID,
  MEDICAL_QUESTIONNAIRE_ID
} from "src/constants/misc";

export default function checkMedicalDataCollected(
  subscriptionEligibility:
    | GetSuggestedTreatmentPlanResponse.eligibility
    | undefined,
  answeredQuestionnaires: AnsweredQuestionnaire[] | undefined
): boolean {
  if (
    subscriptionEligibility ===
    GetSuggestedTreatmentPlanResponse.eligibility.INELIGIBLE
  ) {
    return true;
  }

  const medicalAssessment = answeredQuestionnaires?.find(
    (q) => q.questionnaireRef.id === MEDICAL_ASSESSMENT_ID
  );

  const medicalQuestionnaire = answeredQuestionnaires?.find(
    (q) => q.questionnaireRef.id === MEDICAL_QUESTIONNAIRE_ID
  );

  // If the user has not answered the ethnicity question in the medical assessment we can assume that we have all the medical data
  const medicalQuestionnaireAnswers = (medicalQuestionnaire?.answers.json ??
    []) as AnswerItemDto[];
  const ethnicityData = medicalQuestionnaireAnswers.find(
    (a) => a.questionId === MEDICAL_QUESTIONNAIRE_ETHNICITY_QUESTION_ID
  );

  if (ethnicityData) return true;
  if (medicalAssessment) return true;

  return false;
}
