import React from "react";
import CallbackRequest from "src/ui/components/CallbackRequest/CallbackRequest";
import teamImage from "src/ui/components/CallbackRequest/team.png";
import Translate from "src/ui/components/Translate/Translate";
import type { SignupSuccessPageProps } from "../../SignupSuccessPage";
import styled from "@emotion/styled";

const TeamImage = styled.img`
  width: min(80%, 200px);
`;

const genericFinalSuccessPageProps: SignupSuccessPageProps = {
  title: (
    <>
      <TeamImage src={teamImage} alt="Team" />
      <br />
      <Translate msg="funnel.success.title" />
    </>
  ),
  subtitle: <Translate msg="funnel.success.subtitle" />,
  titleSpacer: false,
  steps: [
    { status: "done", title: <Translate msg="task.medicalAssessment" /> },
    {
      status: "in-progress",
      title: <Translate msg="task.onboardingCall" />,
      subtitle: <CallbackRequest view="success-page" />
    },
    { status: "to-do", title: <Translate msg="task.initialLabTest" /> },
    { status: "to-do", title: <Translate msg="task.medicalReview" /> }
  ]
};

export default genericFinalSuccessPageProps;
