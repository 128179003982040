import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC } from "react";
import React from "react";
import { Link } from "react-router-dom";
import {
  ANDROID_APP_DOWNLOAD_LINK,
  IOS_APP_DOWNLOAD_LINK
} from "src/constants/misc";
import Path from "src/constants/path";
import envVariables from "src/lib/envVariables";
import { isHybridApp } from "src/lib/platform";
import translate from "src/lib/translate";
import appStore from "src/ui/assets/logos/app_store.png";
import googlePlay from "src/ui/assets/logos/google_play.png";
import Qrcode from "src/ui/components/Qrcode/Qrcode";
import Translate from "src/ui/components/Translate/Translate";

interface Props {
  darkCreamBg?: boolean;
  forceQRCode?: boolean;
  content?: string;
}

const PaddedLink = styled(Link)`
  display: flex;
  padding: calc(var(--space-sm) * 0.25) 0;
`;

const BlueLink = styled.a`
  color: var(--color-sunrise-blue, #80aeff) !important;
  padding: calc(var(--space-sm) * 0.75) 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--space-sm);
  padding: var(--space-sm);
  border-radius: 0.5rem;
  background: var(--color-gray-fog, #f4f4f4);

  &.dark-cream {
    background: var(--secondary-dark-cream, #f2efe7);
  }

  @media screen and (min-width: 500px) {
    flex-direction: row;
  }
`;

const Badge = styled.img`
  width: 120px;
  height: auto;
  max-width: unset;
`;

const GetHybridAppStepContent: FC<Props> = ({
  darkCreamBg = false,
  forceQRCode = false,
  content = translate("downloadApp.scanQR")
}) => {
  const getPlatform = (): "android" | "ios" | "other" => {
    const ua = navigator.userAgent.toLowerCase();
    if (/android/.test(ua)) {
      return "android";
    }
    if (/iphone|ipad|ipod/.test(ua)) {
      return "ios";
    }
    return "other";
  };

  if (isHybridApp()) {
    return <nine-button href={Path.app}>Continue</nine-button>;
  }

  if (!forceQRCode && getPlatform() !== "other") {
    const isAndroid = getPlatform() === "android";

    return (
      <>
        <div>
          <PaddedLink
            to={isAndroid ? ANDROID_APP_DOWNLOAD_LINK : IOS_APP_DOWNLOAD_LINK}
          >
            {isAndroid && (
              <Badge
                src={googlePlay}
                alt="Google Play badge"
                style={{ width: "135px" }}
              />
            )}
            {!isAndroid && <Badge src={appStore} alt="App Store badge" />}
          </PaddedLink>
        </div>
        <div>
          <BlueLink href={"/app"} className="as-little strong">
            <Translate msg="skip" />
          </BlueLink>
        </div>
      </>
    );
  }

  return (
    <Wrapper className={clsx({ "dark-cream": darkCreamBg })}>
      <Qrcode size={96} text={`${envVariables.WEB_URL}download-app`} />

      <div>
        <p className="strong m0">
          <Translate msg="downloadApp" />
        </p>
        <nine-spacer s="xxxs"></nine-spacer>
        <p className="m0">{content}</p>
        <nine-spacer s="xxxs"></nine-spacer>
        <BlueLink href="/app" className="as-little strong">
          <Translate msg="continueHere" />
        </BlueLink>
      </div>
    </Wrapper>
  );
};

export default GetHybridAppStepContent;
