import React from "react";
import HtmlParser from "src/lib/HtmlParser";
import translate from "src/lib/translate";
import type { TranslationKey } from "src/types/translationKey";

export default function Translate(props: {
  msg: TranslationKey | "";
  fallback?: TranslationKey;
  variables?:
    | Record<string, number | string | undefined>
    | (() => Record<string, number | string | undefined>);
  uppercase?: boolean;
  markdown?: boolean;
  noWrap?: boolean;
}): React.JSX.Element | string {
  const { variables = {}, fallback, uppercase, markdown, noWrap, msg } = props;

  if (!msg) return "";

  const vars = typeof variables === "function" ? variables() : variables;

  let text: React.JSX.Element | React.JSX.Element[] | string = translate(
    msg,
    vars,
    fallback
  );

  text = text.replace(/®/g, "<sup>®</sup>");

  text = uppercase ? text.toLocaleUpperCase() : text;

  text = new HtmlParser(text, { convertMarkdownToHtml: markdown }).toJsx();

  return noWrap ? (text as string) : <span>{text}</span>;
}
