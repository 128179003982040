import { Cubit } from "blac";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { VideoPlayerLibInterface } from "src/state/VideoPlayerCubit/VideoPlayerCubit";
import { tracker } from "src/state/state";
import videojs from "video.js";

export type FullVideoPlayerBlocState = {
  playing: boolean;
  ready: boolean;
  player?: VideoPlayerLibInterface;
};

export type VideoPlayerOptions = {
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  poster?: string;
  preload?: "auto" | "metadata" | "none";
  ratio?: number;
  sources?: {
    src: string;
    type: string;
  }[];
};

export default class FullVideoPlayerBloc extends Cubit<FullVideoPlayerBlocState> {
  containerElement: HTMLDivElement | null = null;
  videoOptions?: VideoPlayerOptions;
  defaultOptions: VideoPlayerOptions = {
    autoplay: false,
    controls: true,
    loop: false,
    muted: false,
    preload: "auto",
    sources: []
  };

  constructor() {
    super({
      playing: false,
      ready: false
    });
  }

  initVideo = (
    containerElement: HTMLDivElement | null,
    videoOptions: VideoPlayerOptions
  ) => {
    const optionsMerged = {
      ...this.defaultOptions,
      ...videoOptions,
      playsinline: true,
      // use native controls for touch devices
      // in addiction, this allows the video to be played correctly in fullscreen mode
      //  when the app is wrapped in a webview or inappbrowser
      nativeControlsForTouch: true
    };
    if (this.containerElement !== null || containerElement === null) {
      return;
    }

    this.containerElement = containerElement;
    this.videoOptions = optionsMerged;

    const videoElement = document.createElement("video-js");

    videoElement.classList.add("vjs-big-play-centered");
    this.containerElement.appendChild(videoElement);

    const player = videojs(videoElement, optionsMerged, () => {
      this.emit({
        ...this.state,
        playing: videoOptions.autoplay === true,
        ready: true,
        player: player as VideoPlayerLibInterface
      });

      this.addPlayerListeners();
    });
  };

  addPlayerListeners = () => {
    if (!this.state.player) {
      return;
    }

    const trackingEvents = [
      "play",
      "pause",
      "mute",
      "ended",
      "seeking",
      "fullscreenchange"
    ];

    const { player } = this.state;

    trackingEvents.forEach((eventName) => {
      player.on(eventName, () => {
        const trackData = {
          time: Math.round(player.currentTime()),
          duration: Math.round(player.duration()),
          fullscreen: player.isFullscreen()
        };
        this.trackEvent(
          eventName,
          trackData,
          eventName === "seeking" ? 300 : 0
        );
      });
    });
  };

  trackDebounce: Record<string, number> = {};
  trackEvent = (
    event: string,
    data: Record<string, boolean | number | string | undefined>,
    debounce: number
  ) => {
    if (
      this.trackDebounce[event] &&
      Date.now() - this.trackDebounce[event] < debounce
    ) {
      return;
    }
    this.trackDebounce[event] = Date.now();
    tracker.track(`Video Player Interaction: ${event}` as TrackEvent, data);
  };

  reInitVideo = (
    containerElement: HTMLDivElement | null,
    videoOptions: VideoPlayerOptions
  ) => {
    this.disposePlayer();
    this.initVideo(containerElement, videoOptions);
  };

  disposePlayer = () => {
    const { player } = this.state;
    if (player && !player.isDisposed()) {
      player.dispose();
    }
    this.containerElement = null;
    this.videoOptions = undefined;
    this.emit({
      ...this.state,
      playing: false,
      ready: false,
      player: undefined
    });
  };
}
