import { FC, useMemo } from "react";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { useBloc } from "src/state/state";

type ShowIfSubscriptionProps = {
  children: React.ReactNode;
  selfPayd?: boolean;
};

const ShowIfSubscription: FC<ShowIfSubscriptionProps> = ({
  children,
  ...props
}) => {
  const [, { extractPayerInfo }] = useBloc(SubscriptionCubit);
  const payerInfo = extractPayerInfo();

  const show = useMemo(() => {
    if (typeof props.selfPayd === "boolean") {
      const seldPayid = payerInfo.payerIsCashPay;
      if (seldPayid !== props.selfPayd) {
        return false;
      }
    }

    return true;
  }, [extractPayerInfo, props.selfPayd]);

  if (!show) {
    return null;
  }

  return children;
};

export default ShowIfSubscription;
