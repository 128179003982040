import envVariables from "src/lib/envVariables";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { globalEvents } from "src/constants/globalEvents";

enum SocketCloseCode {
  NORMAL = 1000,
  GOING_AWAY = 1001,
  PROTOCOL_ERROR = 1002,
  UNSUPPORTED_DATA = 1003,
  NO_STATUS_RCVD = 1005,
  ABNORMAL_CLOSURE = 1006, // known causes: wrong url
  INVALID_FRAME_PAYLOAD_DATA = 1007,
  POLICY_VIOLATION = 1008,
  MESSAGE_TOO_BIG = 1009,
  MISSING_EXTENSION = 1010,
  INTERNAL_ERROR = 1011, // known causes: malformed message
  SERVICE_RESTART = 1012,
  TRY_AGAIN_LATER = 1013,
  BAD_GATEWAY = 1014,
  TLS_HANDSHAKE = 1015
}

const reconnectableCloseCodes = [
  SocketCloseCode.NORMAL,
  SocketCloseCode.GOING_AWAY,
  SocketCloseCode.PROTOCOL_ERROR,
  SocketCloseCode.UNSUPPORTED_DATA,
  SocketCloseCode.NO_STATUS_RCVD,
  SocketCloseCode.ABNORMAL_CLOSURE,
  SocketCloseCode.INVALID_FRAME_PAYLOAD_DATA,
  SocketCloseCode.POLICY_VIOLATION,
  SocketCloseCode.MESSAGE_TOO_BIG,
  SocketCloseCode.MISSING_EXTENSION,
  SocketCloseCode.INTERNAL_ERROR,
  SocketCloseCode.SERVICE_RESTART,
  SocketCloseCode.TRY_AGAIN_LATER,
  SocketCloseCode.BAD_GATEWAY
];

export class WebSocketConnection {
  verboseLogging = false;
  private _socket: WebSocket | null = null;
  static _url = `${envVariables.WS_BASE_URL}/v1/socket`;

  static instance: WebSocketConnection | null = null;

  constructor() {
    if (WebSocketConnection.instance) {
      return WebSocketConnection.instance;
    }
    WebSocketConnection.instance = this;

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.disconnect();
    });
  }

  get readyState() {
    return this._socket?.readyState ?? WebSocket.CLOSED;
  }

  private async establishConnection() {
    if (this._socket?.readyState === WebSocket.OPEN) {
      return Promise.resolve(true);
    }

    if (this.connectingPromise) {
      return this.connectingPromise;
    }

    this.connectingPromise = new Promise((resolve) => {
      this._socket = new WebSocket(WebSocketConnection._url);
      let resolved = false;
      this.addHandlers();

      // Resolve the promise when the connection is established
      this._socket.addEventListener("open", () => {
        resolve(true);
        resolved = true;
        this.connectingPromise = null;
        this.startPingInterval();
      });

      // Reject the promise when an error occurs
      this._socket.addEventListener("error", () => {
        if (resolved) {
          return;
        }
        this.connectingPromise = null;
      });
    });

    return this.connectingPromise;
  }
  connectingPromise: Promise<boolean> | null = null;

  public async connect() {
    await this.establishConnection();
  }

  public disconnect() {
    if (this._socket) {
      this._socket.close();
    }
  }

  public send(data: string) {
    if (this._socket) {
      this._socket.send(data);
      this.lastMessageSentAt = Date.now();
    }
  }

  private startPingInterval() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    this.pingInterval = setInterval(this.sendPingMessage, 5000);
  }
  pingInterval: NodeJS.Timeout | null = null;

  private readonly sendPingMessage = () => {
    if (!this._socket) {
      return;
    }
    const now = Date.now();
    if (now - this.lastMessageSentAt > 10000) {
      this.log("Sending ping message");
      this.send(JSON.stringify({ type: "ping" }));
    }
  };
  lastMessageSentAt = 0;

  public close() {
    this.disconnect();
  }

  handleEventListeners: { event: string; callback: EventListener }[] = [];
  public addEventListener = (event: string, callback: EventListener) => {
    this.handleEventListeners.push({ event, callback });

    if (this._socket) {
      this._socket.addEventListener(event, callback);
    }
  };

  public removeEventListener = (event: string, callback: EventListener) => {
    this.handleEventListeners = this.handleEventListeners.filter((listener) => {
      const sameEvent = event === listener.event;
      const sameCallback = callback === listener.callback;

      if (sameEvent && sameCallback) {
        return false;
      }
      return true;
    });

    if (this._socket) {
      this._socket.removeEventListener(event, callback);
    }
  };

  private addHandlers() {
    if (!this._socket) {
      throw new Error("Socket is not initialized when adding handlers");
    }

    this._socket.addEventListener("close", this.handleClose);
    this._socket.addEventListener("error", this.handleErrors);

    for (const event of this.handleEventListeners) {
      this._socket.addEventListener(event.event, event.callback);
    }
  }

  closeLogs: CloseEvent[] = [];
  private removeOldLogs = () => {
    const maxAge = 1000 * 60 * 5; // 5 minute
    const now = Date.now();
    this.closeLogs = this.closeLogs.filter((log) => {
      return now - log.timeStamp < maxAge;
    });
  };

  private handleClose = (event: CloseEvent) => {
    this.log("Connection closed", event);

    addSentryBreadcrumb("WebSocket", "Connection closed", "warning");

    this.closeLogs.push(event);
    this.removeOldLogs();
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    this._socket = null;

    const isReconnectable = reconnectableCloseCodes.includes(event.code);

    const reachedMaxCloses = this.closeLogs.length > 10;
    const timeout = reachedMaxCloses ? 10000 : 2000;
    if (reachedMaxCloses) {
      reportErrorSentry(
        new Error(
          `WebSocket connection closed more than 10 times. code: ${event.code} ${event.reason}`
        ),
        {
          closeLogs: this.closeLogs.map((closeEvent) => ({
            code: closeEvent.code,
            reason: closeEvent.reason,
            wasClean: closeEvent.wasClean
          }))
        }
      );
      this.closeLogs = [];
    }
    setTimeout(() => {
      if (isReconnectable) {
        void this.connect();
      }
    }, timeout);
  };

  private handleErrors = (event: Event) => {
    this.log("Error", event);
  };

  private log(message: string, a: unknown = "-") {
    if (this.verboseLogging) {
      // eslint-disable-next-line no-console
      console.warn(`[WebSocketConnection]: ${message}`, a);
    }
    addSentryBreadcrumb("WebSocket", message, "info");
  }
}
