import styled from "@emotion/styled";
import { z } from "zod";
import { FactoryArg } from "imask";
import {
  TextField,
  Label,
  Input as InputAria,
  Text,
  FieldError
} from "react-aria-components";
import React, { FC, ReactNode } from "react";
import { FormFieldBaseState } from "atom/autoform/AutoFormBloc";

export const InputTextFieldEl = styled(TextField)`
  label: TextField;
  font-weight: 400;
  display: flex;
  flex-direction: column;

  &[data-disabled="true"] {
    opacity: 0.7;
    filter: grayscale(1);
    cursor: not-allowed;
  }
`;

export const InputWrap = styled.div`
  label: InputWrap;
  border-radius: 0.5rem;
  background: var(--greys-white, #fff);
  height: 4rem;
  display: flex;
  position: relative;
  background: var(--greys-charcoal-60, rgba(33, 33, 33, 0.6));
  transition: all 0.2s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    --inset-size: 1px;
    inset: var(--inset-size);
    transition: all 0.2s ease-in-out;
    display: block;
    background: white;
    z-index: 2;
    border-radius: calc(0.5rem - var(--inset-size));
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    transition: all 0.2s ease-in-out;
    display: block;
    opacity: 0;
    background: linear-gradient(
      46deg,
      #ffd199 13.49%,
      #fad0e8 50.42%,
      #a6c6ff 85.11%
    );
    pointer-events: none;
    z-index: 1;
    border-radius: inherit;
  }

  &:focus-within {
    background: transparent;
    box-shadow:
      0px 0px 0px 0px rgba(69, 49, 22, 0.08),
      -5px 5px 16px 0px rgba(69, 49, 22, 0.08),
      -20px 20px 29px 0px rgba(69, 49, 22, 0.07),
      -46px 45px 39px 0px rgba(69, 49, 22, 0.03),
      -82px 81px 46px 0px rgba(69, 49, 22, 0.01);

    &::before {
      --inset-size: 2px;
    }

    &::after {
      opacity: 1;
    }
  }

  > * {
    position: relative;
    z-index: 3;
  }

  .input[data-invalid="true"] & {
    background: var(--primary-warning-red, #bf1902);
  }
`;

export const InputEl = styled(InputAria)`
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  padding: 0.625rem 1rem;
  height: 100%;
  box-sizing: border-box;
  outline: none;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.02rem;

  transition: all 0.2s ease-in-out;

  &:not(:focus)::placeholder {
    color: transparent;
  }

  &[aria-labelledby] {
    &:focus,
    &:not(input[value=""]),
    &:not(input[placeholder=""]) {
      padding: 1rem 1rem 0;
    }
  }
`;

export const InputLabelEl = styled(Label)`
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  transform-origin: top left;
  left: 1rem;

  input:focus + &,
  input:not(input[value=""]) + & {
    top: 0.625rem;
    transform: translateY(0%) scale(0.75);
  }
`;

export const InputTextEl = styled(Text)`
  padding: 0.5rem 0.125rem 0rem 0.125rem;
  opacity: 0.8;
`;

export const InputFieldErrorEl = styled(FieldError)`
  padding: 0.5em 1em 1em;
  display: block;
  color: var(--primary-warning-red, #bf1902);

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: show 0.2s linear forwards;
`;

export const IconWrap = styled.div`
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 4px;
`;

export const DescriptionWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.1rem;
`;

export interface InputProps
  extends Omit<React.ComponentProps<typeof TextField>, "ref"> {
  label?: string;
  description?: ReactNode;
  DescriptionIcon?: FC;
  placeholder?: string;
  required?: boolean;
  onChangeCapture?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorParser?: (props: InputProps) => string;
  mask?: FactoryArg;
  error?: string | z.ZodIssue;
  // ref will be ignored in props, but it's needed for React.forwardRef
  ref?: React.Ref<FormFieldBaseState>;
  disableMaskFormattingOnPaste?: boolean;
}
