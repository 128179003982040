export const parseSetVarsObject = (
  regexp: RegExp,
  str: string
): Record<string, number | string> => {
  const obj: Record<string, number | string> = {};
  let match;

  while ((match = regexp.exec(str)) !== null) {
    const set = match[2].split(":");
    const [key] = set;
    let value: number | string = set[1];

    if (`${parseFloat(value)}` === value) {
      value = parseFloat(value);
    }

    obj[key] = value;
  }

  return obj;
};
