import type { ReactElement } from "react";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import FunnelKey from "src/constants/funnelKey";
import Path from "src/constants/path";
import AppUrlListener from "src/hybrid/components/AppUrlListener";
import { createAppPath, createWildcard } from "src/lib/createAppPath";
import createPath from "src/lib/createPath";
import UserCubit from "src/state/UserCubit/UserCubit";
import { useBloc } from "src/state/state";
import AppPageProvider from "src/ui/components/AppPageProvider/AppPageProvider";
import ConfirmEmailAddress from "src/ui/components/ConfirmEmailAddress/ConfirmEmailAddress";
import CustomSuspense from "src/ui/components/CustomSuspense/CustomSuspense";
import DebugAuthQuery from "src/ui/components/Debug/DebugAuthQuery";
import EnrollmentFrame from "src/ui/components/EnrollmentFrame/EnrollmentFrame";
import TrackPageViews from "src/ui/components/TrackPageViews/TrackPageViews";
import WithAuth from "src/ui/components/WithAuth/WithAuth";
import HandoverTokenHandler from "../HandoverTokenHandler/HandoverTokenHandler";
import Loader from "../Loader/Loader";
import BlockingLoadingOverlay from "../BlockingLoadingOverlay/BlockingLoadingOverlay";

const redirects = [
  {
    from: `/signup/glp1`,
    to: `/signup/weight-loss`
  },
  {
    from: `/signup/${FunnelKey.medicare}`,
    to: `/signup/${FunnelKey.universal}`
  },
  {
    from: "*",
    to: "/app/home"
  },
  {
    from: createWildcard(Path.order, true),
    to: "/app/home"
  }
];

const Async = {
  AppDownloadPage: React.lazy(
    async () => import("src/ui/pages/AppDownloadPage/AppDownloadPage")
  ),
  StandaloneQuestionnaire: React.lazy(
    async () =>
      import(
        "src/ui/components/StandaloneQuestionnaire/StandaloneQuestionnaire"
      )
  ),
  CustomQuestionnairePreview: React.lazy(
    async () =>
      import(
        "src/ui/components/CustomQuestionnairePreview/CustomQuestionnairePreview"
      )
  ),
  CustomQuestionnaireStandalone: React.lazy(
    async () =>
      import(
        "src/ui/components/CustomQuestionnaireStandalone/CustomQuestionnaireStandalone"
      )
  ),
  SignupCustomPage: React.lazy(
    async () => import("src/ui/pages/SignupCustomPage/SignupCustomPage")
  ),
  SignupCustomPageDebug: React.lazy(
    async () =>
      import("src/ui/pages/SignupCustomPageDebug/SignupCustomPageDebug")
  ),
  AuthPage: React.lazy(async () => import("src/ui/pages/AuthPage/AuthPage")),
  AppPage: React.lazy(async () => import("src/ui/pages/AppPage/AppPage")),
  GlobalOverlays: React.lazy(
    async () => import("src/ui/components/GlobalOverlays/GlobalOverlays")
  ),
  ClosePageAction: React.lazy(
    async () => import("src/ui/components/ClosePageAction/ClosePageAction")
  ),
  DebugAddPayment: React.lazy(
    async () => import("src/ui/components/Debug/DebugAddPayment")
  )
};

export default function MainRouter(): ReactElement {
  const [{ suspendUi }] = useBloc(UserCubit);

  return (
    <BrowserRouter>
      {suspendUi && <Loader gradient active id="suspendUi" />}
      <AppUrlListener />
      <TrackPageViews />
      <CustomSuspense>
        <Async.GlobalOverlays />
      </CustomSuspense>
      <Routes>
        <Route
          path={`identify.html`}
          element={<WithAuth createAnonymousUser />}
        />
        <Route
          path={`test/just-auth`}
          element={
            <CustomSuspense>
              <DebugAuthQuery redirect />
            </CustomSuspense>
          }
        />
        <Route
          path={`test/add-payment`}
          element={
            <CustomSuspense>
              <Async.DebugAddPayment />
            </CustomSuspense>
          }
        />
        <Route
          path={Path.downloadApp}
          element={
            <CustomSuspense>
              <Async.AppDownloadPage />
            </CustomSuspense>
          }
        />

        <Route
          path={createAppPath(Path.confirmEmail, "app")}
          element={<ConfirmEmailAddress />}
        />

        <Route
          path={`${Path.handover}/:partner`}
          element={
            <WithAuth createAnonymousUser allowPartialAuth>
              <HandoverTokenHandler />
            </WithAuth>
          }
        />

        <Route
          path={"/signup/weight-loss/*?"}
          element={<Navigate to={"/signup/universal"} replace />}
        />

        <Route
          path={Path.individuals}
          element={<Navigate to={"/signup/universal"} replace />}
        />

        <Route
          path={Path.actionDone}
          element={
            <CustomSuspense>
              <Async.ClosePageAction />
            </CustomSuspense>
          }
        />

        <Route
          path={createAppPath(
            createPath(`${Path.signupQuestionnaire}/:id`),
            "root"
          )}
          element={
            <WithAuth loadProfile createAnonymousUser>
              <CustomSuspense>
                <Async.StandaloneQuestionnaire />
              </CustomSuspense>
            </WithAuth>
          }
        />

        <Route
          path={createAppPath(
            createPath(`${Path.questionnairePreview}/:id`),
            "root"
          )}
          element={
            <WithAuth loadProfile createAnonymousUser>
              <CustomSuspense>
                <Async.CustomQuestionnairePreview />
              </CustomSuspense>
            </WithAuth>
          }
        />

        <Route
          path={createAppPath(
            createPath(`${Path.standaloneQuestionnaire}/:id`),
            "root"
          )}
          element={
            <WithAuth loadProfile createAnonymousUser>
              <CustomSuspense>
                <Async.CustomQuestionnaireStandalone />
              </CustomSuspense>
            </WithAuth>
          }
        />

        <Route
          path={createAppPath(
            createPath(`${Path.questionnaireIframe}/:id`),
            "root"
          )}
          element={
            <WithAuth loadProfile createAnonymousUser allowPartialAuth>
              <CustomSuspense>
                <Async.CustomQuestionnairePreview iframe />
              </CustomSuspense>
            </WithAuth>
          }
        />

        <Route
          path={createWildcard(Path.signupCustom, true)}
          element={
            <WithAuth loadProfile createAnonymousUser allowPartialAuth>
              <AppPageProvider>
                <CustomSuspense>
                  <Async.SignupCustomPage />
                </CustomSuspense>
              </AppPageProvider>
            </WithAuth>
          }
        />

        <Route
          path={createWildcard(Path.signupCustomDemo, true)}
          element={
            <WithAuth loadProfile createAnonymousUser allowPartialAuth>
              <AppPageProvider>
                <CustomSuspense>
                  <Async.SignupCustomPage />
                </CustomSuspense>
              </AppPageProvider>
            </WithAuth>
          }
        />

        <Route
          path={createWildcard(Path.signupCustomDebug, true)}
          element={
            <WithAuth loadProfile createAnonymousUser allowPartialAuth>
              <CustomSuspense>
                <Async.SignupCustomPageDebug />
              </CustomSuspense>
            </WithAuth>
          }
        />

        <Route
          path={createWildcard(Path.join, true)}
          element={<Navigate to={"/signup/universal"} replace />}
        />

        {/* Auth routes */}
        <Route
          path={createWildcard(Path.auth, true)}
          element={
            <EnrollmentFrame center={true} direction="column">
              <AppPageProvider>
                <CustomSuspense>
                  <Async.AuthPage />
                </CustomSuspense>
              </AppPageProvider>
            </EnrollmentFrame>
          }
        />

        {/* App routes */}
        <Route
          path={createWildcard(Path.app, true)}
          element={
            <WithAuth redirectIfNoToken>
              <CustomSuspense>
                <Async.AppPage />
              </CustomSuspense>
            </WithAuth>
          }
        />

        {/* Custom Redirects */}
        {redirects.map(({ from, to }) => {
          const currentParams = new URLSearchParams(window.location.search);
          const toWithParams = new URL(to, window.location.origin);
          return (
            <Route
              key={from}
              path={from}
              element={
                <Navigate
                  to={toWithParams.pathname + "?" + currentParams.toString()}
                  replace
                />
              }
            />
          );
        })}
      </Routes>
      <BlockingLoadingOverlay />
    </BrowserRouter>
  );
}
