import IMask, { FactoryArg, InputMask } from "imask";

export default class MaskInput {
  el: HTMLInputElement;
  _mask: FactoryArg;
  maskInstance?: InputMask<FactoryArg>;

  set mask(mask: FactoryArg) {
    this._mask = mask;
    this.init();
  }

  get mask() {
    return this._mask;
  }

  constructor(el: HTMLInputElement, mask: FactoryArg) {
    this.el = el;
    // setter, calls init
    this.mask = mask;
  }

  setEl = (el: HTMLInputElement) => {
    this.el = el;
  };

  init = () => {
    if (!this.el || !this.mask) return;
    if (this.maskInstance) this.destroy();
    this.maskInstance = IMask(this.el, this.mask);
  };

  destroy = () => {
    this.maskInstance?.destroy();
  };
}
