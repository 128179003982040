import styled from "@emotion/styled";
import React from "react";

const Wrap = styled.div`
  font-weight: 400;
  transition: margin-bottom 0.2s ease;
  position: relative;
  background: white;
  box-shadow:
    0px 0px 0px 0px rgba(69, 49, 22, 0.08),
    -5px 5px 16px 0px rgba(69, 49, 22, 0.08),
    -20px 20px 29px 0px rgba(69, 49, 22, 0.07),
    -46px 45px 39px 0px rgba(69, 49, 22, 0.03),
    -82px 81px 46px 0px rgba(69, 49, 22, 0.01);

  border-radius: 0.5rem;

  .data-field:last-of-type {
    .data-field-error {
      position: absolute;
    }
  }

  .data-field[data-invalid="true"] {
    .data-field-row {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    &:last-of-type {
      .data-field-row {
        border-radius: 0.5rem;
      }
    }
  }

  .data-field:not(:last-of-type) {
    .data-field-error {
      padding-top: 0.3rem;
      padding-bottom: 0.6rem;
      position: relative;
      border-radius: 0 0 0.5rem 0.5rem;
      background: var(--data-field-background-invalid, #fdf4f2);
      border: 1px solid var(--primary-warning-red, #bf1902);
      border-top: 0;
      transform: translateY(-1px);
      animation: none;

      &::before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        bottom: auto;
        height: 1px;
        transform: translateY(-0.5rem);
        opacity: 0.2;
        background: var(--primary-warning-red, #bf1902);
      }
    }
  }

  &:has(.data-field[data-invalid="true"]) {
    margin-bottom: 4rem;
  }
`;

export default function DataFieldGroup({
  children
}: {
  children: React.ReactNode;
}) {
  return <Wrap className="data-field-group">{children}</Wrap>;
}
