import type { FC } from "react";
import React from "react";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc } from "src/state/state";

const PersonalizedWelcomeTitle: FC = () => {
  const [, { firstName }] = useBloc(UserPreferencesCubit);

  return <>You’re in, {firstName}!</>;
};

export default PersonalizedWelcomeTitle;
