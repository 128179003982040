import type { UserAddressApiDto } from "@9amhealth/openapi";
import type { TranslationKey } from "src/types/translationKey";

export enum ShipmentAddressStateName {
  initial = "initial",
  fetchingAddressSuccess = "fetchingAddressSuccess",
  fetchingAddressFailure = "fetchingAddressFailure",
  savingAddressSuccess = "savingAddressSuccess",
  savingAddressFailure = "savingAddressFailure"
}

export interface FormError {
  field: string;
  message: TranslationKey;
}
export class ShipmentAddressState {
  stateName: ShipmentAddressStateName = ShipmentAddressStateName.initial;
  errors: FormError[];
  address: Partial<UserAddressApiDto>;

  constructor(
    options: { address?: UserAddressApiDto; errors?: FormError[] } = {}
  ) {
    this.address = options.address ?? {
      firstName: "",
      lastName: "",
      city: "",
      state: "" as UserAddressApiDto.state,
      street: "",
      zip: ""
    };
    this.errors = options.errors ?? [];
  }
}

export class ShipmentAddressFetchingSuccessState extends ShipmentAddressState {
  stateName: ShipmentAddressStateName =
    ShipmentAddressStateName.fetchingAddressSuccess;
}

export class ShipmentAddressFetchingFailureState extends ShipmentAddressState {
  stateName: ShipmentAddressStateName =
    ShipmentAddressStateName.fetchingAddressFailure;
}

export class ShipmentAddressSavingSuccessState extends ShipmentAddressState {
  stateName: ShipmentAddressStateName =
    ShipmentAddressStateName.savingAddressSuccess;
}

export class ShipmentAddressSavingFailureState extends ShipmentAddressState {
  stateName: ShipmentAddressStateName =
    ShipmentAddressStateName.savingAddressFailure;
}
