import type { FC } from "react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Path from "src/constants/path";
import { toast, useBloc } from "src/state/state";

import { createAppPath } from "src/lib/createAppPath";
import UserEmailVerificationCubit from "src/state/UserEmailVerificationCubit/UserEmailVerificationCubit";
import { UserEmailVerificationStateName } from "src/state/UserEmailVerificationCubit/UserEmailVerificationState";
import Loader from "src/ui/components/Loader/Loader";
import ErrorPage, {
  CenterEl,
  ErrorPageLayout
} from "src/ui/components/SentryBoundary/ErrorPage";
import Translate from "src/ui/components/Translate/Translate";

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const ConfirmEmailAddress: FC = () => {
  const navigate = useNavigate();
  const [showResend, setShowResend] = React.useState(true);

  const [
    userVerify,
    { verifyEmailByLink, initiateVerification, resendFailed }
  ] = useBloc(UserEmailVerificationCubit, {
    create: () => new UserEmailVerificationCubit()
  });

  const queryParams = useQuery();

  const linkVerificationToken = queryParams.get("code");

  const handleResend = async () => {
    try {
      const token = queryParams.get("code") ?? "";
      const base64Url = token.split(".")[1] ?? "";

      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const decoded = JSON.parse(
        decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        )
      ) as { vema?: string };
      const email = decoded.vema;

      if (!email) {
        resendFailed();
      }

      setShowResend(false);
      await initiateVerification(email);

      toast.show("email_confirmation_resent");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      resendFailed();
      toast.error("email_confirmation_resend_failed");
      setShowResend(true);
    }
  };

  useEffect(() => {
    if (linkVerificationToken) {
      void verifyEmailByLink({ linkVerificationToken })
        .then(() => {
          navigate(createAppPath(Path.app, "root"), { replace: true });
          toast.show("email_confirmation_success");
        })
        .catch(() => {
          toast.error("email_confirmation_attempt_failed");
          setShowResend(true);
        });
    }
  }, []);

  if (userVerify.stateName === UserEmailVerificationStateName.unverified) {
    return <Loader />;
  }

  if (userVerify.stateName === UserEmailVerificationStateName.resendFailed) {
    return <ErrorPage />;
  }

  return (
    <ErrorPageLayout>
      <div>
        <CenterEl>
          <nine-heading>
            <nine-spacer s="xs"></nine-spacer>
            <h4 className="m0 color-c-80">
              <Translate msg="email_confirmation_failed_title" />
            </h4>
            <nine-spacer s="md"></nine-spacer>
            {showResend ? (
              <>
                <p className="m0 color-c-80">
                  <Translate msg="email_confirmation_failed_message" />
                </p>
              </>
            ) : (
              <>
                <p className="m0 color-c-80">
                  <Translate msg="email_confirmation_resent_message" />
                </p>
              </>
            )}
          </nine-heading>
          {showResend && (
            <>
              <nine-spacer s="lg"></nine-spacer>
              <nine-button
                onClick={(): void => {
                  void handleResend();
                }}
              >
                <Translate msg="resend_email" />
              </nine-button>
            </>
          )}
        </CenterEl>
      </div>
    </ErrorPageLayout>
  );
};

export default ConfirmEmailAddress;
