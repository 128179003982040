import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import { Cubit } from "blac-next";
import FunnelKey from "src/constants/funnelKey";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { loadingState, subscriptionState } from "src/state/state";
import type {
  SignupCustomCampaign,
  SignupCustomPageParameters,
  SignupCustomState,
  SignupCustomStep
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import {
  SignupCustomCampaignName,
  SignupCustomStepType
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import { SignupCustomCampaignCDCDPP } from "src/ui/components/SignupCustomContent/campaigns/SignupCustomCampaignCDCDPP";
import SignupCustomCampaignGenericInstances from "src/ui/components/SignupCustomContent/campaigns/SignupCustomCampaignGenericInstances";

type SignupCustomProps = {
  parameters: SignupCustomPageParameters;
  options: {
    preview?: boolean;
    demo?: boolean;
  };
};

export default class SignupCustomBloc extends Cubit<
  SignupCustomState,
  SignupCustomProps
> {
  defaultDependencySelector = (s: SignupCustomState) => [s];
  checkForDataComplete = true;
  previewMode = false;
  isDemoFunnel = false;

  static allCustomCampaigns: SignupCustomCampaign[] = [
    SignupCustomCampaignCDCDPP,
    ...SignupCustomCampaignGenericInstances
  ];
  customCampaigns: SignupCustomCampaign[] = SignupCustomBloc.allCustomCampaigns;

  constructor(options: {
    parameters: SignupCustomPageParameters;
    options: {
      preview?: boolean;
      demo?: boolean;
      disableInit?: boolean;
    };
  }) {
    const { parameters } = options;
    const campaign = parameters.campaign ?? "";
    const step = parameters.step ?? "";

    // set initial state
    super({ campaign, step, currentStepValid: false, key: 0 });

    this.isDemoFunnel = Boolean(options.options.demo);

    // set preview mode
    this.previewMode = options.options.preview ?? false;
    if (options.options.disableInit !== true) {
      this.init();
    }
  }

  /**
   * Initialize the bloc
   */
  init(): void {
    void this.setInitialStep();
  }

  get enableLogging(): boolean {
    return featureFlags.getFlag(FeatureFlagName.verboseLogging);
  }
  log = (message: string, ...add: unknown[]): void => {
    if (this.enableLogging) {
      // eslint-disable-next-line no-console
      console.warn(`[SignupCustomBloc] ${message}`, ...add);
    }
  };

  getActiveCampaign = (): SignupCustomCampaign => {
    const { campaign } = this.state;

    const activeCampaign = this.customCampaigns.find(
      (customCampaign) =>
        customCampaign.name === (campaign as SignupCustomCampaignName)
    );

    if (!activeCampaign) {
      return {
        funnelKey: FunnelKey.test,
        name: SignupCustomCampaignName.Default,
        prettyName: "Default",
        steps: () => []
      };
    }

    return activeCampaign;
  };

  /**
   * Get the campaign steps from the campaignStepsMap
   * @returns the campaign steps
   */
  getCampaignSteps = (): SignupCustomStep[] => {
    try {
      return this.getActiveCampaign().steps();
    } catch (error) {
      reportErrorSentry(error);
      return [];
    }
  };

  /**
   * Get the campaign pretty name from the campaignPrettyNameMap
   * @param campaign the campaign name
   * @returns the campaign pretty name
   */
  getCampaignPrettyName = (): string => {
    return this.getActiveCampaign().prettyName;
  };

  /**
   * Get the funnel key from the funnelKeyMap
   * @param campaign the campaign name
   * @returns the funnel key
   */
  getCampaignFunnelKey = (): FunnelKey => {
    return this.getActiveCampaign().funnelKey;
  };

  /**
   * Set the initial step
   * If there are no steps, clear the state
   * If there are steps, go to the first incomplete step
   * If there are no incomplete steps, go to the success step
   */
  setInitialStep = async (): Promise<void> => {
    // stop there is no campaignSteps
    if (!this.campaignSteps) {
      return;
    }

    if (this.previewMode) {
      const currentStep = this.campaignSteps.find(
        (s) => s.path === this.state.step
      );

      if (currentStep) {
        this.emit({
          ...this.state,
          currentStepValid: true
        });
        return;
      }
    }

    const firstNotCompleteStep = await this.findFirstIncompleteStep();

    // go to the first incomplete step
    if (firstNotCompleteStep) {
      this.emit({
        ...this.state,
        step: firstNotCompleteStep.path,
        currentStepValid: true
      });
      return;
    }

    // if firstNotCompleteStep is undefined, that means that there are no steps, or that all steps are complete
    // if there are steps, go to the success step
    const successStep = this.campaignSteps.find(
      (s) => s.type === SignupCustomStepType.SuccessPage
    );

    if (successStep) {
      this.emit({
        ...this.state,
        step: successStep.path,
        currentStepValid: true
      });
      return;
    }

    // if there are no steps, clear the state
    this.emit({
      ...this.state,
      step: "",
      currentStepValid: true
    });
  };

  /**
   * Get the current step based on the step in the state
   * @returns the current step or undefined if there are no steps in the state
   */
  getNextStep = (): SignupCustomStep | undefined => {
    const campaignSteps = this.getActiveCampaign().steps();
    const { step } = this.state;

    const currentStepIndex = campaignSteps.findIndex((s) => s.path === step);
    return campaignSteps[currentStepIndex + 1];
  };

  /**
   * Check if the step is complete
   * @param step the step to check
   * @returns true if the step is complete, false if it is not
   */
  checkStepDataComplete = async (step: SignupCustomStep): Promise<boolean> => {
    this.log("checkStepDataComplete", {
      step,
      checkForDataComplete: this.checkForDataComplete,
      alreadyCompleted: this.dataCompletedSteps.has(step.path)
    });
    if (!this.checkForDataComplete) {
      return false;
    }

    if (typeof step.dataComplete === "undefined") {
      return false;
    }

    if (this.dataCompletedSteps.has(step.path)) {
      return true;
    }

    loadingState.start(LoadingKey.checkCustomSignupStepDataComplete);
    try {
      const complete = await step.dataComplete();
      this.log("step.dataComplete()", { step, complete });
      if (complete) {
        this.dataCompletedSteps.add(step.path);
      }
      return complete;
    } catch (error) {
      this.log("Error checking step data complete", error);
      reportErrorSentry(error);
    } finally {
      loadingState.finish(LoadingKey.checkCustomSignupStepDataComplete);
    }

    return false;
  };
  dataCompletedSteps = new Set<string>();

  /**
   * Check if the current step is complete
   * @returns true if the current step is complete, false if it is not
   */
  checkCurrentStepDataComplete = async (): Promise<boolean> => {
    if (!this.currentStep) {
      return false;
    }

    try {
      const complete = await this.checkStepDataComplete(this.currentStep);
      return complete;
    } catch (error) {
      reportErrorSentry(error);
    }

    return false;
  };

  nextStep = async (): Promise<void> => {
    const { currentStep } = this;
    this.log("current", currentStep);

    if (currentStep?.beforeNextStep) {
      loadingState.start(LoadingKey.checkCustomSignupStepDataComplete);
      await currentStep.beforeNextStep();
      loadingState.finish(LoadingKey.checkCustomSignupStepDataComplete);
    }

    const nextStepRequired = await this.findFirstIncompleteStep();
    this.log("nextStepRequired", nextStepRequired);

    const lastSuccessStep = this.campaignSteps?.findLast(
      (s) => s.type === SignupCustomStepType.SuccessPage
    );

    const nextStep = nextStepRequired ?? lastSuccessStep;

    this.log("nextStep", nextStep);

    if (nextStep && nextStep.path !== this.state.step) {
      this.emit({
        ...this.state,
        step: nextStep.path,
        currentStepValid: true,
        key: this.state.key + 1
      });
      return;
    } else {
      addSentryBreadcrumb(
        "funnel",
        `No next step found, step: ${this.state.step}`
      );
    }
  };

  __unsafe__forceStep = (step: SignupCustomStep): void => {
    this.checkForDataComplete = false;
    this.emit({
      ...this.state,
      step: step.path,
      currentStepValid: true,
      key: this.state.key + 1
    });
    this.checkForDataComplete = true;
  };

  /**
   * Checks if all previous steps are complete
   * @returns true if all previous steps are complete, false if any are incomplete
   */
  checkAllPreviousStepsComplete = async (): Promise<boolean> => {
    const steps = this.campaignSteps;

    // if this is the first step, it is always complete
    if (this.currentStepIndex === 0 || !steps) {
      return true;
    }

    // check all previous steps
    for (let i = 0; i < this.currentStepIndex; i++) {
      const step = steps[i];
      const stepDataComplete = await this.checkStepDataComplete(step);
      if (!stepDataComplete) {
        return false;
      }
    }

    // return true if nothing was found to be incomplete
    return true;
  };

  /**
   * Find the first incomplete step based on the campaignSteps in the state
   * @returns the first incomplete step or undefined if all steps are complete
   */
  findFirstIncompleteStep = async (
    onlyRequired = false
  ): Promise<SignupCustomStep | undefined> => {
    const steps = onlyRequired
      ? this.campaignSteps?.filter((s) => s.isRequired)
      : this.campaignSteps;

    if (!steps) {
      return undefined;
    }

    for (const step of steps) {
      const stepDataComplete = await this.checkStepDataComplete(step);
      if (!stepDataComplete) {
        return step;
      }
    }

    return undefined;
  };

  /**
   * Check if the final success step is complete
   */
  checkFinalSuccessStepCompleted = async (): Promise<boolean> => {
    const steps = this.campaignSteps;
    if (!steps) {
      return false;
    }

    const successStep = steps.findLast(
      (s) => s.type === SignupCustomStepType.SuccessPage
    );

    if (!successStep) {
      return false;
    }

    const stepDataComplete = await this.checkStepDataComplete(successStep);
    return stepDataComplete;
  };

  /**
   * Get the index of the step in the campaignSteps array
   * @param step the step to get the index of
   * @returns the index of the step in the campaignSteps array, or -1 if the step is not found
   */
  getStepIndex = (step: SignupCustomStep): number => {
    const { campaignSteps } = this;
    if (!campaignSteps) {
      return -1;
    }

    return campaignSteps.findIndex((s) => s.path === step.path);
  };

  /**
   * Get the path for the step
   * @param step the step to get the path for
   * @returns the path for the step
   */
  getPathForStep = (step: SignupCustomStep): string => {
    const { campaign } = this.state;
    return `/signup/${campaign}/${step.path}`;
  };

  /**
   * Load subscriptions, check for draft or active subscriptions with a membership purchase item and redirect to the funnel
   * The user probably ended up in the wrong funnel, so we redirect them to the correct one
   */
  checkForOtherFunnelSubscriptions = async (): Promise<void> => {
    try {
      await subscriptionState.loadAllSubscriptions();
      const membershipSubscription = subscriptionState
        .filterAllSubscriptions({
          status: [
            SubscriptionDetailsResponse.status.DRAFT,
            SubscriptionDetailsResponse.status.ACTIVE,
            SubscriptionDetailsResponse.status.IN_REVIEW,
            SubscriptionDetailsResponse.status.FINISHED,
            SubscriptionDetailsResponse.status.PAUSED
          ]
        })
        .find((subscription) =>
          subscription.purchaseItems.some(
            (item) => item.sku === "base_membership"
          )
        );

      const subFunnelKey = membershipSubscription?.purchaseItems.find(
        (i) => i.metadata["partner.funnel"]
      )?.metadata["partner.funnel"] as FunnelKey | undefined;

      const subFunnelKeyMatchesCurrent =
        subFunnelKey === this.campaignFunnelKey;

      if (
        !subFunnelKeyMatchesCurrent &&
        membershipSubscription &&
        subFunnelKey
      ) {
        window.location.replace(`/app?from=funnel&funnelKey=${subFunnelKey}`);
      }
    } catch (error) {
      reportErrorSentry(
        new Error("Error checking for other funnel subscriptions", {
          cause: error
        })
      );
    }
  };

  /**
   * Get the current step based on the step in the state,
   * or undefined if there are no steps in the state
   */
  get currentStep(): SignupCustomStep | undefined {
    const { campaignSteps } = this;
    const { step } = this.state;

    if (!campaignSteps) {
      return undefined;
    }

    return campaignSteps.find((s) => s.path === step);
  }

  /**
   * Get the index of the current step in the campaignSteps array,
   * or -1 if there are no steps in the state
   */
  get currentStepIndex(): number {
    const { currentStep } = this;
    if (!currentStep) {
      return -1;
    }

    return this.getStepIndex(currentStep);
  }

  /**
   * Check if the campaign name is valid
   */
  get campaignNameIsValid(): boolean {
    const { campaign } = this.state;
    return Object.values(SignupCustomCampaignName).includes(
      campaign as SignupCustomCampaignName
    );
  }

  /**
   * Get the pretty name of the campaign
   */
  get campaignPrettyName(): string {
    return this.getCampaignPrettyName();
  }

  /**
   * Get the funnel key for the campaign
   */
  get campaignFunnelKey(): FunnelKey {
    return this.getCampaignFunnelKey();
  }

  /**
   * Get the steps for the current campaign
   */
  get campaignSteps(): SignupCustomStep[] | undefined {
    return this.getCampaignSteps();
  }

  get coBrandedLogo(): string | undefined {
    const campaign = this.getActiveCampaign();
    return campaign.logo;
  }

  get coBrandedLogoSize(): SignupCustomCampaign["logoSize"] | undefined {
    const campaign = this.getActiveCampaign();
    return campaign.logoSize;
  }

  get customLogo(): string | undefined {
    const campaign = this.getActiveCampaign();
    return campaign.customLogo;
  }
}
