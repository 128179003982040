export const FOOT_IN_INCHES = 12;
export const INCH_IN_CM = 2.54;
export const POUND_IN_KG = 0.453592;

export const cmToInch = (cm: number | string): number => {
  const val = Number(cm);
  return val / INCH_IN_CM;
};

export const inchesToCm = (inch: number): number => {
  return inch * INCH_IN_CM;
};

export const kgToPounds = (kgInput: number | string): number => {
  const kg = Number(kgInput);
  return kg / POUND_IN_KG;
};

export const poundsToKg = (pounds: number): number => {
  return pounds * POUND_IN_KG;
};

export const cmToFeetAndInches = (
  value: number | string
): { feet: number; inches: number } => {
  const cm = Number(value);
  const inches = cmToInch(cm);
  const feet = Math.floor(inches / FOOT_IN_INCHES);
  const inchesLeft = Math.round(inches - feet * FOOT_IN_INCHES);

  if (inchesLeft === 12) {
    return { feet: feet + 1, inches: 0 };
  }

  return { feet, inches: inchesLeft };
};
