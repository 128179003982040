import { Cubit } from "blac";
import type { ReactElement, SyntheticEvent } from "react";
import HtmlParser from "src/lib/HtmlParser";
import translate from "src/lib/translate";

import { isAppLink } from "lib/Urls";
import {
  ABBREVIATED_QUESTIONNAIRE_ID,
  MEDICAL_QUESTIONNAIRE_ID
} from "src/constants/misc";
import { getRegexMatchResultText } from "src/lib/getRegexMatchResultText";
import { parseSetVarsObject } from "src/lib/parseSetVarsObject";
import { removeQuestionnaireKeywordsFromText } from "src/lib/removeQuestionnaireKeywordsFromText";
import type QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import type { CachedObject } from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import { CustomFieldPropertyRegex } from "src/state/QuestionnaireStepCubit/CustomFields";
import type { TranslationKey } from "src/types/translationKey";
import type { QuestionnaireParsedSelectChoice } from "src/ui/components/MultipleChoiceInput/MultipleChoiceInput";

export enum QuestionnaireType {
  STATEMENT = "statement",
  SHORT_TEXT = "short_text",
  LONG_TEXT = "long_text",
  THANK_YOU = "thankyou_screen",
  WELCOME = "welcome_screen",
  NUMBER = "number",
  MULTIPLE_CHOICE = "multiple_choice",
  DROPDOWN = "dropdown",
  EMAIL = "email",
  DATE = "date",
  PHONE_NUMBER = "phone_number",
  YES_NO = "yes_no",
  ZIP_CODE = "zip_code",
  MEDICATION = "medication",
  MULTIPLE_TEXT = "multiple_text",
  OPINION_SCALE = "opinion_scale"
}

export interface QuestionnaireSelectChoice {
  id: string;
  ref: string;
  label: TranslationKey;
}

export interface QuestionnaireFieldValidations {
  required?: boolean;
  max_value?: number;
  min_value?: number;
}

export enum QuestionnaireFieldAttachmentType {
  IMAGE = "image",
  VIDEO = "video"
}

export enum QuestionnaireFieldLayoutType {
  WALLPAPER = "wallpaper"
}

export type QuestionFieldStyle = "statement";

export enum QuestionFieldValidation {
  a1c = "a1c",
  bloodpressure = "bloodpressure",
  bloodglucose = "bloodglucose",
  weight = "weight",
  waistCircumference = "waistCircumference"
}

export enum QuestionnaireFieldDataType {
  UNKNOWN = "UNKNOWN",
  NAME = "NAME",
  ZIP_CODE = "ZIP_CODE",
  DATE_OF_BIRTH = "DATE_OF_BIRTH",
  ASSIGNED_SEX = "ASSIGNED_SEX",
  LAB_HEIGHT = "LAB_HEIGHT",
  LAB_WEIGHT = "LAB_WEIGHT"
}

export interface QuestionnaireField {
  id: string;
  title: string;
  originalTitle?: string;
  parsedTitle?: ReactElement;
  ref: string;
  properties?: {
    tags?: string[];
    global_disable_data_prefill?: boolean;
    description_first?: boolean;
    order_a1c_step?: boolean;
    description?: string;
    originalDescription?: string;
    setUserDosage?: string;
    ageField?: boolean;
    alphabetical_order?: boolean;
    parsedDescription?: ReactElement;
    button_text?: string;
    input_description?: string;
    button_link?: string;
    button_color?: string;
    hide_marks?: boolean;
    randomize?: boolean;
    allow_multiple_selection?: boolean;
    allow_other_choice?: boolean;
    vertical_alignment?: boolean;
    horizontal_alignment?: {
      title?: boolean;
      description?: boolean;
    };
    always_send_data?: boolean;
    medication_field?: boolean;
    medication_field_times?: string;
    medication_field_units?: string;
    multiple_text_field?: boolean;
    zip_code_field?: boolean;
    height_field?: boolean;
    weight_field?: boolean;
    weight_field_keyword?: string;
    input_label?: string;
    input_placeholder?: string;
    disabled_description?: string;
    default_value?: string;
    auto_continue?: boolean;
    style?: QuestionFieldStyle;
    choices?: QuestionnaireSelectChoice[];
    separator?: string;
    structure?: string;
    show_button?: boolean;
    hide_button?: boolean;
    share_icons?: boolean;
    decimal?: boolean;
    end_adornment?: string;
    validation_key?: QuestionFieldValidation;
    use_value?: string;
    question_reference?: string;
    max_daily_dosage?: number;
    setVars?: Record<string, number | string>;
    dosage?: number;
    redirect_url?: string;
    step_sms_notification_success?: boolean;
    ending?: boolean;
    ineligible?: boolean;
    date_min_max?: string;
    default_country_code?: string;
    start_at_one?: boolean;
    steps?: number;
    labels?: {
      left?: string;
      center?: string;
      right?: string;
    };
  };
  validations?: QuestionnaireFieldValidations;
  type: QuestionnaireType;
  attachment?: {
    type: QuestionnaireFieldAttachmentType;
    href: string;
    properties?: {
      description?: string;
    };
  };
  layout?: {
    type: QuestionnaireFieldLayoutType;
    placement?: "left" | "right";
    attachment: {
      type: QuestionnaireFieldAttachmentType;
      href: string;
      properties?: {
        description?: string;
      };
    };
  };
}

export interface CustomFieldProperties {
  global_disable_data_prefill?: boolean;
  validation?: QuestionFieldValidation;
  decimal: boolean;
  showButton: boolean;
  hideButton: boolean;
  descriptionFirst: boolean;
  step_sms_notification_success: boolean;
  inputDescription?: string;
  buttonText?: string;
  buttonLink?: string;
  buttonColor?: string;
  useValue?: string;
  horizontalCenter: { title: boolean; description: boolean };
  style?: QuestionFieldStyle;
  autoContinue: boolean;
  setUserDosage?: string;
  endAdornment?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  disabledDescription?: string;
  alwaysSendData: boolean;
  medicationField: boolean;
  medicationFieldTimes: string;
  medicationFieldUnits: string;
  tags: string[];
  multipleTextField: boolean;
  zipCodeField: boolean;
  ageField: boolean;
  heightField: boolean;
  weightField: boolean;
  weightFieldKeyword?: string;
  questionReference?: string;
  maxDailyDosage?: string;
  setVars: Record<string, number | string>;
  dosage?: string;
  defaultValue?: string;
  ending?: boolean;
  ineligible?: boolean;
  date_min_max?: string;
}

// type of the keys in the CustomFieldPropertyRegex keys
export type CustomFieldPropertyRegexKeys =
  keyof typeof CustomFieldPropertyRegex;

export interface QuestionnaireEndScreen
  extends Omit<QuestionnaireField, "type"> {
  id: string;
}

interface QuestionnaireStepState {
  fields: QuestionnaireField[];
}

export default class QuestionnaireStepCubit extends Cubit<QuestionnaireStepState> {
  questionnaireState: QuestionnaireCubit;

  constructor(state: {
    questionnaireCubit: QuestionnaireCubit;
    fields: QuestionnaireField[];
  }) {
    super({ fields: state.fields });
    this.questionnaireState = state.questionnaireCubit;
  }

  get targetQuestionnaireName(): string {
    switch (this.questionnaireState.state.formId) {
      case ABBREVIATED_QUESTIONNAIRE_ID:
        return "Eligibility ";
      case MEDICAL_QUESTIONNAIRE_ID:
        return "Medical ";
      default:
        return "";
    }
  }

  // Just return true to always continue to the next step
  // The form itself will check all the validation

  readonly handleSubmit = (field: QuestionnaireField): void => {
    this.questionnaireState.handleSubmit(field);
  };

  readonly handleChange = (
    _event: CustomEvent | SyntheticEvent,
    formData: CachedObject,
    saveOptions: {
      triggerAutoContinue?: boolean;
    } = {}
  ): void => {
    for (const key of Object.keys(formData)) {
      this.questionnaireState.saveValue(key, formData[key], saveOptions);
    }
  };

  readonly triggerAutoContinue = (type: "after-change" | "initil"): void => {
    this.questionnaireState.triggerAutoContinue(type);
  };

  static getCustomFieldProperties = (
    field: QuestionnaireField
  ): CustomFieldProperties => {
    const rx = CustomFieldPropertyRegex;

    const { title } = field;
    const desc = field.properties?.description ?? "";
    const text = `${desc}${title}`;

    const globalDisableDataPrefill = Boolean(
      CustomFieldPropertyRegex.GLOBAL_DISABLE_DATA_PREFILL.exec(text)
    );

    const alwaysSendData = Boolean(
      CustomFieldPropertyRegex.ALWAYS_SEND_DATA.exec(text)
    );

    const medicationField = Boolean(
      CustomFieldPropertyRegex.MEDICATION_FIELD.exec(text)
    );

    const multipleTextField = Boolean(
      CustomFieldPropertyRegex.MULTIPLE_TEXT_FIELD.exec(text)
    );

    const zipCodeField = Boolean(
      CustomFieldPropertyRegex.ZIP_CODE_FIELD.exec(text)
    );

    const ageField = Boolean(CustomFieldPropertyRegex.AGE_FIELD.exec(text));

    const heightField = Boolean(
      CustomFieldPropertyRegex.HEIGHT_FIELD.exec(text)
    );
    const weightField = Boolean(
      CustomFieldPropertyRegex.WEIGHT_FIELD.exec(text) ??
        CustomFieldPropertyRegex.WEIGHT_FIELD_CUSTOM.exec(text)
    );
    const weightFieldKeyword = getRegexMatchResultText(
      rx.WEIGHT_FIELD_CUSTOM.exec(text)
    );

    let buttonLink = getRegexMatchResultText(rx.BUTTON_LINK.exec(text));
    if (buttonLink && buttonLink.indexOf("](http") !== -1) {
      buttonLink = getRegexMatchResultText(
        /\[(.*?)]\((.*?)\)/.exec(buttonLink)
      );
    }

    return {
      global_disable_data_prefill: globalDisableDataPrefill,
      validation: getRegexMatchResultText<QuestionFieldValidation>(
        rx.VALIDATION.exec(text)
      ),
      decimal: Boolean(rx.DECIMAL.exec(text)),
      step_sms_notification_success: Boolean(
        rx.STEP_SMS_NOTIFICATION_SUCCESS.exec(text)
      ),
      showButton: Boolean(rx.SHOW_BUTTON.exec(text)),
      hideButton: Boolean(rx.HIDE_BUTTON.exec(text)),
      descriptionFirst: Boolean(rx.DESCRIPTION_FIRST.exec(text)),
      inputDescription: getRegexMatchResultText(
        rx.INPUT_DESCRIPTION.exec(text)
      ),
      buttonText: getRegexMatchResultText(rx.BUTTON_TEXT.exec(text)),
      buttonLink,
      buttonColor: getRegexMatchResultText(rx.BUTTON_COLOR.exec(text)),
      useValue: getRegexMatchResultText(rx.USE_VALUE.exec(text)),
      horizontalCenter: {
        title: Boolean(field.originalTitle?.match(rx.CENTER)),
        description: Boolean(
          field.properties?.originalDescription?.match(rx.CENTER)
        )
      },
      style: getRegexMatchResultText<QuestionFieldStyle>(rx.STYLE.exec(text)),
      autoContinue: Boolean(rx.AUTO_CONTINUE.exec(text)),
      endAdornment: getRegexMatchResultText(rx.AFFIX_SUFFIX.exec(text)),
      setUserDosage: getRegexMatchResultText(rx.SET_USER_DOSAGE.exec(text)),
      inputLabel: getRegexMatchResultText(rx.LABEL.exec(text)),
      inputPlaceholder: getRegexMatchResultText(rx.PLACEHOLDER.exec(text)),
      disabledDescription: getRegexMatchResultText(
        rx.DISABLED_DESCRIPTION.exec(text)
      ),
      alwaysSendData,
      medicationField,
      medicationFieldTimes:
        getRegexMatchResultText(rx.MEDICATION_FIELD_TIMES.exec(text)) ?? "",
      medicationFieldUnits:
        getRegexMatchResultText(rx.MEDICATION_FIELD_UNITS.exec(text)) ?? "",
      tags: getRegexMatchResultText(rx.TAGS.exec(text))?.split(",") ?? [],
      multipleTextField,
      zipCodeField,
      ageField,
      heightField,
      weightField,
      weightFieldKeyword,
      questionReference: getRegexMatchResultText(
        rx.QUESTION_REFERENCE.exec(text)
      ),
      maxDailyDosage: getRegexMatchResultText(
        rx.MAXIMUM_DAILY_DOSAGE.exec(text)
      ),
      setVars: parseSetVarsObject(rx.SET_VAR(), text),
      dosage: getRegexMatchResultText(rx.DOSAGE.exec(text)),
      ending: Boolean(rx.ENDING.exec(text)),
      ineligible: Boolean(rx.INELIGIBLE.exec(text)),
      date_min_max: getRegexMatchResultText(rx.DATE_MAX_MIN.exec(text)),
      defaultValue: getRegexMatchResultText(rx.DEFAULT_VALUE.exec(text))
    };
  };

  static prepareFieldData = (field: QuestionnaireField): QuestionnaireField => {
    const { title } = field;
    const description = field.properties?.description ?? "";
    const newField: QuestionnaireField = {
      ...field,
      originalTitle: title,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      type: field.type ?? QuestionnaireType.THANK_YOU
    };

    let redirectUrl = field.properties?.redirect_url ?? "";
    if (isAppLink(redirectUrl)) {
      const { pathname } = new URL(redirectUrl);
      redirectUrl = new URL(pathname, window.location.origin).href;
    }

    // Translate button text and fallback to default
    const textForType =
      newField.type === QuestionnaireType.THANK_YOU ? "submit" : "continue";
    const button_text = translate(
      (field.properties?.button_text ?? textForType) as TranslationKey
    );

    if (!newField.properties) {
      newField.properties = {};
    }

    newField.properties.originalDescription = description;
    newField.properties.redirect_url = redirectUrl;

    const parsed = QuestionnaireStepCubit.getCustomFieldProperties(newField);
    newField.properties.tags = parsed.tags;
    newField.properties.global_disable_data_prefill =
      parsed.global_disable_data_prefill;
    newField.properties.auto_continue = parsed.autoContinue;
    newField.properties.validation_key = parsed.validation;
    newField.properties.style = parsed.style;
    newField.properties.horizontal_alignment = parsed.horizontalCenter;
    newField.properties.show_button = parsed.showButton;
    newField.properties.hide_button = parsed.hideButton;
    newField.properties.description_first = parsed.descriptionFirst;
    newField.properties.decimal = parsed.decimal;
    newField.properties.use_value = parsed.useValue;
    newField.properties.ending = parsed.ending;
    newField.properties.ineligible = parsed.ineligible;
    newField.properties.input_description = parsed.inputDescription;
    newField.properties.button_text = parsed.buttonText ?? button_text;
    newField.properties.button_link = parsed.buttonLink;
    newField.properties.button_color = parsed.buttonColor;
    newField.properties.setUserDosage = parsed.setUserDosage;
    newField.properties.ageField = parsed.ageField;
    newField.properties.end_adornment = parsed.endAdornment;
    newField.properties.input_label = parsed.inputLabel;
    newField.properties.input_placeholder = parsed.inputPlaceholder;
    newField.properties.disabled_description = parsed.disabledDescription;
    newField.properties.question_reference = parsed.questionReference;
    newField.properties.max_daily_dosage = Number(parsed.maxDailyDosage);
    newField.properties.setVars = parsed.setVars;
    newField.properties.dosage = Number(parsed.dosage);
    newField.properties.default_value = parsed.defaultValue;
    newField.properties.step_sms_notification_success =
      parsed.step_sms_notification_success;
    newField.properties.date_min_max = parsed.date_min_max;
    newField.properties.medication_field_times = parsed.medicationFieldTimes;
    newField.properties.medication_field_units = parsed.medicationFieldUnits;

    if (parsed.medicationField) newField.type = QuestionnaireType.MEDICATION;
    if (parsed.multipleTextField)
      newField.type = QuestionnaireType.MULTIPLE_TEXT;
    if (parsed.zipCodeField) newField.type = QuestionnaireType.ZIP_CODE;

    newField.properties.medication_field = parsed.medicationField;
    newField.properties.zip_code_field = parsed.zipCodeField;
    newField.properties.always_send_data = parsed.alwaysSendData;
    newField.properties.height_field = parsed.heightField;
    newField.properties.weight_field = parsed.weightField;
    newField.properties.weight_field_keyword = parsed.weightFieldKeyword ?? "";

    return newField;
  };

  static removeFieldPropertiesKeywords = (text: string): string => {
    return removeQuestionnaireKeywordsFromText(text);
  };

  static parseEmbeds = (text: string = ""): string => {
    let parsedText = text;

    const embedRegex = /\[embed="([a-zA-Z0-9-]+)"\]/;

    // loop through all matches
    let match;
    while ((match = embedRegex.exec(parsedText)) !== null) {
      // get the matched text
      const [full, embedKey] = match;
      const embed = `<div data-embed="${embedKey}"></div>`;
      parsedText = parsedText.replace(full, embed);
    }

    return parsedText;
  };

  static parseField = ({
    layout,
    attachment,
    ...field
  }: QuestionnaireField): QuestionnaireField => {
    // remove field properties keys from title
    const title = QuestionnaireStepCubit.removeFieldPropertiesKeywords(
      field.title
    );

    // Parse title from html to JSX
    const parsedTitle = new HtmlParser(title, {
      convertMarkdownToHtml: true,
      emAsUnderline: true,
      disabledTags: ["p"]
    }).toJsx() as ReactElement;

    let description = QuestionnaireStepCubit.parseEmbeds(
      field.properties?.description ?? ""
    );

    // remove field properties keys from description
    description =
      QuestionnaireStepCubit.removeFieldPropertiesKeywords(description);

    // Parse description from html to JSX
    const parsedDescription = new HtmlParser(description, {
      convertMarkdownToHtml: true,
      removeDashOnlyRows: true
    }).toJsx() as ReactElement;

    const isLayoutWallpaper =
      layout?.type === QuestionnaireFieldLayoutType.WALLPAPER;

    // check if field attachment is the same as layout attachment and layout type is wallpaper
    const isAttachmentLayoutWallpaper =
      JSON.stringify(layout?.attachment) === JSON.stringify(attachment) &&
      isLayoutWallpaper;

    return {
      ...field,
      parsedTitle,
      properties: {
        ...(field.properties ?? {}),
        parsedDescription
      },
      layout,
      ...(!isAttachmentLayoutWallpaper && { attachment })
    };
  };

  // check field data type
  static checkDataType = (
    field: QuestionnaireField
  ): QuestionnaireFieldDataType => {
    const dataTypes = [
      QuestionnaireStepCubit.isDataTypeName,
      QuestionnaireStepCubit.isDataTypeZipCode,
      QuestionnaireStepCubit.isDataTypeDateOfBirth,
      QuestionnaireStepCubit.isDataTypeWeight,
      QuestionnaireStepCubit.isDataTypeHeight,
      QuestionnaireStepCubit.isDataTypeAssignedSexAtBirth
    ];

    for (const dataType of dataTypes) {
      const match = dataType(field);
      if (match) {
        return match;
      }
    }

    return QuestionnaireFieldDataType.UNKNOWN;
  };

  static isDataTypeName = (
    field: QuestionnaireField
  ): QuestionnaireFieldDataType.NAME | false => {
    if (field.type !== QuestionnaireType.MULTIPLE_TEXT) {
      return false;
    }

    const fieldChoices = field.properties?.choices ?? [];
    const dataKeys = {
      firstNameDataKey: '[data-key="firstname"]',
      lastNameDataKey: '[data-key="lastname"]'
    };

    for (const choice of fieldChoices) {
      const choiceLabel = choice.label;

      if (
        choiceLabel.includes(dataKeys.lastNameDataKey) ||
        choiceLabel.includes(dataKeys.firstNameDataKey)
      ) {
        return QuestionnaireFieldDataType.NAME;
      }
    }

    return false;
  };

  static isDataTypeZipCode = (
    field: QuestionnaireField
  ): QuestionnaireFieldDataType.ZIP_CODE | false => {
    const match = field.properties?.zip_code_field ?? false;
    return match ? QuestionnaireFieldDataType.ZIP_CODE : false;
  };

  static isDataTypeDateOfBirth = (
    field: QuestionnaireField
  ): QuestionnaireFieldDataType.DATE_OF_BIRTH | false => {
    const match = field.properties?.ageField ?? false;
    return match ? QuestionnaireFieldDataType.DATE_OF_BIRTH : false;
  };

  static isDataTypeWeight = (
    field: QuestionnaireField
  ): QuestionnaireFieldDataType.LAB_WEIGHT | false => {
    const match = field.properties?.weight_field ?? false;
    return match ? QuestionnaireFieldDataType.LAB_WEIGHT : false;
  };

  static isDataTypeHeight = (
    field: QuestionnaireField
  ): QuestionnaireFieldDataType.LAB_HEIGHT | false => {
    const match = field.properties?.height_field ?? false;
    return match ? QuestionnaireFieldDataType.LAB_HEIGHT : false;
  };

  static isDataTypeAssignedSexAtBirth = (
    field: QuestionnaireField
  ): QuestionnaireFieldDataType.ASSIGNED_SEX | false => {
    const typeMatch = field.type === QuestionnaireType.MULTIPLE_CHOICE;
    const fieldChoices = field.properties?.choices ?? [];

    if (!typeMatch || fieldChoices.length !== 2) {
      return false;
    }

    const choiceLabels = fieldChoices.map((choice) => choice.label);
    const optionsMatch =
      choiceLabels.includes("Male") && choiceLabels.includes("Female");

    return optionsMatch ? QuestionnaireFieldDataType.ASSIGNED_SEX : false;
  };

  updateCustomKeyList = (options: {
    selected: QuestionnaireParsedSelectChoice[];
    all: QuestionnaireParsedSelectChoice[];
  }): void => {
    const { selected, all } = options;
    const addRegex = /\[add="(.*?)"\]/m;
    const removeRegex = /\[remove="(.*?)"\]/m;

    const removeKeys = new Set<string>();
    const addKeys = new Set<string>();

    for (const option of all) {
      const { label } = option;
      const isSelected = selected.includes(option);

      // add
      const addMatch = addRegex.exec(label);
      if (addMatch) {
        const [, key] = addMatch;
        if (isSelected) {
          addKeys.add(key);
        } else {
          removeKeys.add(key);
        }
      }

      // remove
      const removeMatch = removeRegex.exec(label);
      if (removeMatch) {
        const [, key] = removeMatch;
        if (isSelected) {
          removeKeys.add(key);
        } else {
          addKeys.add(key);
        }
      }
    }

    for (const key of removeKeys) {
      this.questionnaireState.removeFromKeyList(key);
    }

    for (const key of addKeys) {
      this.questionnaireState.addToKeyList(key);
    }
  };

  checkChoiceForCustomKeys = (
    choice: QuestionnaireSelectChoice
  ): {
    show?: boolean;
  } => {
    const result = {
      show: true
    };

    const showIfRegex = /\[show-if="(.*?)"\]/m;
    const hideIfRegex = /\[hide-if="(.*?)"\]/m;
    const { label } = choice;

    // show if
    const showIfMatch = showIfRegex.exec(label);
    if (showIfMatch) {
      const [, key] = showIfMatch;
      result.show = this.questionnaireState.checkIfKeyExists(key);
    }

    // hide if
    const hideIfMatch = hideIfRegex.exec(label);
    if (hideIfMatch) {
      const [, key] = hideIfMatch;
      result.show = !this.questionnaireState.checkIfKeyExists(key);
    }

    return result;
  };
}
