// istanbul ignore file

import reportErrorSentry from "./reportErrorSentry";

/*
This method is mocked for the test runner, so testing is disabled.
Functionality is tested, if there are any changes after [FRONT-28]
 */
const imageLoadPromise = async (path: string): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => {
      resolve(img);
    });

    // handle error
    img.addEventListener("error", (e) => {
      reportErrorSentry(e);
      reject(e);
    });

    img.src = path;
  });
};

export default imageLoadPromise;
