import type { FC } from "react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import LoadingCubit from "src/state/LoadingCubit/LoadingCubit";
import { tracker, useBloc } from "src/state/state";

const TrackPageViews: FC = () => {
  const location = useLocation();
  const [allLoading] = useBloc(LoadingCubit);
  const trackViewDebuff = useRef<NodeJS.Timeout | number>(0);
  const lastTrackUrl = useRef<string>("_none_");

  useEffect(() => {
    const isLoading = allLoading.length > 0;
    if (isLoading) return;

    clearTimeout(trackViewDebuff.current);

    trackViewDebuff.current = setTimeout(() => {
      if (lastTrackUrl.current === location.pathname) return;
      lastTrackUrl.current = location.pathname;

      tracker.trackPageView(location);
    }, 1000);
  }, [allLoading, location.pathname]);

  return null;
};

export default TrackPageViews;
