export interface ProviderCredentials {
  refreshToken?: string;
  accessToken?: string;
  username?: string;
  password?: string;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default abstract class CredentialsProvider {
  abstract isAvailable(): Promise<boolean>;
  abstract isEnabled(): Promise<boolean>;
  abstract isVerified(): boolean;
  abstract getCredentials(): Promise<ProviderCredentials | undefined>;
  abstract setCredentials(credentials: ProviderCredentials): Promise<void>;
  abstract deleteCredentials(): Promise<void>;
}
