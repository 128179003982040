const httpProtocol = /^https?:\/\//;

const DEBUG = (import.meta.env.VITE_DEBUG ?? "0") === "1";
const envVariables = {
  PROD: import.meta.env.PROD,
  DEBUG,
  TRACKING: !DEBUG,
  DEV: import.meta.env.DEV,
  WEB_URL: `${import.meta.env.VITE_WEB_URL}`,
  APP_ENV: `${import.meta.env.VITE_APP_ENV}`,
  CMS_API_BASE_URL: `${import.meta.env.VITE_CMS_API_BASE_URL}`.replace(
    /\/$/,
    ""
  ),
  API_BASE_URL: `${import.meta.env.VITE_API_BASE_URL}`.replace(/\/$/, ""),
  API_BASE_URL_LEGACY: `${import.meta.env.VITE_API_BASE_URL_LEGACY}`.replace(
    /\/$/,
    ""
  ),
  WS_BASE_URL: `${import.meta.env.VITE_API_BASE_URL}`
    .replace(httpProtocol, "wss://")
    .replace(/\/$/, ""),
  ENV_NAME: `${import.meta.env.VITE_ENV_NAME}`,
  SENTRY_DSN: `${import.meta.env.VITE_SENTRY_DSN || ""}`,
  STRIPE_PUBLISHABLE_KEY: `${import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}`,
  STRIPE_ACCOUNT_ID: `${import.meta.env.VITE_STRIPE_ACCOUNT_ID}`,
  MIXPANEL_TOKEN: `${import.meta.env.VITE_MIXPANEL_TOKEN}`,
  SANITY_PROJECT_ID: `${import.meta.env.VITE_SANITY_PROJECT_ID}`,
  SANITY_STUDIO_API_DATASET: `${
    import.meta.env.VITE_SANITY_STUDIO_API_DATASET
  }`,
  APPLICATION_ID: `${import.meta.env.VITE_APPLICATION_ID}`,
  CUSTOMER_IO_SITE_ID: `${import.meta.env.VITE_CUSTOMER_IO_SITE_ID}`,
  SSO_LINK_TRANSCARENT: `${import.meta.env.VITE_SSO_LINK_TRANSCARENT}`,
  VIMEO_TOKEN: `${import.meta.env.VITE_VIMEO_TOKEN}`,
  BUILD_VERSION: `${import.meta.env.VITE_BUILD_VERSION}`,
  RXDIET_IFRAME_URL: `${import.meta.env.VITE_RXDIET_IFRAME_URL}`
} as const;

export default envVariables;
