import React, { CSSProperties } from "react";
import { FC } from "react";
import ShowIfSubscription from "src/ui/components/ShowIfSubscription/ShowIfSubscription";
import Translate from "src/ui/components/Translate/Translate";
import translate from "src/lib/translate";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import styled from "@emotion/styled";

const Frame = styled.p`
  color: var(--Greys-Charcoal, #212121);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  padding: 0.75rem;
  border-radius: 0.5rem;
  background: var(--bg-color, var(--Greys-Fog, #f4f4f4));
  margin: 0.5rem 0;
`;

const NoteSection: FC<{
  profileProgramMembership: ProfileProgramMembership;
  selfPayd?: boolean;
  rpmConditions?: boolean;
}> = (props) => {
  return (
    <>
      <Frame>
        <Translate
          msg={`checkout.program.note_MEDICATION_COST`}
          variables={() => ({
            program: translate(
              `program.title_${props.profileProgramMembership}`
            )
          })}
        />
        <ShowIfSubscription selfPayd={props.selfPayd}>
          <br />
          <Translate msg={`checkout.program.note_COPAY`} />
        </ShowIfSubscription>
      </Frame>

      {props.rpmConditions && (
        <Frame
          style={{ "--bg-color": "rgba(255, 222, 184, 0.50)" } as CSSProperties}
        >
          <Translate msg={`checkout.program.note_RPM_CONDITIONS`} />
        </Frame>
      )}
    </>
  );
};
export default NoteSection;
