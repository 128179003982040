function t<V extends string, T extends { [key in string]: V }>(o: T): T {
  return o;
}

const appErrors = t({
  user_not_authenticated: "User data missing in message state",
  upload_max_filesize_exceeded: "error_max_filesize_exceeded",
  generic: "error_network_error",
  default_payment_retry_count_exceeded:
    "error_default_payment_retry_count_exceeded"
});

type Keys = keyof typeof appErrors;
export type AppError = (typeof appErrors)[Keys];

export default appErrors;
