import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import BBBLogo from "src/ui/assets/logos/bbb_logo_new.png";

const TrustWrap = styled.div`
  label: TrustWrap;
  display: grid;
  place-items: center;

  nine-bg[variant="animate-primary"] & {
    --color-black-40: #000;
    --color-charcoal-60: #000;
  }
`;

export const Trust: FC = () => {
  return (
    <TrustWrap>
      <nine-social-proof
        imageSrc={BBBLogo}
        imageAlt="9amHealth BBB Business Review"
        href="https://www.bbb.org/us/ca/encinitas/profile/health-and-wellness/9amhealth-1126-1000089320/#sealclick%22"
        target="_blank"
        rel="nofollow norefferer"
      ></nine-social-proof>
    </TrustWrap>
  );
};
