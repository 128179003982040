import styled from "@emotion/styled";

const svgCheckIcon = `url('data:image/svg+xml;charset=UTF-8,<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 5L6.85355 11.6464C6.65829 11.8417 6.34171 11.8417 6.14645 11.6464L3 8.5" stroke="%23FFBD70" stroke-width="2" stroke-linecap="round"/></svg>')`;
export const Container = styled.div`
  > div:last-child {
    div:last-child::after {
      height: calc(100% - 1em);
    }
  }
`;

export const ListWrapper = styled.div`
  margin-left: 2rem;
  margin-top: 1.5rem;

  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.3em;
    width: 0.5em;
    height: 0.5em;
    border: 2px solid var(--color-gray-lighter);
    background-color: transparent;
    border-radius: 50%;
  }

  ::after {
    content: "";
    position: absolute;
    left: 0.325em;
    top: 1.5em;
    width: 1px;
    height: 100%;
    border-radius: 1px;
    background-color: var(--color-gray-lighter);
  }
`;

export const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const ListElement = styled.li`
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.5em;

  &:first-child {
    margin-top: 0.75rem;
  }

  &::before {
    content: "";
    background-image: var(--check-icon, ${svgCheckIcon});
    position: absolute;
    left: 0;
    top: 0.1em;
    width: 1em;
    height: 1em;

    background-color: transparent;
    transform: unset;
  }
`;
