import { SKU_SEPARATOR } from "src/constants/misc";

const parseItemSku = (
  sku: string
): { name: string; dosage: string; dailyDosageTimes: number | undefined } => {
  const [name, dosage = "", dailyDosageTimes] = sku.split(SKU_SEPARATOR);
  return {
    name,
    dosage,
    dailyDosageTimes: dailyDosageTimes
      ? parseFloat(dailyDosageTimes)
      : undefined
  };
};

export default parseItemSku;
