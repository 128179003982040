import styled from "@emotion/styled";
import type { CodeableConcept } from "fhir/r4";
import type { FC } from "react";
import React from "react";
import { Tag, TagGroup, TagList } from "react-aria-components";
import { IconCheckmarkCompleted } from "src/constants/icons";
import translate from "src/lib/translate";
import type { TranslationKey } from "src/types/translationKey";

const StyledButton = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  background: var(--color-cream-darker);
  color: var(--color-charcoal);
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;

  .icon-checkmark {
    display: none;
  }

  &[data-selected="true"] {
    background: var(--gradient-dusk);
    color: white;

    .icon-checkmark {
      display: block;
      margin-right: 0.2rem;
    }
  }
`;

const CheckboxGroupContent = styled(TagList)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

export enum BloodGlucoseTag {
  BeforeMeal = "before_meal",
  AfterMeal = "after_meal",
  Fasting = "fasting",
  Bedtime = "bedtime"
}

export const temporalPeriodMap: Record<string, CodeableConcept> = {
  [BloodGlucoseTag.Bedtime]: {
    text: "Before sleeping",
    coding: [
      {
        code: "307155000",
        display: "Before sleeping",
        system: "http://snomed.info/sct"
      }
    ]
  },
  [BloodGlucoseTag.BeforeMeal]: {
    text: "Before meal",
    coding: [
      {
        code: "307165006",
        display: "Before meal",
        system: "http://snomed.info/sct"
      }
    ]
  },
  [BloodGlucoseTag.AfterMeal]: {
    text: "After meal",
    coding: [
      {
        code: "24863003",
        display: "After meal",
        system: "http://snomed.info/sct"
      }
    ]
  }
};

const bloodGlucoseTagToLabel: Record<BloodGlucoseTag, TranslationKey> = {
  before_meal: "temporal_period.before_meal",
  after_meal: "temporal_period.after_meal",
  fasting: "temporal_period.fasting",
  bedtime: "temporal_period.bedtime"
};

interface Props {
  onTagSelected: (tag: BloodGlucoseTag | undefined) => void;
}

const BloodGlucoseTagSelector: FC<Props> = ({ onTagSelected }) => {
  const handleChange = (tag: BloodGlucoseTag | undefined) => {
    onTagSelected(tag);
  };

  return (
    <TagGroup
      selectionMode="single"
      onSelectionChange={(selected) => {
        const asArray = Array.from(selected);
        const first = asArray[0] as BloodGlucoseTag | undefined;
        handleChange(first);
      }}
    >
      <CheckboxGroupContent>
        {Object.values(BloodGlucoseTag).map((tag) => (
          <StyledButton
            textValue={translate(bloodGlucoseTagToLabel[tag])}
            key={tag}
            id={tag}
          >
            <IconCheckmarkCompleted />
            {translate(bloodGlucoseTagToLabel[tag])}
          </StyledButton>
        ))}
      </CheckboxGroupContent>
    </TagGroup>
  );
};

export default BloodGlucoseTagSelector;
