import type React from "react";
import { useEffect } from "react";
import type { VideoPlayerOptions } from "./FullVideoPlayerBloc";
import { VideoPopupController } from "./VideoPopup";

type UseVideoResponse = [(videoOptions: VideoPlayerOptions) => void];

const useVideoPopup = (
  options: {
    animationSourceRef?: React.RefObject<HTMLElement>;
    onClose?: () => void;
  } = {}
): UseVideoResponse => {
  const openVideo = (videoOptions: VideoPlayerOptions) => {
    const source = options.animationSourceRef?.current;
    VideoPopupController.setAnimationSource(source);
    VideoPopupController.openVideo(videoOptions, options.onClose);
  };

  useEffect(() => {
    return () => {
      VideoPopupController.closeVideo();
    };
  }, []);

  return [openVideo];
};

export default useVideoPopup;
