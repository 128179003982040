import { useIonToast } from "@ionic/react";
import type React from "react";
import { useEffect } from "react";
import ToastBloc from "src/state/Toast/ToastBloc";
import { useBloc } from "src/state/state";

const Toast: React.FC = () => {
  const [, { linkToast }] = useBloc(ToastBloc);
  const ionToast = useIonToast();

  useEffect(() => {
    linkToast(ionToast);
  }, [ionToast]);

  return null;
};

export default Toast;
