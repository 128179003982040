import { Cubit } from "blac";
import type {
  SuccessGetAddressResponseItem,
  UserAddressApiDto
} from "@9amhealth/openapi";
import { AddressesControllerService, ApiError } from "@9amhealth/openapi";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import {
  ShipmentAddressFetchingFailureState,
  ShipmentAddressFetchingSuccessState,
  ShipmentAddressSavingFailureState,
  ShipmentAddressSavingSuccessState,
  ShipmentAddressState
} from "src/state/ShipmentAddressCubit/ShipmentAddressState";
import { apiMiddleware, loadingState, userPreferences } from "src/state/state";

export type ShippingFormData = UserAddressApiDto;

export default class ShipmentAddressCubit extends Cubit<ShipmentAddressState> {
  constructor() {
    super(new ShipmentAddressState());
  }

  readonly setShipmentAddress = async (
    data: ShippingFormData,
    successCallback: () => Promise<void> | void
  ): Promise<void> => {
    try {
      if (loadingState.isLoading(LoadingKey.shippingAddress)) {
        return;
      }

      loadingState.start(LoadingKey.shippingAddress);

      await AddressesControllerService.putDefaultAddress("SHIPMENT", {
        ...data
      });

      this.emit(new ShipmentAddressSavingSuccessState({ address: data }));

      apiMiddleware.clearAll();

      await successCallback();
    } catch (e: unknown) {
      reportErrorSentry(e);
      this.emit(new ShipmentAddressSavingFailureState());
      loadingState.finish(LoadingKey.shippingAddress);
    } finally {
      loadingState.finish(LoadingKey.shippingAddress);
    }
  };

  readonly getShipmentAddress = async (): Promise<void> => {
    this.emit(new ShipmentAddressState());
    try {
      loadingState.start(LoadingKey.shippingAddress);
      const {
        data: { addressData }
      } = await ShipmentAddressCubit.loadShipmentAddress();

      const { zip } = addressData;
      if (zip) {
        const stateObj = await userPreferences.checkZipCode(zip);
        addressData.state = (stateObj as UserAddressApiDto).state;
      } else {
        addressData.state = "" as UserAddressApiDto.state;
      }

      this.emit(
        new ShipmentAddressFetchingSuccessState({
          address: {
            ...addressData
          }
        })
      );
    } catch (e: unknown) {
      if (e instanceof ApiError && e.status !== 404) {
        reportErrorSentry(e);
      }
      this.emit(new ShipmentAddressFetchingFailureState());
    } finally {
      loadingState.finish(LoadingKey.shippingAddress);
    }
  };

  static loadShipmentAddressPromise: Promise<SuccessGetAddressResponseItem> | null =
    null;
  static loadShipmentAddress =
    async (): Promise<SuccessGetAddressResponseItem> => {
      if (this.loadShipmentAddressPromise) {
        return this.loadShipmentAddressPromise;
      }

      const promise = AddressesControllerService.getDefaultAddress("SHIPMENT");
      void promise.finally(() => {
        this.loadShipmentAddressPromise = null;
      });

      this.loadShipmentAddressPromise = promise;
      return promise;
    };
}
