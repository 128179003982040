export enum UserEmailVerificationStateName {
  unverified = "unverified",
  verified = "verified",
  verificationFailed = "verificationFailed",
  resendFailed = "resendFailed"
}

export type UserEmailVerificationStateInitial = {
  stateName: UserEmailVerificationStateName;
};
