import type { PurchaseItemResponse } from "@9amhealth/openapi";
import parseItemSku from "src/lib/parseItemSku";
import translate from "src/lib/translate";

export enum KnownPurchaseItemMetaDataKeys {
  intakeMultiplier = "medication.dosage-multiplication",
  intake = "medication.daily-dosage-details",
  intakeTimes = "medication.daily-dosage-times",
  mandatory = "mandatory.reasons"
}

export interface ParsedPurchaseItemResponse extends PurchaseItemResponse {
  title: string;
  dosage: string;
  skuString: string;
  intake?: string;
  intakeTimes?: string;
}

const parsePurchaseItem = (
  item: PurchaseItemResponse
): ParsedPurchaseItemResponse => {
  const { name: skuString, dosage } = parseItemSku(item.sku);

  return {
    ...item,
    title: translate(`suggestion_item_title`, { context: skuString }),
    skuString,
    dosage,
    intake: item.metadata[KnownPurchaseItemMetaDataKeys.intake] as
      | string
      | undefined,
    intakeTimes: item.metadata[KnownPurchaseItemMetaDataKeys.intakeTimes] as
      | string
      | undefined
  };
};

export default parsePurchaseItem;
