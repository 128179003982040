import AuthenticationBloc from "../UserCubit/AuthenticationBloc";
import UserCubit from "../UserCubit/UserCubit";
import { useBloc, userState } from "../state";

export default function useUserAuthenticatedState(): boolean {
  // add hooks so that this component re-renders when the user state changes
  useBloc(AuthenticationBloc);
  useBloc(UserCubit);

  return userState.hasAppAccess();
}
