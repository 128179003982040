import * as Sentry from "@sentry/react";

export type SentryBreadcrumbCategory =
  | "api"
  | "appView"
  | "auth"
  | "chat"
  | "avatar"
  | "errorHandler"
  | "tracker"
  | "websocket";

export const addSentryBreadcrumb = (
  category: SentryBreadcrumbCategory | string,
  message: string,
  level: Sentry.SeverityLevel = "info",
  data?: AnyObject
): void => {
  Sentry.addBreadcrumb({
    category,
    message,
    level,
    data
  });
};
