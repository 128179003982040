import styled from "@emotion/styled";
import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import type { FC, ReactElement } from "react";
import React, { useEffect, useMemo, useState } from "react";

import type { InputChangeDetails } from "@9amhealth/wcl";
import { NineInput as NineInputWcl } from "@9amhealth/wcl";
import { NineInput } from "@9amhealth/wcl/generated/react";
import { validatePhoneNumber } from "src/lib/formatPhoneNumber";
import { isHybridApp } from "src/lib/platform";
import translate from "src/lib/translate";
import PaymentCubit from "src/state/PaymentCubit/PaymentCubit";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import type { SubscriptionId } from "src/state/SubscriptionCubit/SubscriptionCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { tracker, useBloc } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import EditIcon from "src/ui/assets/icons/EditIcon";
import DisplayPaymentMethod from "src/ui/components/DisplayPaymentMethod/DisplayPaymentMethod";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import { TileWrapper } from "src/ui/components/TileWrapper/TileWrapper";
import Translate from "src/ui/components/Translate/Translate";
import Align from "src/ui/styled/Align";
import Item from "src/ui/styled/Item";

const ItemFullWidth = styled(Item)`
  flex: 1;
`;

const InputWrapper = styled.div`
  margin-bottom: 0.8rem;
`;

export type BuyActionProp = (
  subscriptionId: SubscriptionId,
  options?: { onError?: () => void; onSuccess?: () => void },
  scopeKey?: string
) => Promise<boolean>;

interface Props {
  buyAction: BuyActionProp;
  showPayButton?: boolean;
  onComplete?: () => void;
  onError?: () => void;
  onPaymentStart?: () => void;
  scopeKey?: string;
  subscriptionId: SubscriptionId;
  buyButtonText?: TranslationKey;
  legal?: ReactElement | string;
  errorMessage?: TranslationKey;
}

export const Payment: FC<Props> = ({
  buyAction,
  showPayButton = true,
  onComplete,
  onError,
  scopeKey,
  subscriptionId,
  buyButtonText,
  legal,
  onPaymentStart,
  errorMessage
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [numberError, setNumberError] = useState<string>("");
  const [phoneNumber, setPhoneNumberState] = useState<string>("");
  const [phoneNumberRequired, setPhoneNumberRequired] =
    useState<boolean>(false);

  const stripe = useStripe();
  const elements = useElements();

  const [profile, { loadData, setPhoneNumber }] = useBloc(ProfileCubit);
  const [{ error }, { purchase }] = useBloc(PaymentCubit);

  useEffect(() => {
    void loadData();
    tracker.track(TrackEvent.subReviewAndPayment, {
      type: TrackType.start
    });
  }, []);

  useEffect(() => {
    setPhoneNumberRequired(!profile.data.number);
  }, [profile.data.number]);

  const defaultPaymentMethod = useMemo(() => {
    return profile.data.paymentMethods?.find((method) => method.default);
  }, [profile]);

  const useDefaultPayment = defaultPaymentMethod && !isEditing;

  const handlePhoneChange = (event: CustomEvent<InputChangeDetails>): void => {
    const number = event.detail.value;
    setPhoneNumberState(number);

    setNumberError("");
  };

  const handleSubmit = async () => {
    onPaymentStart?.();

    if (phoneNumberRequired) {
      if (!phoneNumber || !validatePhoneNumber(phoneNumber, "US")) {
        setNumberError(translate("error.phone"));
        return;
      }
      await setPhoneNumber(phoneNumber);
    }
    await purchase(
      subscriptionId,
      elements,
      stripe,
      buyAction,
      useDefaultPayment,
      scopeKey,
      onComplete,
      onError
    );
  };

  const renderPaymentData = useMemo(() => {
    if (useDefaultPayment) {
      return (
        <TileWrapper>
          <div>
            <Align>
              <ItemFullWidth>
                <DisplayPaymentMethod
                  defaultPaymentMethod={defaultPaymentMethod}
                />
              </ItemFullWidth>
              {!isHybridApp() && (
                <Item px={0.4}>
                  <nine-button
                    arrow=""
                    variant="ghost"
                    onClick={(): void => setIsEditing(true)}
                  >
                    <EditIcon />
                  </nine-button>
                </Item>
              )}
            </Align>
          </div>
        </TileWrapper>
      );
    }

    return (
      <TileWrapper>
        {phoneNumberRequired && (
          <OnEvent
            events={{
              [NineInputWcl.customEvents.change]: handlePhoneChange
            }}
          >
            <InputWrapper>
              <NineInput
                label="Phone Number"
                placeholder="Phone Number"
                type="tel"
                mask="(000) 000-0000"
                lazy="false"
              />
            </InputWrapper>
          </OnEvent>
        )}
        {(error ?? numberError) && (
          <ErrorBox data-severity="error" style={{ marginBottom: "2rem" }}>
            {error ?? numberError}
          </ErrorBox>
        )}
        <PaymentElement
          options={{
            fields: {
              billingDetails: "never"
            },
            terms: {
              card: "never"
            }
          }}
        />
      </TileWrapper>
    );
  }, [useDefaultPayment, error, numberError, phoneNumberRequired]);

  return (
    <>
      <nine-info-section>
        <p slot="title" className="m0 strong as-tiny color-c-60">
          <Translate msg="payment" uppercase={true} />
        </p>

        <nine-info-container slot="content">
          <nine-spacer s="sm" />

          {renderPaymentData}

          {showPayButton && (
            <nine-center>
              <nine-button onClick={handleSubmit}>
                {buyButtonText ? (
                  <Translate msg={buyButtonText} />
                ) : (
                  <Translate msg="pay_now_button" />
                )}
              </nine-button>

              <nine-spacer s="md" />
            </nine-center>
          )}

          {errorMessage && (
            <>
              <ErrorBox data-severity="error">
                <Translate msg={errorMessage} fallback="error_generic" />
              </ErrorBox>
              <nine-spacer s="sm"></nine-spacer>
            </>
          )}
        </nine-info-container>

        {legal}
      </nine-info-section>
    </>
  );
};

export default Payment;
