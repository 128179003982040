import { Cubit } from "blac";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { loadingState } from "src/state/state";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { DeviceControllerService } from "@9amhealth/openapi";
import type { DeviceItem } from "@9amhealth/openapi";

interface DevicesState {
  devices: DeviceItem[];
}
export default class DevicesCubit extends Cubit<DevicesState> {
  constructor() {
    super({
      devices: []
    });
  }

  public readonly getUserDevices = async () => {
    loadingState.start(LoadingKey.devices);
    try {
      const response = await DeviceControllerService.findUserDevices();
      this.emit({
        ...this.state,
        devices: response.data
      });
    } catch (error) {
      reportErrorSentry(error);
    }
    loadingState.finish(LoadingKey.devices);
  };
}
