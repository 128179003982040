import { ChartTypeLineProps } from "molecule/chart/Chart";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {
  ChartBaseLine,
  ChartBaseLineChart,
  ChartBaseReferenceLine,
  ChartBaseTooltip,
  ChartBaseXAxis,
  ChartBaseYAxis,
  ChartLineProps
} from "./ChartBaseConfig";

export type ChartLine = {
  color: string;
  dataKey: string;
  props?: ChartLineProps;
};

function ChartTypeLine<T>({
  chartData,
  lines,
  tooltip,
  yAxis = {},
  xAxis = {},
  referenceLines = []
}: Omit<ChartTypeLineProps<T>, "type">) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={chartData} {...ChartBaseLineChart}>
        <CartesianGrid syncWithTicks={true} />

        <XAxis {...ChartBaseXAxis} {...xAxis} />
        <YAxis {...ChartBaseYAxis} {...yAxis} />
        {referenceLines.map((line, i) => (
          <ReferenceLine
            key={`reference-line-${i.toString()}`}
            {...ChartBaseReferenceLine}
            {...line}
          />
        ))}
        {tooltip && <Tooltip {...ChartBaseTooltip<T>(tooltip)} />}
        {lines.map((line) => (
          <Line
            key={line.dataKey}
            {...ChartBaseLine({ color: line.color })}
            dataKey={line.dataKey}
            {...line.props}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default ChartTypeLine;
