import type { FC } from "react";
import React from "react";
import type { FeatureFlagName } from "src/lib/featureFlags";
import { FeatureFlags } from "src/lib/featureFlags";
import { useBloc } from "src/state/state";

const DebugFeatureFlags: FC = () => {
  const [, { toggle, getFlag }] = useBloc(FeatureFlags);

  const keyList = Object.keys(FeatureFlags.initialFlags) as FeatureFlagName[];

  return (
    <div>
      <ul>
        {keyList.map((key) => {
          return (
            <li key={key}>
              {key}:{" "}
              <button onClick={() => toggle(key)}>
                {String(getFlag(key))}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DebugFeatureFlags;
