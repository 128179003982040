import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import type { FC, ReactNode } from "react";
import React, { useEffect, useMemo } from "react";
import envVariables from "src/lib/envVariables";
import getStripeUserLocale from "src/lib/getStripeUserLocale";
import PaymentCubit from "src/state/PaymentCubit/PaymentCubit";
import { BlocProvider, useBloc } from "src/state/state";
import Loader from "src/ui/components/Loader/Loader";

const stripePromise = loadStripe(envVariables.STRIPE_PUBLISHABLE_KEY, {
  stripeAccount: envVariables.STRIPE_ACCOUNT_ID
});

type ElementsProps = React.ComponentProps<typeof Elements>;
type AppearanceType = NonNullable<ElementsProps["options"]>;

const appearance: AppearanceType["appearance"] = {
  theme: "flat",
  labels: "floating",
  variables: {
    // fontFamily: ' "Gill Sans", sans-serif',
    fontLineHeight: "1.5",
    borderRadius: "6px",
    colorBackground: "#ffffff",
    colorPrimaryText: "#262626"
  },
  rules: {
    ".Block": {
      backgroundColor: "var(--colorBackground)",
      boxShadow: "none",
      padding: "12px"
    },
    ".Input": {
      padding: "8px 12px",
      border: "1px solid rgba(0, 0, 0, 0.2)"
    },
    ".Input:focus": {
      padding: "8px 12px",
      border: "1px solid #8DD9CD",
      boxShadow: "0 0 0 1px #8DD9CD"
    },
    ".Input:disabled, .Input--invalid:disabled": {
      color: "lightgray"
    },
    ".Tab": {
      padding: "10px 12px 8px 12px",
      border: "none"
    },
    ".Tab:hover": {
      border: "none",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)"
    },
    ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
      border: "none",
      backgroundColor: "#fff",
      boxShadow:
        "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)"
    },
    ".Label": {
      fontWeight: "500"
    }
  }
};

const PaymentContext: FC<{ children?: ReactNode | undefined }> = (props) => {
  const [{ clientSecret }, { initiatePaymentMethodCollection }] =
    useBloc(PaymentCubit);

  useEffect(() => {
    if (!clientSecret) {
      void initiatePaymentMethodCollection();
    }
  }, [clientSecret]);

  const stripeUserLocale = getStripeUserLocale();

  return !clientSecret ? (
    <Loader active fixed background />
  ) : (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        locale: stripeUserLocale,
        appearance
      }}
    >
      {props.children}
    </Elements>
  );
};

export default PaymentContext;

export const PaymentContextWithPaymentCubit = (props: {
  children?: ReactNode | undefined;
}): React.JSX.Element => {
  const paymentCubit = useMemo(() => new PaymentCubit(), []);
  return (
    <BlocProvider bloc={paymentCubit}>
      <PaymentContext>{props.children}</PaymentContext>
    </BlocProvider>
  );
};
