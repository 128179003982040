import { type Credentials } from "@capgo/capacitor-native-biometric";
import { biometricAuthState } from "src/state/state";
import type { ProviderCredentials } from "src/state/UserCubit/CredentialsProvider";
import CredentialsProvider from "src/state/UserCubit/CredentialsProvider";

export default class BiometricCredentialsProvider extends CredentialsProvider {
  credentials: ProviderCredentials | undefined;

  async isAvailable(): Promise<boolean> {
    if (!biometricAuthState.enabled) {
      return false;
    }
    const res = await biometricAuthState.isAvailable();
    return res.isAvailable;
  }

  isVerified(): boolean {
    return biometricAuthState.sessionVerified;
  }

  async isEnabled(): Promise<boolean> {
    await biometricAuthState.checkSupport();
    return biometricAuthState.state.isBiometricVerificationEnabled;
  }

  async getCredentials(): Promise<ProviderCredentials | undefined> {
    if (!biometricAuthState.state.isBiometricVerificationEnabled) return;
    if (!(await this.isAvailable())) return;

    const credentials = await biometricAuthState.getCredentials();
    if (!credentials) return;
    return credentials;
  }

  async setCredentials(credentials: ProviderCredentials): Promise<void> {
    if (!credentials.username || !credentials.password) {
      return;
    }

    const usernameIsSame = this.credentials?.username === credentials.username;
    const passwordIsSame = this.credentials?.password === credentials.password;
    if (usernameIsSame && passwordIsSame) return;

    this.credentials = credentials;

    const biometricCredentials: Credentials = {
      username: credentials.username,
      password: credentials.password
    };
    await biometricAuthState.setCredentials(biometricCredentials);
  }

  async deleteCredentials(): Promise<void> {
    this.credentials = undefined;
    await biometricAuthState.deleteCredentials();
  }
}
