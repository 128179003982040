import type { KeyboardInfo } from "@capacitor/keyboard";
import { Keyboard, KeyboardResize, KeyboardStyle } from "@capacitor/keyboard";
import { Cubit } from "blac";
import { isHybridApp, isIOS } from "src/lib/platform";

interface KeyboardState {
  keyboardHeight: number;
  isOpen: boolean;
}

export default class KeyboardBloc extends Cubit<KeyboardState> {
  constructor() {
    super({ keyboardHeight: 0, isOpen: false });
    this.setupListenersforNativeIOS();
    this.setupListenersforWebIos();
  }

  setupListenersforNativeIOS = (): void => {
    if (!isHybridApp() || !isIOS()) return;

    void Keyboard.addListener("keyboardWillShow", this.keyboardWillShow);
    void Keyboard.addListener("keyboardWillHide", this.keyboardWillHide);

    void Keyboard.addListener("keyboardDidShow", this.addPagePadding);

    // void Keyboard.setScroll({ isDisabled: true });
    void Keyboard.setResizeMode({ mode: KeyboardResize.None });
    void Keyboard.setStyle({ style: KeyboardStyle.Default });
  };

  lastHeight = 0;
  setupListenersforWebIos = (): void => {
    if (isHybridApp()) return;

    window.addEventListener("resize", this.updateWebKeyboardHeight);
    setInterval(() => {
      this.updateWebKeyboardHeight();
    }, 100);
  };

  get isAndroidKeyboard(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return /android/.test(ua);
  }

  updateWebKeyboardHeight = (): void => {
    if (isHybridApp()) return;
    const win = typeof window !== "undefined" ? window : undefined;
    if (!win) return;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const winH = Math.round(win?.innerHeight ?? 100);
    const vpH = Math.round(window.visualViewport?.height ?? winH);

    if (this.lastHeight === vpH) return;

    this.lastHeight = vpH;
    const virtualKeyboardHeight = winH - vpH;

    document.body.style.setProperty(
      "--keyboard-height",
      String(virtualKeyboardHeight) + "px"
    );
    this.emit({
      keyboardHeight: virtualKeyboardHeight,
      isOpen: winH !== vpH
    });

    // dont set the stored keyboard height on android
    if (this.isAndroidKeyboard) {
      return;
    }

    if (winH !== vpH) {
      document.body.style.setProperty(
        "--stored-keyboard-height",
        String(virtualKeyboardHeight) + "px"
      );
    }
  };

  addPagePadding = (): void => {
    // add a padding on the bottom of the body element
    // to prevent the content from being hidden by the keyboard
    // document.body.style.paddingBottom = `${this.state.keyboardHeight}px`;

    document.body.style.setProperty(
      "--keyboard-height",
      `${this.state.keyboardHeight}px`
    );

    if (this.state.keyboardHeight !== 0) {
      document.body.style.setProperty(
        "--stored-keyboard-height",
        `${this.state.keyboardHeight}px`
      );
    }

    setTimeout(() => {
      this.scrollInputIntoView();
    });
  };

  scrollInputIntoView = (): void => {
    // get the input element that is currently focused
    const focusedElement = document.activeElement as HTMLElement;
    const elementScreenPosition = focusedElement.getBoundingClientRect().top;
    const distanceFromKeyboard = window.innerHeight - elementScreenPosition;
    const isElementHiddenByKeyboard =
      distanceFromKeyboard - 50 < this.state.keyboardHeight;

    const amountToScroll =
      this.state.keyboardHeight -
      distanceFromKeyboard +
      window.innerHeight * 0.3;

    const closestScrollableParent =
      focusedElement.closest(".scrollable") ?? document.body;

    if (isElementHiddenByKeyboard) {
      closestScrollableParent.scrollTop += amountToScroll;
    }
  };

  removePagePadding = (): void => {
    // remove the padding on the bottom of the body element
    document.body.style.setProperty("--keyboard-height", "0px");
  };

  keyboardWillShow = (event: KeyboardInfo): void => {
    this.emit({ keyboardHeight: event.keyboardHeight, isOpen: true });
    this.addPagePadding();
  };

  keyboardWillHide = (): void => {
    this.emit({ keyboardHeight: 0, isOpen: false });
    this.removePagePadding();
  };
}
