import { PLATFORM, PLATFORM_IS_NATIVE } from "src/hybrid/components/Platform";

export function isPlatform(platform: typeof PLATFORM): boolean {
  return PLATFORM === platform;
}

export function isAndroid(): boolean {
  return isPlatform("android");
}

export function isIOS(): boolean {
  return isPlatform("ios");
}

export function isHybridApp(): boolean {
  return PLATFORM_IS_NATIVE && (isAndroid() || isIOS());
}
