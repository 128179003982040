import styled from "@emotion/styled";
import React from "react";
import {
  NameType,
  ValueType
} from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts/types/component/Tooltip";
import { ChartTooltipContentProps } from "./ChartBaseConfig";

const TooltipWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 0.125rem;
`;

const TooltipValue = styled.div`
  display: flex;
  padding: 0.25rem 0.5rem 0.1rem;
  justify-content: center;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0 0 0.125rem 0.125rem;
  background: #212121;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.02rem;
`;

const TooltipLabel = styled.div`
  color: rgba(33, 33, 33, 0.8);
  font-size: 0.75rem;
  background: white;
  border: 2px solid white;
  border-radius: 0.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.05rem */
  padding: 0.25rem 0.5rem 0.1rem;
`;

export type ChartTooltipContentComponentProps<T> = ChartTooltipContentProps<T> &
  TooltipProps<ValueType, NameType>;

export type ChartTooltipData<T> = {
  payload: T[];
};

function ChartTooltipContent<T>(props: ChartTooltipContentComponentProps<T>) {
  // don't show if there are no data points
  if (!props.payload || !props.payload.length) {
    return null;
  }

  // don't show if coordinates are outside of viewbox
  const x = props.coordinate?.x ?? 0;
  if (x < 0) {
    return null;
  }

  const payload = props.payload as { payload: T }[];
  const tooltipData = {
    payload: payload.map((p) => p.payload)
  } satisfies ChartTooltipData<T>;

  const label = props.formatLabel?.(tooltipData) ?? null;
  const value = props.formatValue?.(tooltipData) ?? null;

  return (
    <TooltipWrap className="chart-tooltip">
      {label && (
        <TooltipLabel className="chart-tooltip-label">{label}</TooltipLabel>
      )}
      <TooltipValue className="chart-tooltip-value">{value}</TooltipValue>
    </TooltipWrap>
  );
}

export default ChartTooltipContent;
