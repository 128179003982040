import type { FC, ReactElement, ReactNode } from "react";
import React, { useEffect, useMemo } from "react";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import useUserAuthStage from "src/state/hooks/useUserAuthStage";
import {
  BlocProvider,
  authenticationState,
  useBloc,
  userPreferences
} from "src/state/state";
import LoginOverlay from "src/ui/components/LoginOverlay/LoginOverlay";
import Loader from "../Loader/Loader";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";

const WithAuth: FC<{
  redirectIfNoToken?: boolean;
  loadProfile?: boolean;
  createAnonymousUser?: boolean;
  children?: ReactNode | undefined;
  allowPartialAuth?: boolean;
  allowAnonymous?: boolean;
}> = (props): ReactElement => {
  const profileBloc = useMemo(() => new ProfileCubit(), []);
  const [, { hasActiveSubscription }] = useBloc(SubscriptionCubit);
  const authStage = useUserAuthStage();

  const allowAccess = useMemo(() => {
    if (authStage === "fullAuth") {
      return true;
    }
    if (
      authStage === "anonymous" &&
      (props.createAnonymousUser || props.allowAnonymous)
    ) {
      return true;
    }
    if (
      authStage === "partialAuth" &&
      (props.allowPartialAuth || hasActiveSubscription)
    ) {
      return true;
    }
    return false;
  }, [authStage, props.redirectIfNoToken, props.allowPartialAuth]);

  useEffect(() => {
    if (props.createAnonymousUser && !allowAccess) {
      void authenticationState.createAnonymousAccount();
    }
  }, [authStage, props.createAnonymousUser, allowAccess]);

  // if user is logged in, set state and show children
  if (allowAccess) {
    return (
      <BlocProvider bloc={userPreferences}>
        <BlocProvider bloc={profileBloc}>
          <>{props.children}</>
        </BlocProvider>
      </BlocProvider>
    );
  }

  if (props.createAnonymousUser && authStage === "noAuth") {
    return <Loader gradient active fixed />;
  }

  return <LoginOverlay />;
};

export default WithAuth;
