import {
  CareControllerService,
  FeatureResponse,
  SubscriptionDetailsResponse
} from "@9amhealth/openapi";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Cubit } from "blac";
import React, { FC, useEffect, useState } from "react";
import { Button } from "react-aria-components";
import { useNavigate } from "react-router-dom";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import UserCubit from "src/state/UserCubit/UserCubit";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { subscriptionState, tracker, useBloc } from "src/state/state";
import InDialog from "../InDialog/InDialog";
import Translate from "../Translate/Translate";
import loadingImage from "./spinner.png";
import teamImage from "./team.png";
import { PayerId } from "src/state/SubscriptionCubit/SubscriptionCubit";
import { Blac } from "blac-next";
import { AppRemoteConfigCubit } from "src/state/AppRemoteConfigCubit/AppRemoteConfigCubit";

const DialogIframe = styled.iframe`
  outline: none;
  border: none;
  width: 100%;
  min-height: 400px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  margin: 0;
  padding-bottom: 0 !important;
`;

const TeamImage = styled.img`
  width: min(80%, 190px);
  margin-bottom: 0.5rem;
`;

const Centered = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  --bottom-btn-height: 3.125rem;
  padding-bottom: var(--bottom-btn-height);
  margin-top: -20px;
`;

const spinFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Timer = styled.div`
  font-size: 1rem;
  color: var(--color-text-secondary);
  margin-top: 1rem 0;
  font-weight: 500;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    margin-right: 0.5rem;
    animation: ${spinFrames} 1s linear infinite;
  }
`;

const BottomButton = styled(Button)`
  height: var(--bottom-btn-height);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0;
  background: none;
  border-top: 1px solid var(--Greys-Light-Gray, #e6e3db);
  background: var(--Greys-Fog, #f4f4f4);

  span {
    text-transform: none;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
  }

  &:hover {
    box-shadow: var(--light-shadow);
  }
`;

export type CalendlyEventDetails =
  | {
      event: "calendly.page_height";
      payload: {
        height: `${number}px`;
      };
    }
  | {
      event: "calendly.date_and_time_selected" | "calendly.event_type_viewed";
      payload: null;
    }
  | {
      event: "calendly.event_scheduled";
      payload: {
        event: {
          uri: string;
        };
        invitee: {
          uri: string;
        };
      };
    };

interface CallbackRequestState {
  callEta?: string;
  status:
    | "prompt"
    | "requested"
    | "schedule"
    | "schedule-completed"
    | "canceled";
  hideDialog?: boolean;
  // null means we haven't checked yet
  callbackEnabled: boolean | null;
  disabledReason?: string;
  callbackRequestedTime?: number;
  showLongWaitingTime?: boolean;
}

export class CallbackBloc extends Cubit<CallbackRequestState> {
  constructor(runInit = true) {
    super({
      status: "prompt",
      callbackEnabled: null
    });
    if (runInit) {
      this.recoverCachedData();
      this.checkShouldShowLongWaitingTime();
      void this.checkCallbackEnabled();
    }
  }

  lsKey = "callbackRequest";

  log = (message: string, etc?: unknown): void => {
    if (this.verboseLogging) {
      // eslint-disable-next-line no-console
      console.warn(`[CALLBACK]: ${message}`, etc ?? "");
    }
    addSentryBreadcrumb("callback", message, "info", etc ?? undefined);
  };
  verboseLogging = false;

  recoverCachedData = () => {
    const cached = StorageController.getItem(this.lsKey);
    if (cached) {
      try {
        const data = JSON.parse(cached) as CallbackRequestState;
        // dont cache callbackEnabled
        data.callbackEnabled = null;
        data.showLongWaitingTime = undefined;

        // hide dialog if we've already scheduled
        if (data.status === "schedule-completed") {
          data.hideDialog = true;
        }
        //
        // add calendly listener the user wants to schedule
        if (data.status === "schedule") {
          this.calendlyListenerAdded = true;
          window.addEventListener("message", this.calendlyListener);
        }

        this.emit(data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  checkCallbackEnabled = async () => {
    const [isInRange] = await Promise.all([
      Blac.getBloc(AppRemoteConfigCubit).checkCallbackAvailable(),
      featureFlags.loadBackendFlags()
    ]);
    let callbackEnabled = isInRange;
    let disabledReason = isInRange ? undefined : "not_in_range";

    // if time is in range, check if feature flag is enabled
    if (isInRange) {
      callbackEnabled = featureFlags.getFlag(
        FeatureResponse.feature.ONBOARDING_REQUEST_CALLBACK
      );
      if (!callbackEnabled) {
        disabledReason = "feature_disabled";
      }
    }

    // check if user has a cash-pay subscription
    if (callbackEnabled) {
      const cashPaySubscription = subscriptionState.filterAllSubscriptions({
        status: [
          SubscriptionDetailsResponse.status.ACTIVE,
          SubscriptionDetailsResponse.status.IN_REVIEW
        ],
        metadataPayerId: PayerId.CASH_PAY
      });

      if (cashPaySubscription.length > 0) {
        callbackEnabled = false;
        disabledReason = "cash_pay_subscription";
      }
    }

    this.patch({ callbackEnabled, disabledReason });
  };

  handleScheduleCancel = () => {
    if (this.state.status === "schedule") {
      tracker.track("Callback Request Schedule Dialog Closed");
      this.patch({ status: "canceled" });
    }
  };

  handleRequestCancel = () => {
    if (this.state.status === "requested") {
      tracker.track("Callback Request Call-Now Dialog Closed");
      this.patch({ status: "canceled" });
    }
  };

  closeAllDialogs = () => {
    this.calendlyListenerAdded = false;
    window.removeEventListener("message", this.calendlyListener);

    this.patch({ hideDialog: true });
  };

  longWaitTimeLimit = 1000 * 60 * 5; // 5 minutes
  longWaitTimeTimeout: NodeJS.Timeout | undefined = undefined;
  checkShouldShowLongWaitingTime = () => {
    if (this.state.status !== "requested") {
      this.patch({ showLongWaitingTime: false });
      return;
    }

    const now = new Date();
    const { callbackRequestedTime } = this.state;
    if (callbackRequestedTime) {
      const timeDiff = now.getTime() - callbackRequestedTime;
      const timeToShowLongWaitingTime = this.longWaitTimeLimit - timeDiff;
      if (timeDiff >= this.longWaitTimeLimit) {
        this.patch({ showLongWaitingTime: true });
        tracker.track("Callback Request Show Long Waiting Time");
      } else if (!this.longWaitTimeTimeout) {
        this.patch({ showLongWaitingTime: false });
        this.longWaitTimeTimeout = setTimeout(() => {
          this.checkShouldShowLongWaitingTime();
          this.longWaitTimeTimeout = undefined;
        }, timeToShowLongWaitingTime);
      }
    }
  };

  requestCallback = () => {
    if (this.state.status === "requested") {
      return;
    }

    this.patch({
      status: "requested",
      callbackRequestedTime: Date.now()
    });

    tracker.track("Callback Request Call-Now Requested");
    this.checkShouldShowLongWaitingTime();

    CareControllerService.triggerOnboardingCallback()
      .then(() => {
        addSentryBreadcrumb("callback", "Trigger Onboarding Callback");
      })
      .catch((e: unknown) => {
        reportErrorSentry(
          new Error("Error triggering onboarding callback", {
            cause: e
          })
        );
      });
  };

  scheduleAppointment = () => {
    if (this.state.status === "requested") {
      CareControllerService.cancelOnboardingCallback()
        .then(() => {
          addSentryBreadcrumb("callback", "Cancel Onboarding Callback");
        })
        .catch((e: unknown) => {
          reportErrorSentry(
            new Error("Error canceling onboarding callback", {
              cause: e
            })
          );
        });
    }

    tracker.track("Callback Request Schedule Started");

    if (!this.calendlyListenerAdded) {
      this.calendlyListenerAdded = true;
      window.addEventListener("message", this.calendlyListener);
    }

    this.patch({ status: "schedule" });
  };
  calendlyListenerAdded = false;

  calendlyListener = (event: MessageEvent<CalendlyEventDetails>) => {
    if (event.origin !== "https://calendly.com") {
      return;
    }
    const { data } = event;
    const iframe = document.getElementById("calendly-iframe");
    switch (data.event) {
      case "calendly.event_scheduled":
        this.patch({ status: "schedule-completed" });
        tracker.track("Callback Request [Calendly] Schedule Completed", {
          data: {
            inviteeApi: data.payload.invitee.uri,
            eventApi: data.payload.event.uri
          }
        });
        iframe?.scrollIntoView();
        break;
      case "calendly.event_type_viewed":
        tracker.track("Callback Request [Calendly] Event Type Viewed");
        iframe?.scrollIntoView();
        break;
      case "calendly.date_and_time_selected":
        tracker.track("Callback Request [Calendly] Date and Time Selected");
        iframe?.scrollIntoView();
        break;
      case "calendly.page_height":
        if (iframe) {
          iframe.style.height = data.payload.height;
        }
        break;
    }
  };

  createCalendlyEmbedUrl = async (data: {
    email: string;
    name: string;
  }): Promise<string> => {
    const currentDomain = window.location.hostname;
    const remoteConfigUrl =
      await Blac.getBloc(AppRemoteConfigCubit).checkOnboardingCallCalendlyUrl();
    const url = new URL(
      remoteConfigUrl ?? "https://calendly.com/d/cfz-z8v-rgp/9am-intro-call"
    );
    url.searchParams.set("embed_domain", currentDomain);
    url.searchParams.set("embed_type", "Inline");
    url.searchParams.append("email", data.email);
    url.searchParams.append("name", data.name);

    return url.toString();
  };

  // TODO: Remove when upgrading to blac v1
  patch = (state: Partial<CallbackRequestState>) => {
    const newState = { ...this.state, ...state };
    this.emit(newState);
    StorageController.setItem(this.lsKey, JSON.stringify(newState));
  };
}

const CallbackRequest: FC<{ view: "success-page" | "dialog" }> = ({ view }) => {
  const [
    state,
    {
      requestCallback,
      scheduleAppointment,
      handleScheduleCancel,
      handleRequestCancel,
      calendlyListener,
      closeAllDialogs,
      createCalendlyEmbedUrl
    }
  ] = useBloc(CallbackBloc, {
    create: () => new CallbackBloc()
  });
  const {
    status,
    hideDialog,
    callbackEnabled,
    disabledReason,
    showLongWaitingTime = false
  } = state;
  const [{ userData }] = useBloc(UserCubit);
  const [, { displayName }] = useBloc(UserPreferencesCubit);
  const navigate = useNavigate();
  const [calendlyUrl, setCalendlyUrl] = useState<string>("");

  useEffect(() => {
    createCalendlyEmbedUrl({
      email: userData?.email ?? "",
      name: displayName ?? ""
    })
      .then(setCalendlyUrl)
      .catch(() => {});
  }, [userData, displayName, view]);

  const goToApp = () => {
    navigate("/app/home");
  };

  // useEffect mount/unmount
  useEffect(() => {
    return () => {
      // remove calendly listener when we leave the page
      window.removeEventListener("message", calendlyListener);
    };
  }, []);

  // useEffect on state change, for tracking success page view
  useEffect(() => {
    if (view === "success-page" && callbackEnabled !== null) {
      tracker.track("Callback Request Success Page Presented", {
        data: {
          "Callback Request Enabled": callbackEnabled,
          "Callback Request Disabled Reason": !callbackEnabled
            ? disabledReason
            : undefined
        }
      });
    }
  }, [view, callbackEnabled, disabledReason]);

  if (callbackEnabled === null) {
    return null;
  }

  if (view === "success-page") {
    return (
      <>
        <p>
          <Translate msg="task.onboardingCall.description" />
        </p>
        {callbackEnabled ? (
          <>
            <Button
              onPress={() => {
                requestCallback();
                goToApp();
              }}
            >
              <Translate msg="task.onboardingCall.callNow" />
            </Button>
            <nine-spacer s="xs"></nine-spacer>
            <p>
              <a
                href="#"
                className="as-caption"
                style={{ textDecoration: "none" }}
                onClick={(e) => {
                  e.preventDefault();
                  scheduleAppointment();
                  goToApp();
                }}
              >
                <Translate msg="task.onboardingCall.schedule" />
              </a>
            </p>
          </>
        ) : (
          <>
            <Button
              onPress={() => {
                scheduleAppointment();
                goToApp();
              }}
              className="button-normal-case"
            >
              <Translate msg="task.onboardingCall.scheduleTitle" />
            </Button>
          </>
        )}
      </>
    );
  }

  const showRequestDialog = !hideDialog && status === "requested";
  const showScheduleDialog =
    !hideDialog && (status === "schedule" || status === "schedule-completed");

  if (showRequestDialog) {
    return (
      <InDialog
        simple
        height={showLongWaitingTime ? "440px" : "400px"}
        backdropDismiss={false}
        onWillDismiss={handleRequestCancel}
        alwaysModal
        title={translate("task.onboardingCall")}
        key="request"
      >
        <Centered>
          <div>
            <TeamImage src={teamImage} alt="Team" />
            <h4>
              <Translate msg="task.onboardingCall" />
            </h4>
            <p>
              <Translate msg="task.onboardingCall.waitForCall" />
            </p>
            {showLongWaitingTime ? (
              <Timer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.2rem",
                    flexDirection: "column",
                    maxWidth: "min(100%, 320px)"
                  }}
                >
                  <p className="as-strong">
                    <Translate msg="task.onboardingCall.longWaitingTime" />
                  </p>
                  <img src={loadingImage} alt="Waiting for call" />
                </div>
              </Timer>
            ) : (
              <Timer>
                <img src={loadingImage} alt="Waiting for call" />
                ETA 1-4min
              </Timer>
            )}
          </div>
          <BottomButton onPress={scheduleAppointment}>
            <Translate msg="task.onboardingCall.schedule" />
          </BottomButton>
        </Centered>
      </InDialog>
    );
  }

  if (showScheduleDialog) {
    return (
      <InDialog
        key="schedule"
        width="min(calc(100% - 2rem), 1000px)"
        height="min(calc(100% - 2rem), 900px)"
        simple
        onWillDismiss={handleScheduleCancel}
        backdropDismiss={false}
        title={translate("task.onboardingCall.scheduleTitle")}
      >
        <div>
          <DialogIframe src={calendlyUrl} scrolling="no" id="calendly-iframe" />
          {status === "schedule-completed" && (
            <Centered style={{ marginTop: "2rem" }}>
              <Button onPress={closeAllDialogs}>
                <Translate msg="open_app" />
              </Button>
            </Centered>
          )}
        </div>
      </InDialog>
    );
  }
};

export default CallbackRequest;
