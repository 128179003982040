import type { PharmacyInsuranceResponse } from "@9amhealth/openapi";
import {
  PharmacyBenefitsControllerService,
  PharmacyInsuranceRequest
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import reportErrorSentry from "src/lib/reportErrorSentry";
import primaryPlanOwner = PharmacyInsuranceRequest.primaryPlanOwner;
import type { TranslationKey } from "src/types/translationKey";

export enum CardDetailsFormKey {
  rxBin = "rxBin",
  rxGrp = "rxGrp",
  cardHolderId = "cardHolderId",
  rxPcn = "rxPcn"
}

export enum AddInsuranceDocuments {
  FRONT = "frontPhotoFileId",
  BACK = "backPhotoFileId"
}

export type CardDetailsFormValues = Record<CardDetailsFormKey, string>;
export type CardDetailsFormFiles = Record<AddInsuranceDocuments, string>;

interface PatientInsuranceState {
  pharmacyInsurances?: PharmacyInsuranceResponse[];
}
export default class PatientInsuranceBloc extends Cubit<PatientInsuranceState> {
  addInsuranceFormFields: {
    name: CardDetailsFormKey;
    label: TranslationKey;
  }[] = [
    { name: CardDetailsFormKey.rxBin, label: "insuranceNumber.label_rxBinIin" },
    { name: CardDetailsFormKey.rxGrp, label: "insuranceNumber.label_rxGroup" },
    { name: CardDetailsFormKey.cardHolderId, label: "cardholderId" },
    { name: CardDetailsFormKey.rxPcn, label: "insuranceNumber.label_rxPcn" }
  ];

  temporaryCardDetailFormValues: { current: CardDetailsFormValues | null } = {
    current: null
  };
  temporaryCardDetailFiles: { current: CardDetailsFormFiles | null } = {
    current: null
  };

  constructor() {
    super({});
  }

  /**
   * Load the user's insurances from the API
   */
  public loadInsurances = async () => {
    try {
      const response =
        await PharmacyBenefitsControllerService.fetchPharmacyBenefitsByUser();
      const insurances = response.data.pharmacyInsurances ?? [];

      // sort, primary first, then alphabetically
      insurances.sort((a, b) => {
        if (a.isPrimary && !b.isPrimary) {
          return -1;
        }
        if (!a.isPrimary && b.isPrimary) {
          return 1;
        }
        return a.cardHolderId.localeCompare(b.cardHolderId);
      });

      this.emit({
        ...this.state,
        pharmacyInsurances: insurances
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  /**
   * Add a new card to the user's account, updates the state with the new card
   * @param fields
   * @param files
   */
  public savePharmacyInsurance = async (
    fields: CardDetailsFormValues,
    files: CardDetailsFormFiles
  ) => {
    const pharmacyInsuranceRequest: PharmacyInsuranceRequest = {
      cardHolderId: fields[CardDetailsFormKey.cardHolderId],
      rxBin: fields[CardDetailsFormKey.rxBin],
      rxGrp: fields[CardDetailsFormKey.rxGrp],
      rxPcn: fields[CardDetailsFormKey.rxPcn],
      frontPhotoFileId: files[AddInsuranceDocuments.FRONT],
      backPhotoFileId: files[AddInsuranceDocuments.BACK],
      status: PharmacyInsuranceRequest.status.ACTIVE,
      isPrimary: true,
      primaryPlanOwner: primaryPlanOwner.SELF,
      payer: undefined
    };

    const dontCheckFields: (keyof typeof pharmacyInsuranceRequest)[] = [
      "status",
      "isPrimary",
      "primaryPlanOwner",
      "payer"
    ];

    // verify that all fields are filled out, check if any fields in pharmacyInsuranceRequest are missing
    const missingFields = Object.entries(pharmacyInsuranceRequest).filter(
      ([key, value]) =>
        !dontCheckFields.includes(
          key as keyof typeof pharmacyInsuranceRequest
        ) && !value
    );

    if (missingFields.length > 0) {
      throw new Error(
        `Missing fields: ${missingFields.map(([key]) => key).join(", ")}`
      );
    }

    try {
      const currentPharmacyInsurances = this.state.pharmacyInsurances ?? [];

      const newListPharmacyInsurances: PharmacyInsuranceRequest[] = [
        ...currentPharmacyInsurances
      ];

      // if there is a current insurance with the same cardholder id, update it.
      // otherwise, add a new one

      const existingInsuranceIndex = newListPharmacyInsurances.findIndex(
        (insurance) =>
          insurance.cardHolderId === pharmacyInsuranceRequest.cardHolderId
      );

      if (existingInsuranceIndex > -1) {
        newListPharmacyInsurances[existingInsuranceIndex] = {
          ...newListPharmacyInsurances[existingInsuranceIndex],
          ...pharmacyInsuranceRequest
        };
      } else {
        newListPharmacyInsurances.push(pharmacyInsuranceRequest);
      }

      //   set only new/updated insurance to 'isPrimary=true'

      newListPharmacyInsurances.forEach((insurance) => {
        insurance.isPrimary =
          insurance.cardHolderId === pharmacyInsuranceRequest.cardHolderId;
      });

      await PharmacyBenefitsControllerService.createOrUpdateBenefitsByUser({
        pharmacyInsurances: newListPharmacyInsurances
      });
    } catch (error) {
      reportErrorSentry(error);
      throw error;
    }
  };
}
