import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import type { SanityImageAssetDocument } from "@sanity/client";
import clsx from "clsx";
import type { FC, ReactNode } from "react";
import React, { useEffect, useMemo, useRef } from "react";
import {
  IconLock,
  IconPlayArrow,
  SpecialButtonPlay
} from "src/constants/icons";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { useBloc } from "src/state/state";
import VideoPlayerCubit, {
  VideoData
} from "src/state/VideoPlayerCubit/VideoPlayerCubit";
import ResponsiveImage from "src/ui/components/ResponsiveImage/ResponsiveImage";
import { VideoPlayerOptions } from "../FullVideoPlayer/FullVideoPlayerBloc";
import useVideoInline from "../FullVideoPlayer/useVideoInline";
import useVideoPopup from "../FullVideoPlayer/useVideoPopup";

const Player = styled.div`
  label: MediaPlayer;
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  font-size: var(--font-size);
  z-index: 1;

  &[data-status="locked"] {
    pointer-events: none;
  }

  &[data-fit="true"] {
    aspect-ratio: unset;
    width: 100%;
    height: 100%;
  }

  video-js {
    background-color: transparent !important;
  }
`;

const FadeInKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Thumb = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: var(--color-cream);

  .responsive-image {
    border-radius: 0;
  }

  /* dark cover */
  &:after {
    position: absolute;
    inset: 0;
    background-color: #212121;
    opacity: 0.1;
    z-index: 20;
    content: "";
    display: block;
  }

  *[data-status="locked"] & {
    filter: grayscale(40%);
    opacity: 0.9;
  }

  &[data-fade="in"] {
    animation: ${FadeInKeyframes} 0.5s ease-in-out;
  }
`;

const ThumbBaseImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  object-position: center var(--img-y);
  inset: 0;
  opacity: 0.6;
  transform: scale(1.05);
`;

const Ui = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  color: white;
  background: #212121bb;
`;

const Track = styled.div`
  width: 100%;
  height: 1px;
  background: white;
  opacity: 0.5;
  margin: 0 0 0.75rem;
`;

const Controls = styled.div`
  padding: 0 1em 0.75em;
`;

const Title = styled.div`
  font-weight: 500;
  letter-spacing: -0.02rem;
`;

const MainStatusControl = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
  cursor: pointer;
  color: white;
  display: grid;
  place-items: center;

  transform: scale(1);
  transition: transform var(--ease-time) var(--ease-type);

  &:hover {
    transform: scale(1.2);
  }

  svg {
    width: auto;
    height: min(44%, 30vw);
    margin-bottom: 2em;
  }

  .icon-lock {
    height: 3em;
  }
`;

const EmbedTarget = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 90;
  background-color: var(--video-backdrop, var(--color-charcoal)) !important;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--ease-time) var(--ease-type);

  iframe,
  video-js {
    /* fill */
    width: 100%;
    height: 100%;
  }

  &[data-playing="true"] {
    opacity: 1;
    pointer-events: all;
  }
`;

const SimplePlay = styled.div`
  svg {
    color: white;
    width: 80px;
    height: 80px;
    margin: 0;
  }
`;

const MediaPlayer: FC<{
  thumb?: SanityImageAssetDocument | string;
  title?: ReactNode | string;
  imgY?: number;
  status?: "locked" | "ready";
  videoId?: number | string;
  videoProvider?: string;
  inline?: boolean;
  onPlay?: () => void;
  options?: VideoPlayerOptions;
  initPlayer?: boolean;
  onVideoEnd?: () => void;
  onVideoStart?: () => void;
  callVideoEndSecondsBeforeFinish?: number;
  playIcon?: "simple" | "special";
  fitToContainer?: boolean;
  /**
   * Control the playing state of the video,
   * when using this the options.autoplay will be ignored
   **/
  playing?: boolean;
}> = (props) => {
  const inline = props.inline ?? false;
  const [
    allVideoData,
    { addVideoData, isVideoWatched, getVideoSource, markVideoEnded }
  ] = useBloc(VideoPlayerCubit);
  const embedRef = useRef<HTMLDivElement>(null);
  const { imgY = 0.5, status = "ready" } = props;
  const responsiveImage = typeof props.thumb === "object" ? props.thumb : null;
  const eventsCounter = useRef({
    play: 0,
    ended: 0
  });
  const videoData = useMemo<VideoData | undefined>(() => {
    const id = props.videoId ?? "";
    return allVideoData[id];
  }, [allVideoData]);
  const thumbBasicUrl = props.thumb ?? videoData?.thumb ?? null;
  const playBtnType = props.playIcon ?? "special";

  const title = props.title ?? videoData?.title ?? "";

  useEffect(() => {
    if (props.videoId) {
      addVideoData(props.videoId);
    }
  }, [props.videoId]);

  const watched = isVideoWatched(String(props.videoId));

  useEffect(() => {
    if (watched) {
      setTimeout(() => {
        embedRef.current?.dispatchEvent(
          new CustomEvent("videoEnded", { bubbles: true })
        );
      });
    }
  }, [watched]);

  useEffect(() => {
    setTimeout(() => {
      embedRef.current?.dispatchEvent(
        new CustomEvent("videoInitialized", { bubbles: true })
      );
    });
  }, [embedRef.current]);

  const [initPlayerInline, player] = useVideoInline();
  const [initPlayerPopup] = useVideoPopup();

  useEffect(() => {
    if (player && embedRef.current) {
      player.on("play", () => {
        if (eventsCounter.current.play === 0) {
          props.onVideoStart?.();
        }
        eventsCounter.current.play++;
      });

      player.on("ended", () => {
        if (eventsCounter.current.ended === 0) {
          props.onVideoEnd?.();
          markVideoEnded(String(props.videoId));
        }
        eventsCounter.current.ended++;
      });

      player.on("timeupdate", () => {
        const seconds = Math.round(player.currentTime() ?? 0);
        const duration = Math.round(player.duration() ?? 0);
        if (props.callVideoEndSecondsBeforeFinish) {
          if (duration - seconds <= props.callVideoEndSecondsBeforeFinish) {
            if (eventsCounter.current.ended === 0) {
              props.onVideoEnd?.();
              markVideoEnded(String(props.videoId));
            }
            eventsCounter.current.ended++;
          }
        }
      });

      if (props.playing || props.options?.autoplay) {
        playVideo();
      }
    }
  }, [player, embedRef.current]);

  const setupPlayer = (): void => {
    const target = embedRef.current;
    if (status !== "ready" || !target || !props.videoId) {
      return;
    }

    if (player) {
      return;
    }

    getVideoSource(props.videoId)
      .then((sources) => {
        initPlayerInline({
          target,
          poster: typeof thumbBasicUrl === "string" ? thumbBasicUrl : undefined,
          sources,
          ...props.options,
          autoplay: props.playing || props.options?.autoplay
        });
      })
      .catch(reportErrorSentry);
  };

  const playVideo = (): void => {
    if (inline) {
      const target = embedRef.current;

      if (!target || !player) {
        return;
      }

      void player.play();
      target.dataset.playing = "true";
    } else {
      if (!props.videoId) {
        return;
      }

      getVideoSource(props.videoId)
        .then((sources) => {
          if (sources.length === 0) {
            return;
          }
          initPlayerPopup({
            autoplay: true,
            ratio:
              videoData?.videoWidth && videoData.videoHeight
                ? videoData.videoWidth / videoData.videoHeight
                : 16 / 9,
            poster:
              typeof thumbBasicUrl === "string" ? thumbBasicUrl : undefined,
            sources
          });
        })
        .catch(reportErrorSentry);
    }
  };

  const pauseVideo = (): void => {
    if (player) {
      player.pause();
    }
  };

  const handleVideoPlay = (): void => {
    const target = embedRef.current;
    if (status !== "ready" || !target || !props.videoId) {
      return;
    }
    if (props.onPlay) {
      props.onPlay();
      return;
    }
    if (target.dataset.playing !== "true") {
      playVideo();
    }
  };

  useEffect(() => {
    if (inline && props.initPlayer !== false) {
      setupPlayer();
    }
  }, [props.initPlayer, inline]);

  useEffect(() => {
    if (props.playing === true) {
      playVideo();
    } else if (props.playing === false) {
      pauseVideo();
    }
  }, [props.playing]);

  return (
    <Player
      data-status={status}
      onClick={handleVideoPlay}
      className={clsx("media-player")}
      data-testid="video-player"
      data-fit={props.fitToContainer ? "true" : "false"}
    >
      <EmbedTarget ref={embedRef} data-playing="false" />
      <Thumb>
        {typeof thumbBasicUrl === "string" && (
          <ThumbBaseImage src={thumbBasicUrl} />
        )}
        {responsiveImage && (
          <ResponsiveImage image={responsiveImage} imgY={imgY} />
        )}
      </Thumb>
      <Ui>
        {title && (
          <>
            <Track />
            <Controls>
              <Title>{title}</Title>
            </Controls>
          </>
        )}
      </Ui>
      <MainStatusControl>
        {status === "ready" && (
          <>
            {playBtnType === "special" && <SpecialButtonPlay />}
            {playBtnType === "simple" && (
              <SimplePlay>
                <IconPlayArrow />
              </SimplePlay>
            )}
          </>
        )}
        {status === "locked" && <IconLock />}
      </MainStatusControl>
    </Player>
  );
};

export default MediaPlayer;
