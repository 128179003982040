import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppPopup, AppQueryPopupsController } from "./AppQueryPopupsBloc";

export const PathnameToPopupMap: Record<string, AppPopup | undefined> = {
  "/app/chat/action/upload-blood-pressure": AppPopup.enterBloodPressure,
  "/app/chat/action/upload-waist-circumference":
    AppPopup.enterWaistCircumference,
  "/app/chat/action/upload-weight": AppPopup.enterWeight,
  "/app/chat/action/upload-lab-report": AppPopup.uploadLabReport,
  "/app/chat/action/upload-blood-glucose": AppPopup.enterBloodGlucose
};

const AppQueryPopupsRedirects = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const popup = PathnameToPopupMap[pathname];

    if (popup) {
      AppQueryPopupsController.openPopup(popup, { replace: true });
      // eslint-disable-next-line no-console
      console.warn(
        `Redirecting to popup, please link to "?popup=${popup}" instead`,
        popup
      );
    }
  }, [pathname]);

  return null;
};

export default AppQueryPopupsRedirects;
