import React from "react";
import type { VideoPlayerOptions } from "./FullVideoPlayerBloc";
import { useBloc } from "src/state/state";
import FullVideoPlayerBloc from "./FullVideoPlayerBloc";
import { VideoPlayerLibInterface } from "src/state/VideoPlayerCubit/VideoPlayerCubit";

type UseVideoResponse = [
  (
    videoOptions: VideoPlayerOptions & {
      target: HTMLDivElement | null;
    }
  ) => void,
  VideoPlayerLibInterface | undefined
];

const useVideoInline = (): UseVideoResponse => {
  const [{ player }, { initVideo, disposePlayer }] = useBloc(
    FullVideoPlayerBloc,
    {
      create: () => new FullVideoPlayerBloc()
    }
  );

  const initVideoHookFn = (
    videoOptions: VideoPlayerOptions & {
      target: HTMLDivElement | null;
    }
  ): void => {
    initVideo(videoOptions.target, {
      ...videoOptions
    });
  };

  React.useEffect(() => {
    return () => {
      disposePlayer();
    };
  }, []);

  return [initVideoHookFn, player];
};

export default useVideoInline;
