import {
  CancelablePromise,
  SuccessCollectionTaskResponse,
  TaskControllerService,
  TaskResponse,
  UpdateTaskRequest
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import { globalEvents } from "src/constants/globalEvents";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import type { WebsocketTaskStatusUpdatedPayload } from "../WebSocketBloc/WebSocketBloc";
import {
  WebsocketLifecycleEvent,
  WebsocketMessageType
} from "../WebSocketBloc/WebSocketBloc";
import { websocketState } from "../state";

export type AppTaskDetailsType = "questionnaire" | "video";

export interface AppTaskDetails {
  startedDate?: Date;
  completedDate?: Date;
  availableDate?: Date;
  availableDateEnd?: Date;
  startsInFuture?: boolean;
  timeTillAvailable?: Date;
  timeTillAvailableEnd?: Date;
  videoId?: number;
  videoProvider?: "vimeo" | "youtube";
  questionnaireId?: string;
  type?: AppTaskDetailsType;
}

export type TaskObserverCallback = (
  programs: Map<KnownProgram, TaskResponseKnown[]>
) => void;

export enum TaskObserverEvent {
  TASK_LIST_CHANGED = "task-list-changed"
}

/**
 * TaskKey is the key used to identify a task in the TaskManagementBloc.
 * it is used for creating configurations for tasks.
 * the key can be any string, but its recommended to follow the convention:
 * [program].[group].[slug]
 * or [group].[slug]
 * or [slug]
 *
 * the configs will be checked in the order described above. with the most specific config being used.
 */
export enum TaskKey {
  LOG_WEIGHT = "log-weight",
  UPLOAD_LABS = "upload_lab_report",
  SELECT_PCP = "select-pcp",
  PHARMACY_INSURANCE = "pharmacy-insurance",
  MEDICAL_INSURANCE = "medical-insurance",
  PREFERRED_PHARMACY = "preferred-pharmacy",
  SCHEDULE_GETLABS = "schedule-labs",
  CKECKIN_QUESTIONNAIRE = "checkin-questionnaire",
  RECURRING_CKECKIN_QUESTIONNAIRE = "care.checkin.checkin-questionnaire",
  AMAZON_WEIGHT_JOURNEY_WEEK1 = "awj-week1",
  RXDIET_MEAL_PLAN_SETUP = "mealplan.rxdiet.setup",
  ACTIVATE_HEALTH_SYNC = "activate-health-sync"
}

export type KnownTaskSlugs =
  | "log-weight"
  | "activate-health-sync"
  | "upload-labs"
  | "upload_lab_report"
  | "select-pcp"
  | "pharmacy-insurance"
  | "medical-insurance"
  | "preferred-pharmacy"
  | "schedule-labs"
  | "checkin-questionnaire"
  | "initial-sync-visit"
  | "awj-week1"
  | "setup";

export interface AppTaskResponseAdditionalFields {
  vimeoVideoId?: number;
  questionnaireRef?: {
    id: string;
    type: "TYPEFORM";
  };
}

export interface TaskResponseKnown extends TaskResponse {
  slug: KnownTaskSlugs | string;
  program: KnownProgram | string;
}

interface TaskManagementBlocState {
  tasks: TaskResponseKnown[];
}

export default class TaskManagementBloc extends Cubit<TaskManagementBlocState> {
  activePrograms = new Set<KnownProgram>();

  constructor() {
    super({
      tasks: []
    });
    this.addEventListeners();

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.activePrograms.clear();
      this.emit({
        tasks: []
      });
    });
  }

  log = (message: string, etc: unknown = ""): void => {
    if (featureFlags.getFlag(FeatureFlagName.loggingTaskManagement)) {
      // eslint-disable-next-line no-console
      console.info(`[TaskManagementBloc]: ${message}`, etc);
    }
    addSentryBreadcrumb("api", message);
  };

  /**
   * Loads all tasks for a program, for the current user.
   * Updates the state with the new tasks.
   * @param program, only tasks for this program will be loaded.
   * @returns The loaded tasks.
   */
  loadProgramTasks = async (
    program: KnownProgram
  ): Promise<TaskResponseKnown[] | undefined> => {
    return new Promise<TaskResponseKnown[] | undefined>((resolve, reject) => {
      const request =
        this.fetchRequestStore.get(program) ??
        TaskControllerService.getProgramTasks(program);
      this.fetchRequestStore.set(program, request);
      request
        .then((response) => {
          this.fetchRequestStore.delete(program);

          this.addTasksToState(response.data as TaskResponseKnown[]);

          this.log(
            `loadProgramTasks: ${program} loaded ${response.data.length} tasks.`,
            "setting program active"
          );
          this.activePrograms.add(program);

          resolve(response.data as TaskResponseKnown[]);
        })
        .catch((error: unknown) => {
          this.fetchRequestStore.delete(program);
          reject(error);
        });
    });
  };
  fetchRequestStore: Map<
    string,
    CancelablePromise<SuccessCollectionTaskResponse>
  > = new Map();

  observers: [TaskObserverEvent, TaskObserverCallback][] = [];
  addObserver = (
    event: TaskObserverEvent,
    observer: TaskObserverCallback
  ): (() => void) => {
    this.observers.push([event, observer]);
    return () => this.removeObserver(observer);
  };
  removeObserver = (observer: TaskObserverCallback): void => {
    const index = this.observers.findIndex(([, o]) => o === observer);
    if (index === -1) return;
    this.observers.splice(index, 1);
  };

  /**
   * Adds tasks to the state. Removes duplicates based on id.
   * @param tasks The tasks to add.
   */
  addTasksToState = (tasks: TaskResponseKnown[]): void => {
    this.log(`addTasksToState: adding ${tasks.length} tasks`);
    const alltasks = [...tasks, ...this.state.tasks] as TaskResponseKnown[];

    // remove duplicates based on id
    const uniqueTasks = alltasks.filter(
      (task, index, self) =>
        index ===
        self.findIndex((t) => this.getTaskPath(t) === this.getTaskPath(task))
    );

    const programs = new Map<KnownProgram, TaskResponseKnown[]>();
    uniqueTasks.forEach((task) => {
      const programTasks = programs.get(task.program as KnownProgram) ?? [];
      programs.set(task.program as KnownProgram, [...programTasks, task]);
    });

    this.emit({
      ...this.state,
      tasks: uniqueTasks
    });

    this.observers.forEach(([event, observer]) => {
      if (event === TaskObserverEvent.TASK_LIST_CHANGED) {
        observer(programs);
      }
    });
  };

  getTaskPath = (
    task:
      | TaskResponseKnown
      | WebsocketTaskStatusUpdatedPayload
      | Partial<TaskResponse>
  ): string => {
    return `${task.program}/${task.group}/${task.slug}`;
  };

  handleTaskStatusUpdated = (
    task?: WebsocketTaskStatusUpdatedPayload
  ): void => {
    if (!task) return;
    const taskPath = this.getTaskPath(task);

    this.log(`Task status changed`, task);
    const updatedTasks: TaskResponseKnown[] = this.state.tasks.map((t) => {
      if (this.getTaskPath(t) === taskPath) {
        return {
          ...t,
          status: task.newStatus
        } as TaskResponseKnown;
      }

      return t;
    });

    this.addTasksToState(updatedTasks);
  };

  /**
   * Adds a listeners
   */
  addEventListeners = (): void => {
    this.log(`addEventListeners: adding websocket listeners`);
    websocketState.addObserver(
      WebsocketMessageType.taskStatusUpdated,
      (message) => {
        const task = message?.payload as WebsocketTaskStatusUpdatedPayload;
        this.log(`websocket taskStatusUpdated`, task);
        this.handleTaskStatusUpdated(task);
      }
    );

    // reload all active proframs when websocket connects
    websocketState.addObserver(WebsocketLifecycleEvent.connected, () => {
      this.log(
        `websocket connected, reloading active programs:`,
        this.activePrograms
      );
      this.activePrograms.forEach((program) => {
        void this.loadProgramTasks(program);
      });
    });
  };

  /**
   * Parses the additional data of a task and returns the details.
   * @param task The task to get the details for.
   * @returns The details of the task.
   */
  getTaskDetails = (task?: TaskResponseKnown): AppTaskDetails => {
    const appTaskDetails: AppTaskDetails = {};
    if (!task) return appTaskDetails;

    const additionalData = task.additionalData as
      | AppTaskResponseAdditionalFields
      | undefined;

    // Handle Dates
    if (task.availableFrom) {
      appTaskDetails.availableDate = new Date(task.availableFrom);
    }

    if (task.availableTo) {
      appTaskDetails.availableDateEnd = new Date(task.availableTo);
    }

    if (task.startedAt) {
      appTaskDetails.startedDate = new Date(task.startedAt);
    }

    if (task.completedAt) {
      appTaskDetails.completedDate = new Date(task.completedAt);
    }

    if (appTaskDetails.availableDate) {
      const now = new Date();
      const { availableDate } = appTaskDetails;

      appTaskDetails.startsInFuture = availableDate > now;

      if (appTaskDetails.startsInFuture) {
        appTaskDetails.timeTillAvailable = new Date(
          availableDate.getTime() - now.getTime()
        );
      }
    }

    if (appTaskDetails.availableDateEnd) {
      const now = new Date();
      const { availableDateEnd } = appTaskDetails;

      if (availableDateEnd > now) {
        appTaskDetails.timeTillAvailableEnd = new Date(
          availableDateEnd.getTime() - now.getTime()
        );
      }
    }

    // Handle Video
    if (additionalData?.vimeoVideoId) {
      appTaskDetails.videoId = additionalData.vimeoVideoId;
      appTaskDetails.videoProvider = "vimeo";
      appTaskDetails.type = "video";
    }

    // Handle Questionnaire
    if (additionalData?.questionnaireRef) {
      appTaskDetails.questionnaireId = additionalData.questionnaireRef.id;
      appTaskDetails.type = "questionnaire";
    }

    return appTaskDetails;
  };

  validTaskTransitions: Partial<
    Record<TaskResponse.status, TaskResponse.status[]>
  > = {
    [TaskResponse.status.SKIPPED]: [],
    [TaskResponse.status.COMPLETED]: []
  };

  checkStatusTransition = (params: {
    from?: TaskResponse.status;
    to: TaskResponse.status;
  }): boolean => {
    const { from, to } = params;
    if (!from) {
      return true;
    }

    if (from === to) {
      return false;
    }

    const validTransitions = this.validTaskTransitions[from];
    if (!validTransitions) {
      return true;
    }

    if (validTransitions.includes(to)) {
      return true;
    }

    return false;
  };

  /**
   * Updates the status of a task.
   * @param task The task to update.
   * @param status The new status.
   */
  updateTaskStatus = async (
    task: Partial<TaskResponse>,
    status: UpdateTaskRequest.status
  ): Promise<TaskResponseKnown | undefined> => {
    try {
      this.log(`updateTaskStatus: to ${status}`, task);
      if (!task.program || !task.group || !task.slug) {
        this.log(
          `updateTaskStatus: Invalid task, missing program, group or slug`,
          task
        );
        throw new Error("Invalid task, missing program, group or slug");
      }

      const currentTask = this.state.tasks.find(
        (t) =>
          t.program === task.program &&
          t.group === task.group &&
          t.slug === task.slug
      );

      const statusTransitionValid = currentTask
        ? this.checkStatusTransition({
            from: currentTask.status,
            to: status
          })
        : true;
      if (!statusTransitionValid) {
        this.log(
          `updateTaskStatus: Invalid status transition from ${currentTask?.status} to ${status}`,
          task
        );
        // eslint-disable-next-line no-console
        console.error(
          `updateTaskStatus: Invalid status transition from ${currentTask?.status} to ${status}`,
          task
        );
        return;
      }

      const updatedTasks = [...this.state.tasks].map((t) => {
        if (this.getTaskPath(t) === this.getTaskPath(task)) {
          return {
            ...t,
            status
          } as TaskResponseKnown;
        }

        return t;
      });
      this.addTasksToState(updatedTasks);

      const updateResponse = await TaskControllerService.updateTaskBySlug(
        task.program,
        task.group,
        task.slug,
        {
          status
        }
      );
      // update all all tasks for the program
      void this.loadProgramTasks(task.program as KnownProgram);

      return updateResponse.data as TaskResponseKnown;
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  getProgramTasks = (program?: KnownProgram): TaskResponseKnown[] => {
    return this.state.tasks.filter((task) => task.program === program);
  };

  getProgramsTasks = (programs: KnownProgram[]): TaskResponseKnown[] => {
    const tasks = programs
      .map((program) => this.getProgramTasks(program))
      .flat();
    return tasks;
  };

  getCompletedProgramsTasks = (programs: KnownProgram[]) => {
    const completedTasks = this.getProgramsTasks(programs).filter(
      (task) => task.status === TaskResponse.status.COMPLETED
    );
    return completedTasks;
  };

  /**
   * Gets all tasks that are available.
   * @param program If provided, only tasks for this program will be returned.
   */
  getTaskByStatus = (
    status?: TaskResponse.status[],
    program?: KnownProgram
  ): TaskResponseKnown[] => {
    const programTasks = program
      ? this.getProgramTasks(program)
      : this.state.tasks;
    return programTasks.filter((task) => status?.includes(task.status));
  };

  get syntheticTasks(): TaskResponse[] {
    const syntheticTasks: TaskResponse[] = [];
    for (const task of this.state.tasks) {
      if (task.program === KnownProgram.LIFEBALANCE) {
        const isAvailable = task.status === TaskResponse.status.AVAILABLE;
        const isFirstTask = task.group === "01" && task.slug === "01";
        if (isAvailable) {
          syntheticTasks.push({
            ...task,
            additionalData: {
              title: translate(
                isFirstTask
                  ? "prompt.educational.start_weight"
                  : "prompt.educational.complete_weight"
              ),
              iconType: "education",
              link: `/app/plan/${task.program}?group=${task.group}&slug=${task.slug}`
            }
          });
        }
      }
    }
    return syntheticTasks;
  }
}
