import type { FC } from "react";
import React from "react";
import { useBloc } from "src/state/state";
import UserCubit from "src/state/UserCubit/UserCubit";
import Translate from "src/ui/components/Translate/Translate";

const ListUserSelectedConditions: FC = () => {
  const [, userState] = useBloc(UserCubit);
  const conditions = userState.medicalConditions ?? [];

  if (conditions.length === 1) {
    return (
      <div>
        <Translate msg="program_designed_for_you_no_conditions" />
      </div>
    );
  }

  return (
    <span>
      <Translate msg="program_designed_for_you" />
    </span>
  );
};

export default ListUserSelectedConditions;
