import AuthenticationBloc from "../UserCubit/AuthenticationBloc";
import UserCubit from "../UserCubit/UserCubit";
import { useBloc } from "../state";

export default function useUserAuthStage():
  | "anonymous"
  | "fullAuth"
  | "noAuth"
  | "partialAuth" {
  const [{ authenticationStatus }] = useBloc(AuthenticationBloc);
  const [{ userData }] = useBloc(UserCubit);

  if (
    authenticationStatus === "authenticated" &&
    userData &&
    userData.email === undefined &&
    userData.passwordSet === false
  ) {
    return "anonymous";
  }

  if (
    authenticationStatus === "authenticated" &&
    userData &&
    (userData.email === undefined || userData.passwordSet === false)
  ) {
    return "partialAuth";
  }

  if (authenticationStatus === "authenticated" && userData) {
    return "fullAuth";
  }

  return "noAuth";
}
