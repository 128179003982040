import type { ReactElement } from "react";
import React from "react";

export default function EditIcon(): ReactElement {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 15.8125V19.25H6.1875L16.3258 9.11167L12.8883 5.67417L2.75 15.8125ZM18.9842 6.45334C19.3417 6.09584 19.3417 5.51834 18.9842 5.16084L16.8392 3.01584C16.4817 2.65834 15.9042 2.65834 15.5467 3.01584L13.8692 4.69334L17.3067 8.13084L18.9842 6.45334Z"
        fill="black"
      />
    </svg>
  );
}
