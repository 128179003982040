import { Cubit } from "blac";
import type { Location } from "react-router-dom";
import { globalEvents } from "src/constants/globalEvents";
import { Locale } from "src/constants/language";
import { appStatusBarState } from "../state";

export default class HistoryBloc extends Cubit<{
  history: Location[];
}> {
  sessionStorageKey = "9am.history";
  languageStorageKey = "9am.initialLanguage";
  initialLocale?: Locale;
  constructor() {
    super({ history: [] });
    this.recoverHistory();
    this.setInitialLocale();
    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({ history: [] });
      sessionStorage.removeItem(this.sessionStorageKey);
    });
  }

  setInitialLocale = (): void => {
    const url = new URL(window.location.href);
    const lang =
      url.searchParams.get("language") ??
      sessionStorage.getItem(this.languageStorageKey);
    const locale = Locale[lang as "en" | "es"] as Locale | undefined;

    if (lang && locale) {
      this.initialLocale = locale;
      sessionStorage.setItem(this.languageStorageKey, lang);
    }
  };

  recoverHistory = (): void => {
    const history = sessionStorage.getItem(this.sessionStorageKey);
    const parsed = JSON.parse(history ?? "[]") as Location[];
    const filtered = parsed.filter((loc) => {
      const { search } = loc;
      const searchParams = new URLSearchParams(search);
      if (searchParams.has("popup")) {
        return false;
      }
      return true;
    });
    this.emit({ history: filtered });
  };

  saveHistory = (): void => {
    const history = this.state.history
      .map((loc) => ({
        pathname: loc.pathname
      }))
      .slice(-50);
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(history));
  };

  locationChange = (loc: Location): void => {
    const { pathname, search } = loc;
    let addToHistory = true;

    const searchParams = new URLSearchParams(search);
    if (searchParams.has("popup")) {
      appStatusBarState.setPopupOpen(true);
      appStatusBarState.setThemeDark();
      addToHistory = false;
    } else {
      appStatusBarState.setPopupOpen(false);
      appStatusBarState.resetThemeDefault();
    }

    if (!addToHistory) {
      return;
    }

    const { history } = this.state;
    const last = history[history.length - 1] as Location | undefined;
    if (last && last.pathname === pathname) {
      return;
    }

    this.emit({ history: [...history, loc] });

    this.saveHistory();
  };

  /**
   * Find how many steps back it would take to get to the given pathname, always returns a negative number
   * @param pathname
   */
  findDelta = (pathname: string): number => {
    const reversed = [...this.state.history].reverse();
    const index = reversed.findIndex((loc) => loc.pathname === pathname);
    return index * -1;
  };

  get backUrl(): string | undefined {
    const { history } = this.state;
    return history[history.length - 2]?.pathname;
  }

  get currentPathname(): string {
    const { history } = this.state;
    return history[history.length - 1]?.pathname ?? window.location.pathname;
  }

  /**
   * map history.go to a function that also updates the state
   */
  go = (delta: number): void => {
    window.history.go(delta);
    // delete delta from history
    const history = this.state.history.slice(0, delta);
    this.emit({ history });
  };

  /**
   * map history.back to a function that also updates the state
   */
  back = (): void => {
    window.history.back();
    // delete last from history
    const history = this.state.history.slice(0, -1);
    this.emit({ history });
  };
}
