import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React, { useMemo } from "react";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { useBloc } from "src/state/state";

const shimmer = keyframes`
  0% {
    background-position: 1000px 0;
  }
  100% {
    background-position: -1000px 0;
  }
`;

const CustomSkeleton = styled.span<{
  width?: string;
  height?: string;
  delay?: number;
  ratio?: number;
}>`
  display: inline-block;
  width: ${(p) => p.width};
  height: ${(p) => (p.ratio ? "auto" : p.height)};
  aspect-ratio: ${(p) => p.ratio ?? "auto"};
  background: #00000010;
  border-radius: 3px;
  opacity: 0.5;
  margin: 0;
  padding: 0;

  /* shimmer */
  background-image: linear-gradient(
    -70deg,
    #b0b0b0 0%,
    #b0b0b0 12%,
    #dcdcdc 60%,
    #b0b0b0 88%,
    #b0b0b0 100%
  );
  background-size: 1000px 100%;
  animation: ${shimmer} 3s linear infinite;
  animation-delay: ${(p) => p.delay}ms;

  &[data-round="true"] {
    border-radius: 50%;
  }
`;

const AsyncContent: FC<{
  children?: ReactNode;
  check?: unknown[];
  height?: string;
  width?: string;
  round?: boolean;
  ratio?: number;
  style?: React.CSSProperties;
}> = (props) => {
  const [{ forceSkeletons }] = useBloc(AppViewCubit);
  const delay = useMemo(() => Math.random() * 1000, []);
  const { height = "1.5em", width = "100%" } = props;

  const showContent = props.check
    ? props.check.every(Boolean)
    : Boolean(props.children);

  if (!showContent || forceSkeletons === true)
    return (
      <CustomSkeleton
        style={props.style}
        width={width}
        height={height}
        ratio={props.ratio}
        delay={delay}
        data-round={props.round}
        className="async-content"
      />
    );

  return <>{props.children}</>;
};

export default AsyncContent;

export const Skeleton: FC<{
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  className?: string;
}> = (props) => {
  const width =
    typeof props.width === "number" ? `${props.width}px` : props.width;
  const height =
    typeof props.height === "number" ? `${props.height}px` : props.height;
  return (
    <CustomSkeleton
      width={width}
      height={height}
      style={props.style}
      className={props.className}
    />
  );
};
