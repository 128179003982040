export enum QuestionnaireDiagnosisOption {
  DIABETES = "option1_diabetes",
  PREDIABETES = "option2_prediabetes",
  POSSIBLE_DIABETES = "option_possible_diabetes",
  HIGH_BLOOD_PRESSURE = "option3_high_blood_pressure",
  HIGH_CHOLESTEROL = "option4_high_cholesterol",
  FATTY_LIVER_DISEASE = "option5_fatty_liver_disease_nafld_nash_masld",
  OBSTRUCTIVE_SLEEP_APNEA = "option6_obstructive_sleep_apnea",
  POLYCYSTIC_OVARIES = "option7_polycystic_ovaries",
  TWO_CONDITIONS_NO_DIABETES = "option8_two_conditions_no_diabetes",
  NONE = "option9_none"
}
