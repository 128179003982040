export enum KnownFileTypes {
  image = "image",
  plainText = "plainText",
  formattedTextHTML = "formattedTextHTML",
  unknown = "unknown"
}

const mimeType = {
  plainText: "text/plain",
  formattedTextHTML: "text/html",

  isImage: (type: string): boolean => {
    return /image\/*/.exec(type) !== null;
  },

  isPlainText: (type: string): boolean => {
    return /text\/plain/.exec(type) !== null;
  },

  isFormattedTextHTML: (type: string): boolean => {
    return /text\/html/.exec(type) !== null;
  },

  getType: (type: string): KnownFileTypes => {
    if (mimeType.isPlainText(type)) {
      return KnownFileTypes.plainText;
    }
    if (mimeType.isImage(type)) {
      return KnownFileTypes.image;
    }
    if (mimeType.isFormattedTextHTML(type)) {
      return KnownFileTypes.formattedTextHTML;
    }
    return KnownFileTypes.unknown;
  }
};

export default mimeType;
