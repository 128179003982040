import type {
  AppliedCouponResponse,
  SubscriptionDetailsResponse
} from "@9amhealth/openapi";

const durationText: Record<number, string> = {
  1: "first month",
  3: "first 3 months",
  6: "first 6 months"
};

export interface ParsePriceOptions {
  appliedCoupon: AppliedCouponResponse | undefined;
  sub: SubscriptionDetailsResponse | undefined;
  finalPrice?: number;
}

const parsePriceText = (options: ParsePriceOptions): string => {
  const { appliedCoupon, sub, finalPrice } = options;

  if (!sub) {
    return "";
  }

  const withCoupon = Boolean(appliedCoupon);

  if (!withCoupon || !appliedCoupon) {
    return `$${sub.totalPrice}`;
  }

  const { totalPrice } = sub;
  const discountedPrice =
    typeof finalPrice === "number"
      ? finalPrice
      : totalPrice - sub.totalCouponDiscount;
  const discountDuration = appliedCoupon.durationInMonths ?? 1;

  return `$${discountedPrice} ${durationText[discountDuration]}, then $${totalPrice}`;
};

export default parsePriceText;
