import styled from "@emotion/styled";

const ErrorBox = styled.div`
  color: var(--t-c, rgb(95, 33, 32));
  background-color: var(--bg-c, rgb(253, 237, 237));
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0.6rem 1rem 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
  border-left: 0.25rem solid var(--b-c, rgb(212, 63, 58));
  margin-bottom: 0.5rem;
  box-sizing: border-box;

  &[data-severity="error"] {
    --b-c: rgb(212, 63, 58);
    --t-c: rgb(95, 33, 32);
    --bg-c: rgb(253, 237, 237);
  }

  &[data-severity="warning"] {
    --b-c: rgb(212, 157, 58);
    --t-c: rgb(95, 69, 32);
    --bg-c: rgb(253, 247, 237);
  }

  &[data-severity="info"] {
    --b-c: rgb(58, 212, 157);
    --t-c: rgb(32, 95, 69);
    --bg-c: rgb(237, 253, 247);
  }
`;

export default ErrorBox;
