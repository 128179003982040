import Path from "src/constants/path";

type PathType = "app" | "auth" | "join" | "referral" | "root";

export enum QueryParam {
  switch = "switch"
}

export const createAppPath = (target: Path, type: PathType = "app"): Path => {
  if (type === "auth") {
    return `${Path.root}${Path.auth}/${target}` as Path;
  }

  if (type === "join") {
    return `${Path.root}${Path.join}/${target}` as Path;
  }

  if (type === "root") {
    return `${Path.root}${target}` as Path;
  }

  return `${Path.root}${Path.app}/${target}` as Path;
};

export const createWildcard = (
  target: Path,
  insertRootPath = false
): string => {
  return `${insertRootPath ? Path.root : ""}${target}/*`;
};

export const addQueryParams = (
  target: Path | string,
  queryParams: Record<string, string>
): string => {
  const params = Object.keys(queryParams);

  const queryString = params
    .map(
      (param, index) =>
        `${param}=${queryParams[param]}${
          index !== params.length - 1 ? "&" : ""
        }`
    )
    .join("");

  return `${target}?${queryString}`;
};
