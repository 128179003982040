export interface HealthPlugin {
  requestAuthorization(authOptions: AuthorizationQueryOptions): Promise<void>;
  isHealthDataAvailable(): Promise<{availability: 'Available' | 'NotSupported' | 'NotInstalled'}>;
  // queryDataType(options: QueryOptionsSamples): Promise<QueryResultSamples>;
  getStepsForDays(options: QueryOptionsRange): Promise<HealthDataDaysResult>;
  getDeviceInfo(): Promise<DeviceInfo>;
  checkAuthStatus(options: {
    read: HealthDataType[];
    write?: HealthDataType[];
  }): Promise<HealthAuthStatusResponse>;
  runBackgroundTaskHandler(): Promise<void>;
}

export type HealthScheduledTask = {
  task: {
    identifier?: string;
    earliestBeginDate?: string;
  };
};

export type HealthAuthStatusResponse = {
  status: 'unknown' | 'request' | 'granted';
};

export type DeviceInfo = {
  deviceInformation: {
    name?: string;
    model?: string;
    identifierForVendor?: string;
    uidDeviceIdentifier?: string;
    firmwareVersion?: string;
    hardwareVersion?: string;
    manufacturer?: string;
    softwareVersion?: string;
  };
};

export type QueryOptionsRange = {
  from: string;
  to: string;
};

export type HealthDataSource = {
  name?: string;
  bundleIdentifier?: string;
};

export type HealthDataDaysResult = {
  stepsByDay: HealthDataDay[];
};

export type HealthDataDay = {
  value: number;
  startDate: string;
  endDate: string;
  hash: string;
};

export type QueryOptionsSamples = {
  type: HealthDataType;
  startDate: string;
  endDate: string;
};

export type QueryResultSamples = {
  resultData: HealthDataSample[];
  countReturn: number;
};

export type AuthorizationQueryOptions = {
  read: HealthDataType[];
  write?: HealthDataType[];
};

export enum HealthDataType {
  STEPS = 'steps',
}

export type HealthDataSample = {
  startDate: string;
  endDate: string;
  source: string;
  value: number;
  unitName: string;
  device: {
    hardwareVersion: string;
    manufacturer: string;
    model: string;
    name: string;
    softwareVersion: string;
  };
};
