import { motion } from "framer-motion";
import type { FC, ReactNode } from "react";
import React from "react";

const FadeWrap: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <motion.div
      className="fade-wrap"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

export default FadeWrap;
