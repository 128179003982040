import styled from "@emotion/styled";
import type { FC, ReactElement } from "react";
import React, { useEffect, useRef } from "react";

const Wrap = styled.div`
  display: contents;
`;

const OnEvent: FC<{
  children: ReactElement;
  events?: Record<
    string,
    | EventListenerOrEventListenerObject
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((event: CustomEvent<any>) => Promise<void> | void)
    | null
  >;
  shadowEvents?: Record<
    string,
    | EventListenerOrEventListenerObject
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((event: CustomEvent<any>) => Promise<void> | void)
    | null
  >;
  style?: React.CSSProperties;
  className?: string;
}> = ({ children, events = {}, shadowEvents = {}, style, className }) => {
  const refEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = refEl.current?.childNodes[0];
    if (!el) return;
    const { shadowRoot } = el as HTMLElement;

    for (const event of Object.keys(events)) {
      el.addEventListener(
        event,
        events[event] as unknown as EventListenerOrEventListenerObject
      );
    }

    for (const shadowEvent of Object.keys(shadowEvents)) {
      shadowRoot?.addEventListener(
        shadowEvent,
        shadowEvents[
          shadowEvent
        ] as unknown as EventListenerOrEventListenerObject
      );
    }

    return () => {
      for (const event of Object.keys(events)) {
        el.removeEventListener(
          event,
          events[event] as unknown as EventListenerOrEventListenerObject
        );
      }

      for (const shadowEvent of Object.keys(shadowEvents)) {
        shadowRoot?.removeEventListener(
          shadowEvent,
          shadowEvents[
            shadowEvent
          ] as unknown as EventListenerOrEventListenerObject
        );
      }
    };
  }, [events]);

  return (
    <Wrap
      data-testid="on-event-handler"
      style={style}
      className={className}
      ref={refEl}
    >
      {children}
    </Wrap>
  );
};

export default OnEvent;
