import { CustomFieldPropertyRegex } from "src/state/QuestionnaireStepCubit/CustomFields";

export const removeQuestionnaireKeywordsFromText = (
  text: string,
  keywords?: RegExp[]
): string => {
  let newText = text;

  const keywordsRegexArray = keywords ?? [
    CustomFieldPropertyRegex.CENTER,
    CustomFieldPropertyRegex.AUTO_CONTINUE,
    CustomFieldPropertyRegex.DECIMAL,
    CustomFieldPropertyRegex.INPUT_DESCRIPTION,
    CustomFieldPropertyRegex.BUTTON_TEXT,
    CustomFieldPropertyRegex.BUTTON_LINK,
    CustomFieldPropertyRegex.BUTTON_COLOR,
    CustomFieldPropertyRegex.SHOW_BUTTON,
    CustomFieldPropertyRegex.HIDE_BUTTON,
    CustomFieldPropertyRegex.DESCRIPTION_FIRST,
    CustomFieldPropertyRegex.AFFIX_SUFFIX,
    CustomFieldPropertyRegex.CLEAR_OTHER,
    CustomFieldPropertyRegex.STYLE,
    CustomFieldPropertyRegex.LABEL,
    CustomFieldPropertyRegex.USE_VALUE,
    CustomFieldPropertyRegex.SET_USER_DOSAGE,
    CustomFieldPropertyRegex.PLACEHOLDER,
    CustomFieldPropertyRegex.MEDICATION_FIELD,
    CustomFieldPropertyRegex.HEIGHT_FIELD,
    CustomFieldPropertyRegex.WEIGHT_FIELD,
    CustomFieldPropertyRegex.VALIDATION,
    CustomFieldPropertyRegex.QUESTION_REFERENCE,
    CustomFieldPropertyRegex.MAXIMUM_DAILY_DOSAGE,
    CustomFieldPropertyRegex.DOSAGE,
    CustomFieldPropertyRegex.DEFAULT_VALUE,
    CustomFieldPropertyRegex.SET_VAR(),
    CustomFieldPropertyRegex.ENDING,
    CustomFieldPropertyRegex.INELIGIBLE,
    CustomFieldPropertyRegex.ALWAYS_SEND_DATA,
    CustomFieldPropertyRegex.ANY_COMMAND_WITH_VALUE,
    CustomFieldPropertyRegex.ANY_COMMAND_NO_VALUE,
    CustomFieldPropertyRegex.ZIP_CODE_FIELD,
    CustomFieldPropertyRegex.DATE_MAX_MIN,
    CustomFieldPropertyRegex.MULTIPLE_TEXT_FIELD,
    CustomFieldPropertyRegex.DATA_KEY,
    CustomFieldPropertyRegex.REQUIRED
  ];

  keywordsRegexArray.forEach((val) => {
    newText = newText.replace(val, "").trim();
  });

  return newText.replace(CustomFieldPropertyRegex.BLANK_LINE, "");
};
