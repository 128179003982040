import DOMPurify from "dompurify";
import type { DOMNode, HTMLReactParserOptions } from "html-react-parser";
import parse, { domToReact } from "html-react-parser";
import { isAperoLink } from "lib/Urls";
import type { ReactElement } from "react";
import React from "react";
import showdown from "showdown";
import SanityService from "src/api/SanityService";
import VimeoService from "src/api/VimeoService";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import ExampleInsuranceCard from "src/ui/components/ExampleInsuranceCard/ExampleInsuranceCard";
import Feedback from "src/ui/components/Feedback/Feedback";
import Link from "src/ui/components/Link/Link";
import MediaPlayer from "src/ui/components/MediaPlayer/MediaPlayer";
import Underline from "src/ui/components/Underline/Underline";
import { Document, getLegalDocumentLink } from "./getLegalDocumentLink";
import translate from "./translate";
import { TranslationKey } from "src/types/translationKey";

export enum NineamComponents {
  video = "9am:video",
  feedback = "9am:feedback"
}

const htmlSettings = () => ({
  ALLOWED_TAGS: [
    "b",
    "p",
    "em",
    "strong",
    "div",
    "span",
    "a",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "ul",
    "ol",
    "li",
    "br",
    "hr",
    "i",
    "u",
    "sup"
  ],
  ALLOWED_ATTR: ["href", "type", "src", "version", "data-embed"]
});

interface ElData {
  name?: string;
  data?: string;
  parent?: ElData;
  type?: string;
  attribs: {
    href?: string;
    type?: NineamComponents | string;
    src?: string;
    version?: string | "default";
    "data-embed"?: string;
  };
  children: DOMNode[];
}

const markdownConverter = new showdown.Converter({
  noHeaderId: true,
  simpleLineBreaks: true,
  requireSpaceBeforeHeadingText: true,
  underline: true
});

interface HtmlParserOptions {
  convertMarkdownToHtml?: boolean;
  emAsUnderline?: boolean;
  disabledTags?: string[];
  removeDashOnlyRows?: boolean;
}

class HtmlParser {
  content: string;
  options: HtmlParserOptions = {};
  htmlSettings = htmlSettings();

  constructor(dirty: string, options: HtmlParserOptions = {}) {
    let text = dirty;
    this.options = options;

    if (options.convertMarkdownToHtml) {
      // replace() needed to properly parse * and _ for bold, italic and underlined text (Typeform returns \* and \_ for every * and _)
      text = text.replace(/\\\*/g, "*").replace(/\\_/g, "_");

      if (options.removeDashOnlyRows) {
        const textRows = text.split("\n");
        // filter out rows that contain only "-" or "- " (just a dash, or a dash with only whitespaces afterwards)
        const filteredTextRows = textRows.filter(
          (row) => !new RegExp(/^-[\s+]?$/).test(row)
        );
        text = filteredTextRows.join("\n");
      }

      text = markdownConverter.makeHtml(text);
    } else {
      text = text.replace(/\n/g, "<br />");
    }

    // remove disabledTags from ALLOWED_TAGS
    if (options.disabledTags) {
      this.htmlSettings.ALLOWED_TAGS = this.htmlSettings.ALLOWED_TAGS.filter(
        (tag) => !options.disabledTags?.includes(tag)
      );
    }

    text = DOMPurify.sanitize(text, this.htmlSettings);
    this.content = text;

    return this;
  }

  // take single line of text and splits it onto multiple rows
  static splitIntoRows(text: string, rows: number): string {
    if (text.indexOf("\n") !== -1) {
      return text;
    }
    const words = text.split(" ");

    // split words array into X equal parts
    const split = [];
    const chunkSize = Math.ceil(words.length / rows);
    for (let i = 0; i < rows; i++) {
      const part = words.slice(i * chunkSize, (i + 1) * chunkSize).join(" ");
      if (part) split.push(part);
    }

    return split.join("<br />");
  }

  public readonly toJsx = (): ReturnType<typeof domToReact> => {
    return parse(this.content, this.parseOptions);
  };

  readonly parseEmbed = (data: ElData) => {
    const embed = data.attribs["data-embed"];

    switch (embed) {
      case "example-insurance-card":
        return <ExampleInsuranceCard />;
      default:
        return <div />;
    }
  };

  private readonly createEmbedComponentVideo = (
    data: ElData
  ): ReactElement | undefined => {
    const { src = "", version = "default" } = data.attribs;
    const vimeoId = VimeoService.extractIdFromUrl(src);
    const sanityId = SanityService.checkIsVideoId(src);

    if (vimeoId) {
      return (
        <MediaPlayer
          videoId={vimeoId}
          videoProvider="vimeo"
          title=""
          playIcon="simple"
          inline={version === "inline"}
        />
      );
    }

    if (sanityId) {
      return (
        <MediaPlayer
          videoId={sanityId}
          videoProvider="sanity"
          title=""
          playIcon="simple"
          inline={version === "inline"}
        />
      );
    }

    return <div>Video source not found.</div>;
  };

  private readonly parseUnderline = (data: ElData): ReactElement => {
    return (
      <Underline>{domToReact(data.children, this.parseOptions)}</Underline>
    );
  };

  // static validateLink(href: string): boolean {
  //   const isTel = href.startsWith("tel:");
  //   const isMailto = href.startsWith("mailto:");
  //   if (isTel || isMailto) return true;
  //
  //   const absoluteHref = href.startsWith("http")
  //     ? href
  //     : `${window.location.origin}${href}`;
  //   try {
  //     new URL(absoluteHref);
  //     return true;
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   } catch (error) {
  //     reportErrorSentry(new Error(`Invalid link: ${href}`));
  //     return false;
  //   }
  // }

  private anchorShorts: Record<string, (() => string) | undefined> = {
    telehealth: () => getLegalDocumentLink(Document.telehealthConsent),
    terms: () => getLegalDocumentLink(Document.termsOfService),
    "notice-privacy": () =>
      getLegalDocumentLink(Document.noticePrivacyPractice),
    "privacy-policy": () => getLegalDocumentLink(Document.privacyPolicy)
  };

  private readonly parseAnchorTag = (data: ElData): ReactElement => {
    const computed: {
      onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    } = {};
    let { href = "" } = data.attribs;

    if (!href) {
      return <>{domToReact(data.children, this.parseOptions)}</>;
    }

    const linkExpander = this.anchorShorts[href];
    if (linkExpander) {
      href = linkExpander();
    }
    const useDialog = href.includes("dialog=true");

    if (useDialog) {
      computed.onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const targetInnerText = e.currentTarget.innerText;
        AppQueryPopupsController.openPopup(AppPopup.iframe, {
          additionalParameters: {
            url: href,
            title: targetInnerText,
            stay: "false"
          }
        });
      };
    }

    // let isExternalLink = href.startsWith("http") || href.startsWith("www");

    // handle broken relative links from zendesk or others
    // if (
    //   useHref &&
    //   (useHref.startsWith("https://app/") || useHref.startsWith("http://app/"))
    // ) {
    //   target = undefined;
    //   useHref = useHref.replace("https://app/", "/app/");
    //   useHref = useHref.replace("http://app/", "/app/");
    //   isExternalLink = false;
    // }

    // handle absolute links to app
    // if (useHref && isAppLink(useHref)) {
    //   const url = new URL(useHref);
    //   const path = url.pathname;
    //   const search = url.search || "";
    //   target = undefined;
    //   useHref = path + search;
    //   isExternalLink = false;
    // }

    // const props: {
    //   href?: string;
    //   to?: string;
    //   onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    //   target?: string;
    // } = {
    //   ...(isExternalLink
    //     ? {
    //         href: useHref
    //       }
    //     : { to: useHref }),
    //   target,
    //   ...computed
    // };
    //
    // if (!validHref) {
    //   props.onClick = (e: React.MouseEvent<HTMLElement>) => {
    //     e.preventDefault();
    //     toast.error("error.invalid_link");
    //   };
    // }

    return (
      <Link to={href}>{domToReact(data.children, this.parseOptions)}</Link>
    );
  };

  private checkParents = (data: ElData, parentName: string): boolean => {
    let parent = data as ElData | undefined;
    while (parent) {
      if (parent.name === parentName) {
        return true;
      }
      parent = parent.parent as ElData;
    }
    return false;
  };

  // eslint-disable-next-line no-useless-escape
  urlRegEx = /https?:\/\/[a-zA-Z0-9\/\$\%\-\_\.\+\!\*\';\?\:\@\=\&\#?]+/g;

  getUrls = (text: string = ""): string[] | null => {
    const urls = text.match(this.urlRegEx);
    if (!urls) return null;

    return urls.map((url) => {
      let t = url;
      // remove trailing punctuation
      if (t.endsWith(".")) t = t.slice(0, -1);
      return t;
    });
  };

  shouldParseTextToLink = (data: ElData): boolean => {
    const isText = data.type === "text";
    if (!isText) return false;

    const hasLinkParent = this.checkParents(data, "a");
    if (hasLinkParent) return false;

    const hasUrlText = this.getUrls(data.data);
    if (!hasUrlText) return false;

    return true;
  };

  translate = (key: TranslationKey): string => {
    return translate(key);
  };

  parseTextForLink = (url: string): string => {
    const altHash = url.split("#alt=");
    if (altHash.length > 1) {
      return decodeURIComponent(altHash[1]);
    }

    if (isAperoLink(url)) {
      return this.translate("link.scheduleAppointment");
    }

    return url;
  };

  addLinkPlaceholderRegex = /^%(\d+)%$/gm;
  addLinkSplitString = "%%LINK%%";
  addLinksToText = (data: ElData): ReactElement => {
    let text = data.data ?? "";
    const urls = this.getUrls(data.data);
    if (!urls) return <>{text}</>;

    urls.forEach((url, i) => {
      text = text.replace(
        url,
        `${this.addLinkSplitString}%${i}%${this.addLinkSplitString}`
      );
    });

    const parts = text.split(this.addLinkSplitString);

    const elements: ReactElement[] = [];
    for (const part of parts) {
      const linkPlaceholder = this.addLinkPlaceholderRegex.exec(part);
      if (linkPlaceholder) {
        const index = parseInt(linkPlaceholder[1], 10);
        elements.push(
          <Link key={index} to={urls[index]}>
            {this.parseTextForLink(urls[index])}
          </Link>
        );
      } else {
        elements.push(<>{part}</>);
      }
    }

    return <>{elements.map((e) => e)}</>;
  };

  private readonly parseOptions: HTMLReactParserOptions = {
    replace: (el) => {
      const data = el as unknown as ElData;
      const { attribs = {}, name = "" } = data;

      if (this.shouldParseTextToLink(data)) {
        return this.addLinksToText(data);
      }

      if (attribs["data-embed"]) {
        return this.parseEmbed(data);
      }

      if (attribs.type === NineamComponents.video) {
        return this.createEmbedComponentVideo(data);
      }

      if (attribs.type === NineamComponents.feedback) {
        return <Feedback />;
      }

      if (name === "a") {
        return this.parseAnchorTag(data);
      }

      if (name === "u") {
        return this.parseUnderline(data);
      }

      if (this.options.emAsUnderline && name === "em") {
        return this.parseUnderline(data);
      }
    },
    htmlparser2: {
      lowerCaseTags: true
    }
  };
}

export default HtmlParser;
