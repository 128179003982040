import styled from "@emotion/styled";
import React from "react";
import type { FC, ReactNode } from "react";

const StyledDetails = styled.details`
  summary {
    cursor: pointer;
    padding: 0.5rem;
    background-color: #eee;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }

  .content {
    padding: 0.5rem;
  }
`;

const CollapseDebug: FC<{ title: string; children?: ReactNode }> = ({
  title,
  children
}) => {
  return (
    <StyledDetails>
      <summary>{title}</summary>
      <div className="content">{children}</div>
    </StyledDetails>
  );
};

export default CollapseDebug;
